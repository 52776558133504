import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface PropsType {
  resultSetter: (value: number[]) => void;
  error?: string | undefined;
  initial?: number[]
}

export function StoneXMultiDecileSelect(props: PropsType) {
  const [selectedDeciles, setSelectedDeciles] = useState<number[]>([]);
  const [check, setCheck] = useState([true, false]);


  useEffect(() => {
    
    var decileArray = selectedDeciles;
    props.resultSetter(decileArray);
  }, [selectedDeciles]);

  const onDecileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const decile = parseInt(event.target.name);
    const isChecked = event.target.checked;

    const i = selectedDeciles.indexOf(decile);
    check[i] = isChecked;
    setCheck([...check]);
    
    if (isChecked) {
      selectedDeciles.push(decile);
    }
    else {
      var index = selectedDeciles.findIndex(d => d == decile);
      if (index != null) {
        selectedDeciles.splice(index, 1);
      }
    }

  };

  const onDecileCheckAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        setSelectedDeciles([0,10,20,25,30,40,50,60,70,75,80,90,100]);
      } else {
        setSelectedDeciles([]);
      }
    },
    [],
  );

  return (
    <FormControl error={!!props.error}>
      <FormGroup sx={{ display: 'grid', alignContent: 'center', gridTemplateColumns: '100px 100px 100px 100px', marginLeft: '8px' }}>
        <FormControlLabel
          sx={{ gridColumnStart: 'span 4' }}
          control={<Checkbox name="all" onChange={onDecileCheckAllChange} />}
          label="Check All"
        />
        <FormControlLabel control={<Checkbox name="0" onChange={onDecileChange} checked={selectedDeciles.some(s => s == 0)} />} label="0" />
        <FormControlLabel control={<Checkbox name="10" onChange={onDecileChange} checked={selectedDeciles.includes(10)} />} label="10" />
        <FormControlLabel control={<Checkbox name="20" onChange={onDecileChange} checked={selectedDeciles.includes(20)} />} label="20" />
        <FormControlLabel control={<Checkbox name="25" onChange={onDecileChange} checked={selectedDeciles.includes(25)} />} label="25" />
        <FormControlLabel control={<Checkbox name="30" onChange={onDecileChange} checked={selectedDeciles.includes(30)} />} label="30" />
        <FormControlLabel control={<Checkbox name="40" onChange={onDecileChange} checked={selectedDeciles.includes(40)} />} label="40" />
        <FormControlLabel control={<Checkbox name="50" onChange={onDecileChange} checked={selectedDeciles.includes(50)} />} label="50" />
        <FormControlLabel control={<Checkbox name="60" onChange={onDecileChange} checked={selectedDeciles.includes(60)} />} label="60" />
        <FormControlLabel control={<Checkbox name="70" onChange={onDecileChange} checked={selectedDeciles.includes(70)} />} label="70" />
        <FormControlLabel control={<Checkbox name="75" onChange={onDecileChange} checked={selectedDeciles.includes(75)} />} label="75" />
        <FormControlLabel control={<Checkbox name="80" onChange={onDecileChange} checked={selectedDeciles.includes(80)} />} label="80" />
        <FormControlLabel control={<Checkbox name="90" onChange={onDecileChange} checked={selectedDeciles.includes(90)} />} label="90" />
        <FormControlLabel control={<Checkbox name="100" onChange={onDecileChange} checked={selectedDeciles.includes(100)} />} label="100" />

      </FormGroup>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
}
