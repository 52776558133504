import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect,useState } from 'react';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface PropsType {
  value?: Date | null;
  onChange: (date: Date | null) => void;
  label: string;
}

export function StoneXDatePicker(props: PropsType) {

  //when date is undefined, datepicker automatically assumes current day.
  //we prevent the behavior of undefined by converting it to null

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(props.value ?? null);

  useEffect(() => {
    props?.onChange(date);
  }, [date])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StoneXLabeledItem label={props.label}>
        <DatePicker
          openTo="day"
          views={['year', 'month', 'day']}
          value={date}
          onChange={setDate}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={function (params) {
            return <TextField {...params} sx={{ borderBottom: '1px solid', fieldset: { border: '0px' } }} onClick={(e) => setOpen(true)} />;
          }}
        />
      </StoneXLabeledItem>
    </LocalizationProvider>
  );
}
