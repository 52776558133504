import { Button,ButtonGroup,Chip } from '@mui/material';
import { ColDef,GetRowIdParams,ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React,{ useEffect,useMemo,useRef,useState } from 'react';
import { MarkItViewApi } from '../../../../Apis/Apis';
import { CommodityManagementSiteUserModel } from '../../../../Generated/Commodity-Management-Api';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXRow } from '../../../StoneX';

export default function UserManagementAdminSettings() {

  const gridRef = useRef<AgGridReact<CommodityManagementSiteUserModel>>(null);
  const siteGuid = useActiveApplication();
  const [siteUsers, setSiteUsers] = useState<CommodityManagementSiteUserModel[]>([]);

  useEffect(() => {
    MarkItViewApi.listCommodityManagementSiteUsers(null, siteGuid).then((res) => setSiteUsers(res.data.rows ?? []));
  }, []);

  const columnDefs = [
    {
      headerName: 'User',
      field: 'displayName',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Admin',
      field: 'isAdmin',
      sortable: true,
      width: 90,
      cellRendererFramework: (props: ICellRendererParams<CommodityManagementSiteUserModel>) =>
        props.data?.isSiteManager ? <Chip variant="success" label="ADMIN" /> : <></>,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 400,
      cellRendererFramework: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button onClick={() => {}}>Set Permissions</Button>
            <Button onClick={() => {}}>Promote</Button>
            <Button onClick={() => {}}>Disable</Button>
            <Button onClick={() => {}}>Remove</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  return (
    <>
      <StoneXRow align="end">
        <h3>Users</h3>
        <Button className="pull-right" variant="outlined" onClick={() => {}}>
          Add
        </Button>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            getRowId={(params: GetRowIdParams) => params.data.commodityManagementUserGuid}
            enableRangeSelection
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            rowData={siteUsers}
          />
        </div>
      </StoneXRow>
    </>
  );
}
