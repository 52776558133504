import { MenuItem,Select,SelectChangeEvent,TextField } from '@mui/material';
import { isString } from 'lodash';
import React,{ useEffect,useState } from 'react';
import { ContractDate } from '../../../Generated/Mark-It-View-Api';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface PropsType {
  value?: ContractDate | null;
  onChange?: (date: ContractDate | null) => void;
  label?: string;
  error?: string | undefined;
  disabled?: boolean;
  name?: string;
  width?: string | number;
  fullWidth?: boolean;
}

export function StoneXContractMonthDropdown(props: PropsType) {

  const isControlledInput = props.value === null;

  const [value, setValue] = useState<ContractDate | null>(props.value ?? null);

  const [month, setMonth] = useState<number | string | undefined>(isControlledInput ? "" : props?.value?.month);
  const [year, setYear] = useState<number | string | undefined>(isControlledInput ? "" : props?.value?.year);

  function onMonthChange(event: SelectChangeEvent<number | string>) {
     const fieldValue = event.target.value;
     let month: number | null = null;

     if (fieldValue) {
       month = typeof fieldValue === 'string' ? parseInt(fieldValue) : (fieldValue as number);
       const newValue: ContractDate | null = { year: value?.year, month: month };

       props.onChange && props.onChange(newValue);
       setValue(newValue);
     }
  }

  function onYearChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const fieldValue = event.target.value;

    if (fieldValue) {
      let newValue = { year: parseInt(fieldValue), month: value?.month };
       props.onChange && props.onChange(newValue);
       setValue(newValue);
    }
  }

  useEffect(() => {

    if (!props.onChange) {
      return;
    }
    
    if (month && year) {
      const yearNum = isString(year) ? parseInt(year) : (year as number);
      const monthNum = isString(month) ? parseInt(month) : month as number;
      props.onChange({ year: yearNum, month: monthNum });
    } 
    else {
      props.onChange(null);
    }

  }, [year, month])

  useEffect(() => {
    // setYear(props.value?.year);
    // setMonth(props.value?.month)
    setValue(props.value ?? null);
    
  }, [props.value])

  return (
    <StoneXLabeledItem label={props.label} fullWidth={props.fullWidth}>
      <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
        <Select fullWidth={props.fullWidth} value={value?.month ?? ''} onChange={onMonthChange}>
          <MenuItem value={1}>Jan</MenuItem>
          <MenuItem value={2}>Feb</MenuItem>
          <MenuItem value={3}>Mar</MenuItem>
          <MenuItem value={4}>Apr</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>Jun</MenuItem>
          <MenuItem value={7}>Jul</MenuItem>
          <MenuItem value={8}>Aug</MenuItem>
          <MenuItem value={9}>Sept</MenuItem>
          <MenuItem value={10}>Oct</MenuItem>
          <MenuItem value={11}>Nov</MenuItem>
          <MenuItem value={12}>Dec</MenuItem>
        </Select>
        <TextField InputProps={{ inputProps: { min: 2000 } }} fullWidth value={value?.year ?? ''} onChange={onYearChange} type="number" />
      </div>
    </StoneXLabeledItem>
  );
}
