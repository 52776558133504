import { Box, Button, Card, CardContent, List } from '@mui/material';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsStreamgraph from 'highcharts/modules/streamgraph';
import { debounce, flatMap, some, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useResizeListener } from '../../../../../Hooks';
import { MarketViewProductModel, DecileCalculatorResponse } from '../../../../../Generated/Mark-It-View-Api';
import { DecileQueryResult, DecileStripType } from '../../../../../Types';
import './../../Deciles.scss';
import { StoneXRow } from '../../../../StoneX';

HighchartsStreamgraph(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
let numeral = require('numeraljs');

interface QueryDisplayProps {
  queries: DecileQueryResult[];
}

type QuoteDate = string;
type Price = number | null | undefined;
type Rank = number | undefined;
type DecileRange = string;
type PriceRange = string | undefined;

type Row = { decile: DecileRange; price: { [key: string]: PriceRange } };

type DecilePrices = Record<DecileRange, PriceRange>;
type DailyPrices = Record<QuoteDate, Price>;
type Series = {
  queryId: number;
  //label: QuoteDate;
  //queryType: DecileStripType;
  product: MarketViewProductModel;
  prices: DecilePrices;
  season: string;
};
type GraphSeries = {
  queryId: number;
  label: QuoteDate;
  seriesType: string;
  product: MarketViewProductModel;
  prices: DailyPrices;
};

export default function QueryDisplay(props: QueryDisplayProps) {
  const { queries } = props;

  const [allQuerySeries, setAllQuerySeries] = useState<Series[]>([]);
  const [allGraphSeries, setAllGraphSeries] = useState<GraphSeries[]>([]);
  const [productYAxes, setProductYAxes] = useState<{ [productId: number]: 0 | 1 }>({});
  const [products, setProducts] = useState<{ [productId: number]: MarketViewProductModel }>({});

  const [columnDefs, setColumnDefs]: any[] = useState<any[]>([{ headerName: 'QuoteDate', field: 'quoteDate' }]);
  const [rowData, setRowData] = useState<Row[]>([]);
  const [showingChartControls, setShowingChartControls] = useState(false);

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const chartContainerRef = useRef(null);
  const chartContainerDimensions = useResizeListener(chartContainerRef);

  const chartSizeUpdate = useMemo(
    () =>
      debounce(() => {
        chartRef.current?.chart.setSize(chartContainerDimensions.x, chartContainerDimensions.y);
      }, 50),
    [chartContainerDimensions],
  );

  useEffect(() => {
    chartSizeUpdate();

    return () => {
      chartSizeUpdate.cancel();
    };
  }, [chartContainerDimensions]);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});

  const defaultOptions: Highcharts.Options = {
    // chart: {
    //   zoomType: 'x',
    // },
    title: {
      text: 'Prices',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%b %Y}',
        rotation: -45,
      },
    },
    yAxis: [
      {
        title: {
          text: undefined,
        },
      },
      {
        title: {
          text: undefined,
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    // exporting: {
    //   enabled: true
    // }
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        label: {
          onclick: function () {},
          text: 'Edit Axis',
        },
      },
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: ['downloadPNG', 'downloadSVG', 'separator', 'label'],
        },
      },
    },
    //   colors: StoneXColors,
  };

  const dateTimeToTimestamp = (date: string) => dayjs(date).unix() * 1000;

  const toggleYAxis = (productId: number) => {
    const newAxis: 0 | 1 = productYAxes[productId] == 0 ? 1 : 0;
    const newAxes = { ...productYAxes, [productId]: newAxis };
    setProductYAxes(newAxes);
  };

  const dailyPricesToArray = (quoteDatePrices: DailyPrices): [number, Price][] => {
    const prices: [number, Price][] = [];

    for (const quoteDate in quoteDatePrices) {
      const price = quoteDatePrices[quoteDate];
      let timestamp = dateTimeToTimestamp(quoteDate);
      prices.push([timestamp, price]);
    }

    return prices.sort((a, b) => a[0] - b[0]);
  };

  const historicalSeriesToChartSeries = (series: GraphSeries): Highcharts.SeriesOptionsType => {
    return {
      type: series.seriesType,
      name: series.label,
      fillOpacity: 1,
      step: 'left',
      data: dailyPricesToArray(series.prices),
      yAxis: productYAxes[series.product.productId],
    } as Highcharts.SeriesOptionsType;
  };

  const buildHistoricalChart = (allSeries: GraphSeries[]) => {
    let chartSeries: Highcharts.SeriesOptionsType[] = [];

    for (const series of allSeries) {
      const seriesAsHighchartSeries = historicalSeriesToChartSeries(series);
      chartSeries = [...chartSeries, seriesAsHighchartSeries];
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: chartSeries,
    };

    setChartOptions(options);
  };

  const decilesToArray = (query: DecileCalculatorResponse): [number, Price][] => {
    const prices: [number, Price][] = [];

    query.rows!.forEach((q) => {
      q.weightedDeciles.forEach((d) => {
        prices.push([d.rank!, d.value]);
      });
    });

    return prices;
  };

  const decileQueryToChartSeries = (query: DecileQueryResult) => {
    return {
      type: 'areaspline',
      name: query.product.name,
      data: decilesToArray(query.query as DecileCalculatorResponse),
      yAxis: productYAxes[query.product.productId],
    } as Highcharts.SeriesOptionsType;
  };

  const buildForwardChart = (queries: DecileQueryResult[]) => {
    let chartSeries: Highcharts.SeriesOptionsType[] = [];

    for (const query of queries) {
      chartSeries.push(decileQueryToChartSeries(query));
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: chartSeries,
    };

    setChartOptions(options);
  };

  const buildChart = (allSeries: GraphSeries[]) => {
    buildHistoricalChart(allSeries);
  };

  const getQueryDecileSeries = (queryId: number, product: MarketViewProductModel, query: DecileCalculatorResponse): Series[] => {
    const allRanks = flatMap(query.rows, (q) => q.weightedDeciles!.map((r) => r.rank!));
    const ranks = uniq(allRanks);
    let season: string;

    let series: Series[] = [];

    //query.rows?.forEach((q) => {
    var q = query.rows![0];
    let decilePrices: DecilePrices = {};
    season = q.season;

    season = q.season;

    decilePrices['Mean'] = q.weightedMean ? numeral(q.weightedMean).format('(0,0.000)') : '-';
    const median = q.weightedDeciles.find((r) => r.rank! == 0.5)?.value!.toString();
    decilePrices['Median'] = median ? numeral(median).format('(0,0.000)') : '-';

    let prev = q.weightedDeciles[0];

    q.weightedDeciles?.forEach((w) => {
      const r = (w.rank as number) * 100;
      if (r! % 2 == 0 && w.rank! != 0) {
        decilePrices[((prev.rank as number) * 100).toString() + ' - ' + r!.toString()] =
          (prev.value ? numeral(prev.value).format('(0,0.000)') : '-').toString() + ' - ' + (w.value ? numeral(w.value).format('(0,0.000)') : '-').toString();
        prev = w;
      }

      /*q.weightedDeciles.forEach((d) => {
          //const price = d.weightedDeciles?.find((r) => r.rank! === rank)?.value;
          decilePrices[d.rank!] = d.value;
        });*/
    });

    const seriesData: Series = {
      queryId: queryId,
      //label: contractDate,
      prices: decilePrices,
      product: product,
      //queryType: DecileStripType,
      season: season,
    };

    series.push(seriesData);
    //});

    return series;
  };

  const getQueryGraphSeries = (queryId: number, product: MarketViewProductModel, query: DecileCalculatorResponse, deciles: number[]): GraphSeries[] => {
    const allContractDates = flatMap(query.rows, (q) => q.startDate!);
    const contractDates = uniq(allContractDates);

    let series: GraphSeries[] = [];

    deciles?.forEach((d) => {
      let quoteDatePrices: DailyPrices = {};

      query.rows?.forEach((q) => {
        const price = q.weightedDeciles?.find((r) => r.rank! === d / 100)?.value;
        quoteDatePrices[q.startDate!] = price;
      });

      const seriesData: GraphSeries = {
        queryId: queryId,
        label: d.toString(),
        prices: quoteDatePrices,
        product: product,
        seriesType: 'area',
      };

      series.push(seriesData);
    });

    let termPrices: DailyPrices = {};
    query.rows![0].innerPrices.forEach((cv) => {
      if (!termPrices[cv.quoteDate!]) {
        const price = query.rows![0].innerPrices.filter((f) => f.quoteDate == cv.quoteDate);
        let avg = 0;
        price.forEach((p) => {
          avg += p.price!;
        });

        avg == avg / price.length;
        termPrices[cv.quoteDate!] = avg / price.length;
      }
    });

    const lineData: GraphSeries = {
      queryId: queryId,
      label: 'Price',
      prices: termPrices,
      product: product,
      seriesType: 'line',
    };

    series.push(lineData);

    return series;
  };

  const buildQueryPriceDict = (queries: DecileQueryResult[]): void => {
    let allSeriesDict: Series[] = [];
    let allGraphSeriesDict: GraphSeries[] = [];

    queries.forEach((q) => {
      allSeriesDict = [...allSeriesDict, ...getQueryDecileSeries(q.id, q.product, q.query as DecileCalculatorResponse)];
      allGraphSeriesDict = [...allGraphSeriesDict, ...getQueryGraphSeries(q.id, q.product, q.query as DecileCalculatorResponse, q.deciles!)];
    });

    setAllQuerySeries(allSeriesDict);
    setAllGraphSeries(allGraphSeriesDict);
  };

  const getAllQuoteDates = (allSeries: Series[]): string[] => {
    let allQuoteDates: string[] = [];

    for (const series of allSeries) {
      allQuoteDates = [...allQuoteDates, ...Object.keys(series.prices)];
    }

    const uniqueQuoteDates = uniq(allQuoteDates).sort();
    return uniqueQuoteDates;
  };
  const getAllDeciles = (allSeries: Series[]): string[] => {
    let allDeciles: string[] = [];

    for (const series of allSeries) {
      allDeciles = [...allDeciles, ...Object.keys(series.prices)];
    }

    const uniqueDeciles = uniq(allDeciles).sort();
    return uniqueDeciles;
  };

  const queriesInGridDataFormat = (series: Series[]): Row[] => {
    const allDeciles = getAllDeciles(series);
    let rows: Row[] = [];

    allDeciles.forEach((d) => {
      let prices: { [key: string]: PriceRange } = {};

      for (const key in series) {
        const price = series[key].prices[d];
        prices[key] = price;
      }

      const row: Row = {
        decile: d,
        price: prices,
      };

      rows.push(row);
    });

    return rows;
  };

  const buildTable = (allSeries: Series[]) => {
    const dataInGridFormat = queriesInGridDataFormat(allSeries);
    setRowData(dataInGridFormat);

    console.log(`there are ${allSeries.length} series`);

    const bgColor = ['#99A1A1', '#4B738B', '#336094', '#7DA2B9', '#427ABE'];

    const seriesHeaders = allSeries.map((series, index) => {
      //const headerStyles = ".ag-header {background-color: yellow;}";
      return {
        headerName: series.season,
        valueGetter: (params: { data: Row }) => {
          return params.data.price[index] ?? 0;
        },
        cellStyle: { 'background-color': bgColor[index], color: 'white' },
        headerClass: 'headerStyles' + index,
      };
    });

    const decileHeader = {
      headerName: '',
      field: 'decile',
      cellStyle: { 'background-color': '#7AADD4', color: 'white' },
      //valueFormatter: (params: any) => dayjs(params.value).format('L'),
    };

    setColumnDefs([decileHeader, ...seriesHeaders]);
  };

  const setDefaultProductYAxes = (allSeries: GraphSeries[]) => {
    //just set all products to y-axis 1
    const axes: { [productId: number]: 0 | 1 } = {};

    allSeries.forEach((s) => {
      if (axes[s.product.productId]) return;
      axes[s.product.productId] = 0;
    });

    setProductYAxes(axes);
  };

  const setProductsDict = (queries: DecileQueryResult[]) => {
    const products: { [productId: number]: MarketViewProductModel } = {};

    queries.forEach((q) => {
      products[q.product.productId] = q.product;
    });

    setProducts(products);
  };

  useEffect(() => {
    buildQueryPriceDict(queries);
    setProductsDict(queries);
  }, [queries]);

  useEffect(() => {
    setDefaultProductYAxes(allGraphSeries);
    buildChart(allGraphSeries);
    buildTable(allQuerySeries);
  }, [allQuerySeries]);

  useEffect(() => {
    buildChart(allGraphSeries);
  }, [productYAxes]);

  return (
    <>
      <StoneXRow align="start">
        <CardContent style={{ width: '250px' }}>
          <Button
            size="small"
            onClick={(e: any) => {
              setShowingChartControls((p) => !p);
            }}
          >
            {showingChartControls ? 'Hide Controls' : 'Show Controls'}
          </Button>
          {showingChartControls && (
            <Box sx={{ width: '250px' }}>
              {Object.values(products).map((q) => (
                <List key={q.productId} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{q.name}</div>
                  {/* <IconButton edge='end' size="small" onClick={() => toggleYAxis(q.productId)}>
                      {productYAxes[q.productId] == 1 ? <LooksOne /> : <LooksTwo />}
                    </IconButton> */}
                  <Button variant="outlined" onClick={(e: any) => toggleYAxis(q.productId)} size="small">
                    {productYAxes[q.productId] + 1}
                  </Button>
                </List>
                // <ListItem>
                //   <ListItemText primary={q.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}></ListItemText>

                // </ListItem>
              ))}
            </Box>
          )}
        </CardContent>
        <div ref={chartContainerRef} style={{ width: '1000px', justifySelf: 'center', overflow: 'auto', resize: 'both', padding: 0, margin: 0, zIndex: 2 }}>
          <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        </div>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ height: '800px' }}>
        <AgGridReact columnDefs={columnDefs} rowData={rowData} />
      </div>
    </>
  );
}
