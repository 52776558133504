import { CloseOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import StoneXRow from '../../StoneX/StoneXRow/StoneXRow';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: '1500px',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};

interface StoneXModalProps {
  header: string;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  // width?: number;
}

export default function StoneXModal(props: StoneXModalProps) {

  const {header, open, onClose, children} = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <StoneXRow align="start">
          <h2>{header}</h2>

          <IconButton className="pull-right">
            <CloseOutlined onClick={onClose} />
          </IconButton>
        </StoneXRow>
        <Typography sx={{ mt: 2 }}>{props?.children}</Typography>
      </Box>
    </Modal>
  );
}
