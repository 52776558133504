import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../ReduxStore';

export interface SystemState {
    storeId: string;
}

const initialState: SystemState = {
    storeId: 'default',
};

// This file was based off the Redux example:
// For information about async reducers check out the below.
// https://github.com/reduxjs/cra-template-redux-typescript/blob/master/template/src/features/counter/counterSlice.ts
export const systemSlice = createSlice({
    name: 'system',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    reducers: {
        setStoreId: (state: SystemState, action: PayloadAction<{ storeId: string }>): void => {
            state.storeId = action.payload.storeId;
        },
    },
});

export const { setStoreId } = systemSlice.actions;

export const selectStoreId = (state: RootState): string => state.system?.storeId ?? 'default';

export default systemSlice.reducer;
