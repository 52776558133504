import { Button } from '@mui/material';
import { ColDef,FirstDataRenderedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { find } from 'lodash';
import { useCallback,useMemo,useRef } from 'react';
import { useNavigate } from 'react-router';
import { BudgetModel } from '../../../../../Generated/Commodity-Management-Api';
import { StoneXRow } from '../../../../StoneX';
import { StoneXChevron } from '../../../../StoneXMui';

interface BudgetTableProps {
  locationId?: number;
  commodityId?: number;
  year: number;
  budgets: BudgetModel[];
  counterpartyId?: number;
  labelByCommodity: boolean;
  labelByLocation: boolean;
  labelByCounterparty: boolean;
}

export default function BudgetTable(props: BudgetTableProps) {

  const gridRef = useRef<AgGridReact<BudgetModel>>(null);
  const navigate = useNavigate();
  const firstBudget = props.budgets[0];
  const components = firstBudget.pricingStrategy.components;
  const commodityId = firstBudget.commodity.commodityId;
  const locationId = firstBudget.location?.locationId;
  const counterpartyId = firstBudget.counterparty?.counterpartyId;

  const commodityLabel = props.labelByCommodity ? firstBudget.commodity.commodityName : "";
  const locationLabel = props.labelByLocation ? firstBudget.location?.locationName ?? "(No location)" : "";
  const counterpartyLabel = props.labelByCounterparty ? firstBudget.counterparty?.counterpartyName ?? "(No counterparty)" : "";
  const label = [commodityLabel, locationLabel, counterpartyLabel].filter(q => !!q).join(' - ');

    
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.sizeColumnsToFit();
  }, []);

  const pricingComponents = components.length > 1 ? 
    components.map(q => {
      return {
        headerName: q.componentType!,
        valueGetter: (params: any) => {
          return find(params.data.budgetPrices, r => r.componentType == q.componentType)?.price ?? 0;
        },
      };
    })
  : [{ headerName: 'Price', field: 'price' }];

  const columns = [
    { headerName: 'Commodity', field: 'commodity.commodityName' },
    { headerName: 'Counterparty', field: 'counterparty.counterpartyName', hide: true },
    { headerName: 'Location', field: 'location.locationName' },
    ...pricingComponents,
    { headerName: 'Budget Month', field: 'budgetDate.label' },
    { headerName: 'Contract Month', field: 'contractDate.label' },
    { headerName: 'Volume', field: 'volume' },
    { headerName: 'Currency', field: 'currency' },
  ];

  function editBudgets() {
    const params = new URLSearchParams({
      year: props.year.toString(),
      commodityId: commodityId?.toString(),
    });

    if (locationId) {
      params.append('locationId', locationId.toString());
    }

    if (counterpartyId) {
      params.append('counterpartyId', counterpartyId.toString());
    }

    navigate(`update?${params.toString()}`);
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

   return (
     <StoneXChevron label={label}>
       <StoneXRow>
         <div className="ag-theme-alpine" style={{ width: '100%' }}>
           <Button variant="text" onClick={editBudgets}>Edit budgets</Button>
           <AgGridReact
             enableRangeSelection
             ref={gridRef}
             domLayout="autoHeight"
             columnDefs={columns}
             defaultColDef={defaultColDef}
             rowData={props.budgets}
             onFirstDataRendered={onFirstDataRendered}
           />
         </div>
       </StoneXRow>
     </StoneXChevron>
   );
}
