import dayjs from 'dayjs';
import { ContractDate } from '../Generated/Mark-It-View-Api';
import { numberToShortMonth } from './MonthHelper';
export class ContractDateHelper {
  public static toLabel(contractDate: ContractDate) {
    return `${numberToShortMonth(contractDate.month!)} ${contractDate.year}`;
  }

  public static addMonths(contractDate: ContractDate, monthsToAdd: number): ContractDate {
    const newDate = new Date(contractDate.year!, contractDate.month! - 1 + monthsToAdd, 1);

    console.log('newDate', newDate);

    return {
      year: newDate.getFullYear(),
      month: newDate.getMonth() + 1,
    };
  }

  public static getStartContractDate(year: number, offset?: number): ContractDate {
    let startMonth = { year: year, month: 1 };

    if (offset) {
      return this.addMonths(startMonth, offset);
    }

    return startMonth;
  }

  public static getFiscalYear(contractDate: ContractDate, offset: number) {
    if (offset != 0 && offset > contractDate.month!) {
      return contractDate.year! + 1;
    }

    return contractDate.year!;
  }

  public static fromDate(date: Date) {
    const day = dayjs(date);
    return { year: day.year(), month: day.month() };
  }

  public static toDate(contractDate: ContractDate) {
    return new Date(contractDate.year!, contractDate.month!, 1);
  }

  public static toMonthYearString(date: ContractDate): string {
    return dayjs(`${date.year}-${date.month}-01`).format('MMMM YYYY');
  }

  public static isGreaterThanOrEqualTo(a: ContractDate, b: ContractDate) {
    if (a === undefined || b === undefined) return undefined;

    // If years are not the same, compare years directly
    if (a.year !== b.year) {
      return a.year! >= b.year!;
    }

    // If years are the same, compare months
    if (a.month !== b.month) {
      return a.month! >= b.month!;
    }

    // If both year and month are the same, return true (since they are equal)
    return true;
  }

  public static isLessThanOrEqualTo(a: ContractDate, b: ContractDate) {
    if (a === undefined || b === undefined) return undefined;

    // If years are not the same, compare years directly
    if (a.year !== b.year) {
      return a.year! <= b.year!;
    }

    // If years are the same, compare months
    if (a.month !== b.month) {
      return a.month! <= b.month!;
    }

    // If both year and month are the same, return true (since they are equal)
    return true;
  }
}
