import { ColDef,GridOptions,ICellRendererParams,ValueGetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Currency,GetCustomerDataResponse,PositionModel } from "../../../../../../Generated/Commodity-Management-Api";
import { StoneXNumber } from "../../../../../StoneXMui";

interface MarkToMarketTableProps {
  positions: PositionModel[];
  customerData: GetCustomerDataResponse;
  grouping: string[];
}

export default function MarkToMarketTable(props: MarkToMarketTableProps) {
 
  const { customerData, positions, grouping } = props;

  function getTierColumnDefs(): ColDef<PositionModel>[] {
    const tiers = customerData.tiers?.sort((a, b) => b.tierRank! - a.tierRank!) ?? [];

    const tierColumnDefs = tiers.map(
      (q, i) =>
        ({
          colId: q.tierName,
          headerName: q.tierName,
          hide: i != 0,
          valueGetter: (params: ValueGetterParams<PositionModel>) => {

            //don't show data for grouped rows
            if (params.node?.group) {
              return null;
            }
            
            return params.data?.locationHierarchy[i]?.locationName ?? `No ${q.tierName}`;
          },
          rowGroupIndex: getRowGroupIndex(q.tierName!) ?? null,
        } as ColDef<PositionModel>),
    );

    return tierColumnDefs;
  }

  function getRowGroupIndex(colId: string): number | undefined {
    const index = grouping.indexOf(colId);
    return index < 0 ? undefined : index;
  }
  
  const columns: ColDef<PositionModel>[] = [
    {
      colId: 'Commodity',
      headerName: 'Commodity',
      field: 'commodity.commodityName',
      rowGroupIndex: getRowGroupIndex('Commodity'),
    },
    {
      colId: 'Counterparty',
      headerName: 'Counterparty',
      field: 'counterparty.counterpartyName',
      rowGroupIndex: getRowGroupIndex('Counterparty'),
    },
    ...getTierColumnDefs(),
    {
      colId: 'Budget Month',
      headerName: 'Budget Month',
      field: 'budgetDate.label',
      rowGroupIndex: getRowGroupIndex('Budget Month'),
    },
    {
      colId: 'Contract Month',
      headerName: 'Contract Month',
      field: 'contractDate.label',
    },
    {
      colId: 'Volume',
      headerName: 'Volume',
      field: 'volume',
      cellRenderer: (params: ICellRendererParams<PositionModel>) => params.node.group ? null : <StoneXNumber number={params.value}></StoneXNumber>,
    },
    {
      colId: 'Currency',
      headerName: 'Currency',
      field: 'currency',
    },
    {
      colId: 'Trade Price',
      headerName: 'Trade Price',
      field: 'price',
      cellRenderer: (params: ICellRendererParams<PositionModel>) => params.node.group ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={2}></StoneXNumber>,
    },
    {
      colId: 'Market Price',
      headerName: 'Market Price',
      field: 'marketPrice',
      cellRenderer: (params: ICellRendererParams<PositionModel>) => params.node.group ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={2}></StoneXNumber>,
    },
    {
      colId: 'Mtm Value',
      headerName: 'MTM Value',
      field: 'markToMarketValue',
      aggFunc: 'sum',
      cellRenderer: (params: ICellRendererParams<PositionModel>) => <StoneXNumber number={params.value} currency={Currency.Usd} colored></StoneXNumber>,
    },
  ];


  const options: GridOptions<PositionModel> = {
    // animateRows: true,
    rowHeight: 30,
    domLayout: 'autoHeight',
    groupDisplayType: 'singleColumn',
    rowGroupPanelShow: 'never',
    suppressAggFuncInHeader: true,
    // adds subtotals
    // groupIncludeFooter: true,
    // includes grand total
    groupIncludeTotalFooter: true,
    autoGroupColumnDef: {
      minWidth: 300,
      flex: 1,
      cellRendererParams: {
        suppressCount: true,
        footerValueGetter: (params: any) => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
    },
  };

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact columnDefs={columns} gridOptions={{ ...options, domLayout: 'autoHeight' }} rowData={positions} />
      </div>
    </>
  );
}
