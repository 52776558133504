import React from 'react';
import { Currency } from '../../../Generated/Commodity-Management-Api';

interface StoneXNumberProps {
  number?: number;
  decimals?: number;
  colored?: boolean;
  flipped?: boolean;
  currency?: Currency;
}

export function StoneXNumber(props: StoneXNumberProps) {
  
  const { number, decimals = 0, colored = false, flipped = false, currency } = props;

  const isNegative = number !== undefined && (flipped ? number > 0 : number < 0);

  const formattedNumber = number !== undefined 
    ? Math.abs(number).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
    : '-';

  const colorClass = getColorClass(number, colored);

  const displayNumber = isNegative ? `(${formattedNumber})` : formattedNumber;
  console.log(formattedNumber, Number(formattedNumber));  

  const currencySymbol = currency ? getSymbol(currency) : '';

  return (
    <span className={colorClass} style={{width: '100%', textAlign: 'right'}}>
      {currencySymbol}
      {displayNumber}
    </span>
  );
}

function getColorClass(number: number | undefined, colored: boolean) {
  if (!colored || number === undefined || number === 0) return undefined;
  return number > 0 ? 'green' : 'red';
}

function getSymbol(code: string): string | undefined {
  const currencySymbols: { [index: string]: string } = {
    USD: '$', // US Dollar
    CAD: 'C$', // Canadian Dollar
    EUR: '€', // Euro
    AUD: 'A$', // Australian Dollar
    GBP: '£', // British Pound
    BRL: 'R$', // Brazilian Real
    NZD: 'NZ$', // New Zealand Dollar
    MXN: 'MX$', // Mexican Peso
  };
  return currencySymbols[code];
}