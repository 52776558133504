import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect,useState } from 'react';
import { ContractDate } from '../../../Generated/Mark-It-View-Api';
import { ContractDateHelper } from '../../../Helpers';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface PropsType {
  value?: ContractDate | null;
  onChange?: (contractDate: ContractDate | null) => void;
  label?: string;
  error?: string | undefined;
  disabled?: boolean;
  width?: string | number;
  name?: string;
}

export function StoneXContractMonthPicker(props: PropsType) {
  //when date is undefined, datepicker automatically assumes current day.
  //we prevent the behavior of undefined by converting it to null

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(props.value ? ContractDateHelper.toDate(props.value) : null);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(date ? ContractDateHelper.fromDate(date) : null);
    }
  }, [date])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StoneXLabeledItem label={props.label}>
        <DatePicker
          openTo="month"
          views={['year', 'month']}
          value={date}
          onChange={setDate}
          inputFormat="MM/YYYY"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={function (params) {
            return (
              <TextField
                {...params}
                sx={{ borderBottom: '1px solid', fieldset: { border: '0px' } }}
                name={props.name}
                error={!!props.error}
                helperText={props.error}
                disabled={props.disabled}
                onClick={(e) => setOpen(true)}
              />
            );
          }}
        />
      </StoneXLabeledItem>
    </LocalizationProvider>
  );
}
