import { TextField } from '@mui/material';
import React from 'react';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface StoneXTextFieldProps {
  id?: string;
  label?: string;
  type?: any;
  defaultValue?: any;
  min?: number;
  max?: number;
  name?: string;
  value?: any;
  error?: boolean;
  placeholder?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

export function StoneXTextField(props: StoneXTextFieldProps) {

  return (
    <StoneXLabeledItem label={props.label} fullWidth={props.fullWidth}>
      <TextField
        InputProps={{ inputProps: { min: props.min, max: props.max } }}
        id={props.id}
        error={props.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
    </StoneXLabeledItem>
  );
}
