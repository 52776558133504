import { LoadingStateMonitor } from '../Types';
import { ManyLoadingStatesMonitor } from '../Types/LoadingState';

export default function useManyLoadingStates(loadingStates: LoadingStateMonitor[]): ManyLoadingStatesMonitor {
  const loading = loadingStates.map((q) => q.isLoading());
  const loaded = loadingStates.map((q) => q.isLoaded());
  const failed = loadingStates.map((q) => q.failed());

  return {
    isLoading: loading.indexOf(true) >= 0,
    isLoaded: loaded.indexOf(false) < 0,
    failed: failed.indexOf(true) >= 0,
  };
}
