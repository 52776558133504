import { Box, Card, CardHeader } from '@mui/material';
import { useEffect, useState } from 'react';
import { DecileQuery } from '../../../../Types/DecileQueryResult';
import { DecileSavedQuerySet } from '../../../../Types/DecileSavedQuerySet';
import QueryDisplay from './DecileQueryDisplay/DecileQueryDisplay';
import QueryFormList from './DecileQueryFormList/DecileQueryFormList';
import { StoneXDivider } from '../../../StoneX';

interface QueryPageProps {
  id: number;
  onTitleChange?: (id: number, title: string) => void;
  querySet?: DecileSavedQuerySet;
  save: (querySetToSave: DecileSavedQuerySet) => Promise<void>;
}

export function QueryPage(props: QueryPageProps) {
  const [queries, setQueries] = useState<DecileQuery[]>([]);
  const [querySetsParams, setQuerySetsParams] = useState<DecileSavedQuerySet>();

  useEffect(() => {
    if (props.onTitleChange && queries.length > 0) {
      props.onTitleChange(props.id, queries[0].product.name);
    }
  }, [queries]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <QueryFormList onQueriesUpdate={setQueries} onQueryParamsUpdate={setQuerySetsParams} querySet={props.querySet} saveQuerySet={props.save} />
      </Box>

      <StoneXDivider />

      {queries.length > 0 && <QueryDisplay queries={queries} />}
    </>
  );
}
