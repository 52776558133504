import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { StoneXLoading } from '../../Components/StoneX';

// Adopted from https://github.com/okta/okta-react/blob/master/src/LoginCallback.tsx
const OktaLoginCallback: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = useState<Error | null>(null);

  useEffect(() => {
    oktaAuth
      .handleLoginRedirect()
      .then(() => {
        // In `<Security>` component service was not started in case of login redirect.
        // Start it now after `restoreOriginalUri` has been called and route changed.
        oktaAuth.start();
      })
      .catch((e: Error) => {
        setCallbackError(e);
      });
  }, [oktaAuth]);

  const authError = authState?.error;
  const displayError = callbackError || authError;

  if (displayError) {
    if (displayError.name && displayError.message) {
      return (
        <p>
          {displayError.name}: {displayError.message}
        </p>
      );
    }

    return <p>Error: {displayError.toString()}</p>;
  }

  return <StoneXLoading />;
};

export default OktaLoginCallback;
