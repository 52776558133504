import { ChevronRight } from '@mui/icons-material';
import { Box,SxProps } from '@mui/material';
import React,{ ReactNode,useState } from 'react';

interface StoneXChevronProps {
  label: string;
  children: ReactNode;
}

const boxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  marginBottom: '20px',
} 


export function StoneXChevron(props: StoneXChevronProps) {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <Box sx={boxStyle}>
        <span style={{ display: 'flex' }} onClick={() => setIsOpen((previous) => !previous)}>
          <ChevronRight sx={{ transform: isOpen ? 'rotate(90deg)' : 'none', transition: 'transform .05s ease-out' }} />
          <h2 style={{ margin: 0 }}>{props.label}</h2>
        </span>
      </Box>

      {isOpen && props.children}
    </div>
  );
}
