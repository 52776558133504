import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import type { RootState } from '../ReduxStore';

export interface UserState {
    userGuid?: string;
    accessToken?: string;
    identityToken?: string;
}

const initialState: UserState = {};

// This file was based off the Redux example:
// For information about async reducers check out the below.
// https://github.com/reduxjs/cra-template-redux-typescript/blob/master/template/src/features/counter/counterSlice.ts
export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    reducers: {
        setUser: (state: UserState, action: PayloadAction<{ accessToken: string; identityToken: string }>): void => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            state.accessToken = action.payload.accessToken;
            state.identityToken = action.payload.identityToken;

            const decodedToken = jwt_decode(state.accessToken) as { cds_id: string };
            state.userGuid = decodedToken.cds_id;

            console.log("access tokens SAVED");
        },
        resetUser: (state: UserState): void => {
            delete state.userGuid;
            delete state.accessToken;
            delete state.identityToken;
        },
    },
});

export const { setUser, resetUser } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`.
// export const selectUser = (state: RootState): UserModel | null => state.user.user ?? null;
export const selectAccessToken = (state: RootState): string | null => state.user?.accessToken ?? null;
export const selectUserGuid = (state: RootState): string | null => state.user?.userGuid ?? null;

export default userSlice.reducer;
