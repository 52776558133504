import React from 'react'

export default function WhatIf() {
  return (
    <div>WhatIf</div>
  )
}


// import { Button } from '@mui/material';
// import { AgGridReact } from 'ag-grid-react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import React,{ useEffect,useState } from 'react';
// import { CommodityManagementApi,CommodityManagementMarketViewApi } from '../../../../Apis/Apis';
// import { CommodityModel,ContractDate,GetCustomerDataResponse,OptionStyle,OptionType,PositionType,Side,Unit,UpdateWhatIfPositionModel,UpdateWhatIfStrategyRequest,Variance,WhatIfStrategyModel,WhatIfVariance } from '../../../../Generated/Commodity-Management-Api';
// import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
// import { StoneXAutocomplete,StoneXContractMonthPicker,StoneXTextField } from '../../../StoneXMui';
// let numeral = require('numeraljs');

// const isDefined = (value: any): boolean => !(value == null || value == undefined);
// const formatAsValue = (params: any) => (isDefined(params.value) ? numeral(params.value).format('(0,0)') : '-');
// const formatAsPrice = (params: any) => (isDefined(params.value) ? numeral(params.value).format('(0,0.000)') : '-');
// const formatAsPercent = (params: any) => (isDefined(params.value) ? numeral(params.value).format('0%') : '-');

// interface UpdateStrategyFormProps{
//   strategy?: WhatIfStrategyModel | null;
//   customerData: GetCustomerDataResponse;
//   onUpdateStrategy: (strategies: UpdateWhatIfStrategyRequest) => Promise<any>;
//   onDeleteStrategy: (strategyId: number) => Promise<any>;
// }

// function UpdateStrategyForm(props: UpdateStrategyFormProps) {

//   interface StrategyData {
//     whatIfStrategyId?: number;
//     whatIfStrategyName?: string;
//   }

//   interface RowData {
//     whatIfPositionId?: number | null;
//     commodity?: CommodityModel | null;
//     commodityId?: number | null;
//     budgetMonth?: number | null;
//     budgetYear?: number | null;
//     budgetDate?: ContractDate | null;
//     positionType?: PositionType | null;
//     side?: Side | null;
//     price?: number | null;
//     volume?: number | null;
//     optionStrike?: number | null;
//     optionType?: OptionType | null;
//     optionStyle?: OptionStyle | null;
//     unit?: Unit | null;
//   }

//   const customerData = props.customerData;
//   const strategy = props.strategy;

//   const [strategyData, setStrategyData] = useState<StrategyData>({});
//   const [rows, setRows] = useState<RowData[]>([]);

//   const setStrategyName = (name?: string) => {
//     setStrategyData(previous => {
//       return {...previous, whatIfStrategyName: name}
//     })
//   }


//   useEffect(() => {
//     setRows(props.strategy ? props.strategy.positions : [{}]);
    
//     if (strategy) {
//       setStrategyData(strategy as StrategyData);
//     }

//   }, []);

//   const deletePosition = (index: number) => {
//     setRows(previous => previous.filter((r, i) => i !== index));
//   }

//   const addPosition = () => setRows(previous => [...previous, {}]);

//   const updatePosition = <K extends keyof RowData, V extends RowData[K]>(index: number, key: K, value: V): void => {
//     setRows((previous) =>
//       previous.map((row, i) => {
//         if (i == index) {
//           return { ...row, [key]: value };
//         }

//         return row;
//       }),
//     );
//   };

//   const updateStrategy = () => {
//     var updateRequest = {
//       whatIfStrategyId: strategy!.whatIfStrategyId,
//       whatIfStrategyName: strategy!.whatIfStrategyName!,
//       positions: rows.map(q => {
//        return {
//          whatIfPositionId: q.whatIfPositionId,
//          commodityId: q.commodity!.commodityId,
//          budgetMonth: q.budgetDate!.month,
//          budgetYear: q.budgetDate!.year,
//          positionType: q.positionType!,
//          side: q.side!,
//          price: q.price!,
//          volume: q.volume!,
//          optionStrike: q.optionStrike,
//          optionType: q.optionType,
//          optionStyle: q.optionStyle,
//          unit: q.unit!,
//        } as UpdateWhatIfPositionModel; 
//       })
//     } as UpdateWhatIfStrategyRequest

//     props.onUpdateStrategy(updateRequest);
//   };

//   const deleteStrategy = (strategyId: number) => {
//     props.onDeleteStrategy(strategyId);
//   }

//   return (
//     <>
//       <StoneXTextField type="text" value={strategyData!.whatIfStrategyName} onChange={(e: any) => setStrategyName(e.target.value)} />

//       {rows?.map((q, i) => {
//         return (
//           <div style={{ display: 'flex', flexDirection: 'row' }}>
//             <StoneXAutocomplete
//               options={customerData.commodities ?? []}
//               getOptionLabel={(q) => q.commodityName}
//               value={q.commodity}
//               onChange={(e: any, commodity: CommodityModel | null) => updatePosition(i, 'commodity', commodity)}
//               label="Commodity"
//             />

//             <StoneXContractMonthPicker
//               width="200px"
//               // value={q.contractMonth}
//               onChange={(budgetDate: ContractDate | null) => updatePosition(i, 'budgetDate', budgetDate)}
//               label="Budget Month"
//             />

//             <StoneXAutocomplete
//               options={['Buy', 'Sell']}
//               getOptionLabel={(q) => q}
//               onChange={(e: any, side: Side | null) => updatePosition(i, 'side', side)}
//               label="Side"
//             />

//             <StoneXAutocomplete
//               options={[PositionType.Future, PositionType.Option]}
//               getOptionLabel={(q) => q}
//               // value={selectedLocation}
//               onChange={(e: any, positionType: PositionType | null) => updatePosition(i, 'positionType', positionType)}
//               label="Position Type "
//             />

//             <StoneXAutocomplete
//               options={customerData.optionTypes ?? []}
//               getOptionLabel={(q) => q}
//               onChange={(e: any, optionType: OptionType | null) => updatePosition(i, 'optionType', optionType)}
//               label="Option Type "
//             />

//             <StoneXAutocomplete
//               options={customerData.optionStyles ?? []}
//               getOptionLabel={(q) => q}
//               onChange={(e: any, optionStyle: OptionStyle | null) => updatePosition(i, 'optionStyle', optionStyle)}
//               label="Option Style "
//             />

//             <StoneXTextField type="number" placeholder="Strike" onChange={(e: any) => updatePosition(i, 'optionStrike', e?.target.value)} min={0} />

//             <StoneXTextField type="number" placeholder="Quantity" onChange={(e: any) => updatePosition(i, 'volume', e?.target.value)} min={0} />

//             <StoneXTextField type="number" placeholder="Price" onChange={(e: any) => updatePosition(i, 'price', e?.target.value)} min={0} />

//             <StoneXAutocomplete
//               options={customerData.units ?? []}
//               getOptionLabel={(q) => q}
//               // value={selectedLocation}
//               onChange={(e: any, unit: Unit | null) => updatePosition(i, 'unit', unit)}
//               label="Unit"
//             />

//             <Button onClick={() => deletePosition(i)}>Delete</Button>
//           </div>
//         );
//       })}

//       <Button onClick={addPosition}>Add Position</Button>
//       <Button onClick={updateStrategy}>Save Strategy</Button>
//     </>
//   );

// }

// interface ManageStrategiesProps {
//   strategies: WhatIfStrategyModel[];
//   customerData: GetCustomerDataResponse;
//   onUpdateStrategy: (strategies: UpdateWhatIfStrategyRequest) => Promise<any>;
//   onDeleteStrategy: (strategyId: number) => Promise<any>;
//   onClose: () => void;
// }

// function MangeStrategies(props: ManageStrategiesProps,) {

//   const [selectedStrategy, setSelectedStrategy] = useState<WhatIfStrategyModel | null>();

//   return (
//     <>
//       <Button onClick={() => props.onClose()}>Close</Button>
//       <StoneXAutocomplete
//         options={props.strategies}
//         getOptionLabel={(q) => q.whatIfStrategyName}
//         onChange={(e: any, strategy: WhatIfStrategyModel | null) => setSelectedStrategy(strategy)}
//       />
//       <UpdateStrategyForm strategy={selectedStrategy} customerData={props.customerData} onUpdateStrategy={props.onUpdateStrategy} onDeleteStrategy={props.onDeleteStrategy} />
//     </>
//   );
// }

// function Chart(props: { variances: WhatIfVariance[] }) {
//   interface ChartData {
//     stressPercent: number;
//     budgetValue: number;
//     blendedValue: number;
//     blendedValueWithStrategy: number;
//   }

//   interface ChartProps {
//     data: ChartData[];
//   }

//   const [chartData, setChartData] = useState<ChartData[]>([]);

//   useEffect(() => {
//     setChartData(
//       props.variances.map((variance) => {
//         return {
//           stressPercent: variance.stressPercent!,
//           budgetValue: variance.varianceByEntity?.entities.reduce((aggregate, current) => aggregate + (current.total.budgetValue ?? 0), 0),
//           blendedValue: variance.varianceByEntity?.entities.reduce((aggregate, current) => aggregate + (current.total.blendedValue ?? 0), 0),
//           blendedValueWithStrategy: variance.varianceByEntityWithStrategy?.entities.reduce(
//             (aggregate, current) => aggregate + (current.total.blendedValue ?? 0),
//             0,
//           ),
//         } as ChartData;
//       }),
//     );
//   }, [props.variances]);

//   const chartOptions: Highcharts.Options = {
//     chart: {
//       type: 'column',
//     },
//     title: {
//       text: 'Stress Chart',
//     },
//     xAxis: {
//       title: {
//         text: 'Stress Percent',
//       },
//       categories: chartData.map((item) => item.stressPercent.toString()),
//     },
//     yAxis: {
//       title: {
//         text: 'Values',
//       },
//     },
//     series: [
//       {
//         type: 'line',
//         name: 'Budget Value',
//         data: chartData.map((item) => item.budgetValue),
//         marker: {
//           enabled: false,
//         },
//       },
//       {
//         type: 'column',
//         name: 'Blended Value',
//         data: chartData.map((item) => item.blendedValue),
//       },
//       {
//         type: 'column',
//         name: 'Blended Value With Strategy',
//         data: chartData.map((item) => item.blendedValueWithStrategy),
//       },
//     ],
//     plotOptions: {
//       column: {
//         grouping: true,
//         borderWidth: 0,
//       },
//     },
//   };

//   return (
//     <div>
//       <HighchartsReact highcharts={Highcharts} options={chartOptions} />
//     </div>
//   );
// }

// function StressTable(props: { variances: WhatIfVariance[] }) {
  
//   interface RowData {
//     stressPercent: number;
//     budgetValue: number;
//     blendedValue: number;
//     vtbValue: number;
//     vtbPercent: number;
//     blendedValueWithStrategy: number;
//     vtbValueWithStrategy: number;
//     vtbPercentWithStrategy: number;
//   }

//   const [rowData, setRowData] = useState<RowData[]>();

//   useEffect(() => {
//     setRowData(
//       props.variances.map((variance) => {
//         return {
//           stressPercent: variance.stressPercent,
//           budgetValue: variance.varianceByEntity?.entities.reduce((aggregate, current) => aggregate + (current.total.budgetValue ?? 0), 0),
//           blendedValue: variance.varianceByEntity?.entities.reduce((aggregate, current) => aggregate + (current.total.blendedValue ?? 0), 0),
//           vtbValue: variance.varianceByEntity?.entities.reduce((aggregate, current) => aggregate + (current.total.vtbValue ?? 0), 0),
//           vtbPercent: 0,
//           blendedValueWithStrategy: variance.varianceByEntityWithStrategy?.entities.reduce(
//             (aggregate, current) => aggregate + (current.total.blendedValue ?? 0),
//             0,
//           ),
//           vtbValueWithStrategy: variance.varianceByEntityWithStrategy?.entities.reduce(
//             (aggregate, current) => aggregate + (current.total.blendedValue ?? 0),
//             0,
//           ),
//           vtbPercentWithStrategy: 0,
//         } as RowData;
//       }),
//     );
//   }, [props.variances]);

//   const columns = [
//     { headerName: 'Percent Change', field: 'stressPercent', valueFormatter: formatAsPercent },
//     { headerName: 'Budget Value', field: 'budgetValue', valueFormatter: formatAsValue },
//     {
//       headerName: 'Current Hedges',
//       children: [
//         { headerName: 'Blened Value', field: 'blendedValue', valueFormatter: formatAsValue },
//         { headerName: 'VTB', field: 'vtbValue', valueFormatter: formatAsValue },
//         { headerName: 'VTB %', field: 'vtbPercent', valueFormatter: formatAsPercent },
//       ],
//     },
//     {
//       headerName: 'With Strategy',
//       children: [
//         { headerName: 'Blened Value', field: 'blendedValueWithStrategy', valueFormatter: formatAsValue },
//         { headerName: 'VTB', field: 'vtbValueWithStrategy', valueFormatter: formatAsValue },
//         { headerName: 'VTB %', field: 'vtbPercentWithStrategy', valueFormatter: formatAsPercent },
//       ],
//     },
//   ];

//   return (
//     <div className="ag-theme-alpine" style={{ width: '100%', height: 'auto' }}>
//       <AgGridReact rowData={rowData} columnDefs={columns} defaultColDef={{ flex: 1 }} domLayout="autoHeight" />
//     </div>
//   );
// }

// function CommodityTable(props: { variances: WhatIfVariance[] }) {

//   interface RowData 
//   {
//     commodityName: string,
//     variance: Variance,
//     varianceWithStrategy: Variance    
//   }

//   const [rowData, setRowData] = useState<RowData[]>();

//   useEffect(() => {
//     var unstressedVariance = props.variances.find(q => q.stressPercent == 0);
//     const commodities = unstressedVariance?.varianceByEntity?.entities.map(q => q.entityName!);

//     const rowData = commodities?.map(commodityName => {
//       return {
//         commodityName: commodityName,
//         variance: unstressedVariance?.varianceByEntity?.entities.find(q => q.entityName == commodityName)?.total,
//         varianceWithStrategy: unstressedVariance?.varianceByEntityWithStrategy?.entities.find(q => q.entityName == commodityName)?.total,
//       } as RowData

//     })
//     setRowData(rowData);
//   }, [props.variances])

//    const columns = [
//      { headerName: 'Commodity', field: 'commodityName' },
//      { headerName: 'Budget Volume', field: 'variance.budgetVolume', valueFormatter: formatAsValue },
//      {
//        headerName: 'Current Hedges',
//        children: [
//          { headerName: 'Hedged Volume', field: 'variance.hedgedVolume', valueFormatter: formatAsValue },
//          { headerName: 'Hedged Volume %', field: 'variance.hedgedVolumePercent', valueFormatter: formatAsValue },
//        ],
//      },
//      {
//        headerName: 'With Strategy',
//        children: [
//          { headerName: 'Hedged Volume', field: 'varianceWithStrategy.hedgedVolume', valueFormatter: formatAsValue },
//          { headerName: 'Hedged Volume %', field: 'varianceWithStrategy.hedgedVolumePercent', valueFormatter: formatAsValue },
//        ],
//      },
//    ];

//   return (
//     <div className="ag-theme-alpine" style={{ width: '100%', height: 'auto' }}>
//       <AgGridReact rowData={rowData} columnDefs={columns} defaultColDef={{ flex: 1 }} domLayout="autoHeight" />
//     </div>
//   );
// }

// function ResultDisplay(props: { variances: WhatIfVariance[] }) {
//   return (
//     <>
//       <Chart variances={props.variances} />
//       <CommodityTable variances={props.variances} />
//       <StressTable variances={props.variances} />
//     </>
//   );
// }

// function Report(props: { variances: WhatIfVariance[], onManageStrategies: () => void }) {
//   return (
//     <>
//       <Button onClick={() => props.onManageStrategies()}>Manage Strategies</Button>
//       <ResultDisplay variances={props.variances} />
//     </>
//   );
// }

// export default function WhatIf() {
//   const activeApplicationGuid = useActiveApplication();
//   const [pageState, setPageState] = useState<'report' | 'strategy'>('report');
//   const [whatIfVariances, setWhatIfVariances] = useState<WhatIfVariance[]>([]);
//   const [strategies, setStrategies] = useState<WhatIfStrategyModel[]>([]);
//   const [customerData, setCustomerData] = useState<GetCustomerDataResponse>();

//   useEffect(() => {
//     (async () => {
//       const stressPercents: number[] = [-0.25, -0.2, -0.15, -0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2, 0.25];
//       const mtmDateString = (await CommodityManagementMarketViewApi.getMarketViewLastQuoteDate(null, '2', undefined)).data.quoteDate;
      
//       CommodityManagementApi.getWhatIfReport(null, activeApplicationGuid, mtmDateString!, stressPercents)
//         .then(res => setWhatIfVariances(res.data.rows ?? []))

//       CommodityManagementApi.listWhatIfStrategies(null, activeApplicationGuid)
//         .then(res => setStrategies(res.data.rows ?? []))

//       CommodityManagementApi.getCustomerData(null, activeApplicationGuid)
//         .then(res => setCustomerData(res.data));
      
//     })();
//   }, []);

//   const updateStrategy = (strategy: UpdateWhatIfStrategyRequest): Promise<any> => {
//     var promise = CommodityManagementApi.updateWhatIfStrategy(null, activeApplicationGuid, strategy);

//     promise.then(res => {
//         if (strategy.whatIfStrategyId == 0) {
//           //add to the list
//           setStrategies(previous => [...previous, res.data.whatIfStrategy!])
//         }
//         else {
//           //update exisitng item with new
//           setStrategies(previous => previous.map(strategy => strategy.whatIfStrategyId == strategy.whatIfStrategyId
//             ? res.data.whatIfStrategy!
//             : strategy)
//             )
//         }
//       })

//       return promise;
//   };

//   const deleteStrategy = (strategyId: number): Promise<any> => {
//     var promise = CommodityManagementApi.deleteWhatIfStrategy(null, activeApplicationGuid, strategyId.toString());

//     promise.then(res => setStrategies(previous => previous.filter(q => q.whatIfStrategyId != strategyId)));

//     return promise;
//   }

//   switch (pageState) {
//     case 'report': return <Report variances={whatIfVariances} onManageStrategies={() => setPageState('strategy')}/>
//     case 'strategy': return <MangeStrategies strategies={strategies} customerData={customerData!} onUpdateStrategy={updateStrategy} onDeleteStrategy={deleteStrategy} onClose={() => setPageState('report')}/>
//   }
// }
