import { useOktaAuth } from '@okta/okta-react';
import { ReactElement, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { MarkItViewApi } from '../Apis/Apis';
import './App.scss';
import { AdminSolution, CommodityManagementSolution, Dashboard, DecilesSolution, Header, MarketViewSolution, SidebarNavigation } from '.';
import LoginPage from './LoginPage';
import OktaLoginCallback from '../Core/Okta/OktaLoginCallback';
import OktaSecurity from '../Core/Okta/OktaSecurity';
import store, { useApplicationDispatch } from '../Redux/ReduxStore';
import { setUserAccess } from '../Redux/Slices/UserAccessSlice';
import { StoneXLoading } from './StoneX';
import { useLoadingState } from '../Hooks';

interface StandardLayoutProps {
  contextualHeader?: ReactElement
}

function StandardLayout(props: StandardLayoutProps) {
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useApplicationDispatch();
  const authorizationLoadingState = useLoadingState();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState.isAuthenticated) {
      navigate('/login');
    }

    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((userInfo) => {
        MarkItViewApi.marketViewUserAuthorizationCallback(authorizationLoadingState.setLoadingState).then((res) => {
          dispatch(
            setUserAccess({
              commodityManagementUserSiteAccesses: res.data.user?.commodityManagementUserSiteAccesses,
              marketViewApplications: res.data.user?.marketViewApplications,
              hasAccess: res.data.user?.email == null ? false : true,
              isAdmin: res.data.user?.isAdministrator
            }),
          );
        });
      });
    }
  }, [authState, oktaAuth]);

  return (
    <>
      <Header contextualHeader={props?.contextualHeader} />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <SidebarNavigation />
        <main>
          <StoneXLoading show={!authorizationLoadingState.isLoaded() || authorizationLoadingState.isLoading()} />
          {authorizationLoadingState.isLoaded() && <Outlet />}
        </main>
      </div>
    </>
  );
}

function HomeLayout() {
  return <Outlet />;
}
export default function App() {

  const [contextualHeader, setContextualHeader] = useState<ReactElement>();

  return (
    <Provider store={store}>
      <OktaSecurity>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route element={<HomeLayout />}>
                <Route path="/login" element={<LoginPage />} />
              </Route>
              <Route element={<StandardLayout contextualHeader={contextualHeader} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/commodity-management/*" element={<CommodityManagementSolution setContextualHeader={setContextualHeader} />} />
                <Route path="/market-view" element={<MarketViewSolution setContextualHeader={setContextualHeader}/>} />
                <Route path="/deciles" element={<DecilesSolution setContextualHeader={setContextualHeader}/>} />
                <Route path="/admin/*" element={<AdminSolution setContextualHeader={setContextualHeader} />} />
              </Route>
            </Route>
            <Route path="/callback" element={<OktaLoginCallback />} />
          </Routes>
          {/* <MainLayout /> */}
        </BrowserRouter>
      </OktaSecurity>
    </Provider>
  );
}
