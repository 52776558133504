import { Button,Chip } from '@mui/material';
import { ColDef,FirstDataRenderedEvent,ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React,{ useEffect,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { DealTicketModel, PositionModel } from '../../../../../Generated/Commodity-Management-Api';
import { useActiveAppliction,useLoadingState } from '../../../../../Hooks';
import { StoneXLoading,StoneXRow } from '../../../../StoneX';
import { StoneXModal } from '../../../../StoneXMui';
import StoneXLabeledItem from '../../../../StoneXMui/StoneXLabeledItem/StoneXLabeledItem';

interface PositionValidationModalProps {
  open: boolean;
  dealTicketId?: number;
  onClose: () => void;
  onValidationUpdated: (tradeId: number, validatedPositionIds: number[]) => void;
}

export default function PositionValidationModal(props: PositionValidationModalProps) {
  const { open, dealTicketId, onClose, onValidationUpdated } = props;
  const applicationGuid = useActiveAppliction();
  const gridRef = useRef<AgGridReact<PositionModel>>(null);

  const [trade, setTrade] = useState<DealTicketModel>();
  const tradeLoadingState = useLoadingState();
  const validationSaveLoadingState = useLoadingState();

  useEffect(getDealTicketOnOpen, [open]);

  function getDealTicketOnOpen() {

    console.log('get deal ticket...', open);

    if (!open) {
      setTrade(undefined);
    }

    if (open && dealTicketId) {
      CommodityManagementApi.getDealTicket(tradeLoadingState.setLoadingState, `${dealTicketId}`).then((res) => setTrade(res.data.dealTicket));
    }
  }

  function updateValidation() {
    const selectedRows = gridRef!.current!.api!.getSelectedRows();
    const validatedPositionIds = selectedRows.map((q) => q.positionId) ?? [];

    CommodityManagementApi.updateDealTicketPositionValidation(
      validationSaveLoadingState.setLoadingState,
      trade!.dealTicketId!.toString(),
      validatedPositionIds,
    ).then(() => {
      onValidationUpdated(trade!.dealTicketId!, validatedPositionIds);
      //todo: send success message
    });

    onClose();
  }

  function onDataFirstRendered(params: FirstDataRenderedEvent<PositionModel>) {
    params.api.forEachNode((node) => node.setSelected(!!node.data && node.data.isValidated));
    params.api.sizeColumnsToFit();
  }

  function onRowSelected(event: any) {
    const node = event.node;
    node.data.isValidated = !node.data.isValidated;
    gridRef?.current?.api.refreshCells({ rowNodes: [node]});
  }

  const columnDefs: ColDef[] = [
    { width: 65, headerCheckboxSelection: true, checkboxSelection: true },
    {
      headerName: 'Status',
      field: 'isValidated',
      sortable: true,
      width: 110,
      cellRendererFramework: (props: ICellRendererParams) => (
        <Chip variant={props.node.isSelected() ? 'success' : 'neutral'} label={props.node.isSelected() ? 'VAL' : 'INV'} />
      )
    },
    { headerName: 'Budget Date', field: 'budgetDate.label' },
    { headerName: 'Contract Date', field: 'contractDate.label' },
    { headerName: 'Location', field: 'location.locationName' },
    { headerName: 'Price', field: 'price' },
    { headerName: 'Volume', field: 'volume' },
    { headerName: 'Position Type', field: 'positionTypeOrOption' },
    { headerName: 'Side', field: 'side' },
    { headerName: 'Unit', field: 'unit' },
    { headerName: 'Currency', field: 'currency' },
  ];

  const defaultColDef = {
    resizable: true,
    suppressMenu: true,
  };

  return (
    <StoneXModal header="Set Validation" open={open} onClose={onClose}>
      <StoneXLoading show={tradeLoadingState.isLoading()} />

      {tradeLoadingState.isLoaded() && trade && (
        <>
          <StoneXRow>
            <StoneXLabeledItem width="175px" label="Trade Date">
              {dayjs(trade.tradeDate).format('L')}
          </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Commodity">
              {trade.commodity?.commodityName}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Counterparty">
              {trade.counterparty?.counterpartyName}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Deal Ticket">
              {trade.dealTicketNumber}
            </StoneXLabeledItem>
          </StoneXRow>

          <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              suppressRowClickSelection
              rowData={trade?.positions}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowSelection="multiple"
              onGridReady={onDataFirstRendered}
              onRowSelected={onRowSelected}
            />
          </div>
        </>
      )}

      {validationSaveLoadingState.isLoading() ? 'Saving....' : ''}

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!trade} onClick={updateValidation}>
          Update Validation
        </Button>
      </StoneXRow>
    </StoneXModal>
  );
}
