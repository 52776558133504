import { Add, Close, Description } from '@mui/icons-material';
import { Box, Tab, Tabs, Button, ButtonGroup, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React, { ReactElement, ReactNode, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { MarkItViewApi } from '../../../../Apis/Apis';
import { MarketViewProductTypeModel, MarketViewProductSymbolView, CommodityType, PriceType, Unit, DataProvider } from '../../../../Generated/Mark-It-View-Api';
import { useListResource, useLoadingState, useMtmDate } from '../../../../Hooks';
import { StoneXAutocomplete, StoneXModal, StoneXTextField } from '../../../StoneXMui';
import { StoneXRow } from '../../../StoneX';
import { EnumHelper } from '../../../../Helpers/EnumHelper';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';

export default function Commodities() {
  const gridRef = useRef<AgGridReact<MarketViewProductSymbolView>>(null);
  const [productSymbols, setProductSymbols] = useState<MarketViewProductSymbolView[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalCommodity, setModalCommodity] = useState<MarketViewProductSymbolView | undefined>();
  const saveCommodityLoadingState = useLoadingState();

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    MarkItViewApi.listMarketViewProductSymbols(null).then((res) => setProductSymbols(res.data.rows ?? []));
  }, []);

  const columnDefs = [
    {
      headerName: 'Product',
      field: 'name',
      width: 150,
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Commodity',
      field: 'commodityType',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Type',
      field: 'priceType',
      sortable: true,
      width: 50,
      flex: 1,
    },
    {
      headerName: 'Symbol',
      field: 'symbolCode',
      sortable: true,
      width: 25,
      flex: 1,
    },
    {
      headerName: 'Exchange',
      field: 'exchange',
      sortable: true,
      width: 25,
      flex: 1,
    },
    {
      headerName: 'Unit',
      field: 'unit',
      sortable: true,
      width: 25,
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      cellRenderer: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button
              onClick={() => {
                updateCommodity(params.data);
              }}
            >
              Edit
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];
  function updateCommodity(commodity: MarketViewProductSymbolView) {
    setModalCommodity(commodity);
    setModalOpen(true);
  }

  function saveCommodity(commodityToSave: MarketViewProductSymbolView) {
    if (!commodityToSave.productId) {
      //add
      MarkItViewApi.addMarketViewProductSymbol(saveCommodityLoadingState.setLoadingState, commodityToSave!).then((res) =>
        setProductSymbols((previous) => [...previous, res.data as MarketViewProductSymbolView]),
      );
    } else {
      MarkItViewApi.updateProductSymbol(saveCommodityLoadingState.setLoadingState, commodityToSave.productId!.toString(), commodityToSave!).then((res) => {
        setProductSymbols((previous) =>
          previous.map((commodity) => (commodity.productId == res.data.product?.productId ? (res.data.product as MarketViewProductSymbolView) : commodity)),
        );
      });
    }
  }
  function addCommodity() {
    setModalCommodity(undefined);
    setModalOpen(true);
  }
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
  }, []);

  return (
    <StoneXMainPage>
      <StoneXRow align="end">
        <h3>Commodities</h3>
        <input type="text" id="filter-text-box" placeholder="Filter..." onChange={onFilterTextBoxChanged} />
        <Button className="pull-right" variant="outlined" onClick={addCommodity}>
          Add
        </Button>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params: GetRowIdParams) => params.data.productId}
          enableRangeSelection
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          rowData={productSymbols}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      <UpdateCommodityModal open={modalOpen} onClose={() => setModalOpen(false)} onCommoditySave={saveCommodity} commodity={modalCommodity} />
    </StoneXMainPage>
  );
}

interface UpdateCommodityProps {
  open: boolean;
  onClose: () => void;
  onCommoditySave: (commodity: MarketViewProductSymbolView) => void;
  commodity?: MarketViewProductSymbolView;
}

function UpdateCommodityModal(props: UpdateCommodityProps) {
  const { open, onClose, onCommoditySave, commodity } = props;
  const [productName, setProductName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [productUrl, setProductUrl] = useState<string>();
  const [commodityType, setCommodityType] = useState<CommodityType>();
  const [priceType, setPriceType] = useState<PriceType>();
  const [unit, setUnit] = useState<Unit>();
  const [symbolCode, setSymbolCode] = useState<string>();
  const [exchange, setExchange] = useState<string>();
  const [divider, setDivider] = useState<number>();
  const [dataProviderId, setDataProviderId] = useState<DataProvider>();
  const commodityTypes = useListResource<MarketViewProductTypeModel, string>([], null, (item) => item.commodityType);
  const dataProviders = Object.values(DataProvider); //useListResource<DataProvider, string>([], null, (item) => item);
  const priceTypes = Object.values(PriceType); //useListResource<PriceType, string>([], null, (item) => item);
  const units = Object.values(Unit); // useListResource<PriceType, string>([], null, (item) => item);

  const actionLabel = props.commodity ? 'Update' : 'Add';

  const loadProductTypes = (): Promise<void> => {
    const promise = MarkItViewApi.listMarketViewProductTypes(commodityTypes.setLoadingState).then((response) => {
      if (response.data.rows) {
        commodityTypes.setItems(response.data.rows);
      }
    });

    return promise;
  };

  useEffect(() => {
    setProductName(commodity?.name ?? '');
  }, [commodity]);
  useEffect(() => {
    setDescription(commodity?.description ?? '');
  }, [commodity]);
  useEffect(() => {
    setProductUrl(commodity?.productUrl ?? '');
  }, [commodity]);
  useEffect(() => {
    setSymbolCode(commodity?.symbolCode ?? '');
  }, [commodity]);
  useEffect(() => {
    setCommodityType(commodity?.commodityType);
  }, [commodity]);
  useEffect(() => {
    setPriceType(commodity?.priceType);
  }, [commodity]);
  useEffect(() => {
    setUnit(commodity?.unit);
  }, [commodity]);
  useEffect(() => {
    setExchange(commodity?.exchange ?? '');
  }, [commodity]);
  useEffect(() => {
    setDataProviderId(commodity?.dataProviderId);
  }, [commodity]);

  useEffect(() => {
    loadProductTypes();
  }, []);

  const onCommodityTypeChange = (event: any, value: CommodityType | null) => {
    setCommodityType(value!);
    // commodityTypes.setSelectedItem(value);
  };

  const onPriceTypeChange = (event: any, value: PriceType | null) => {
    setPriceType(value!);
  };
  const onUnitChange = (event: any, value: Unit | null) => {
    setUnit(value!);
  };
  const onDataProviderChange = (event: any, value: DataProvider | null) => {
    setDataProviderId(value!);
  };

  function updateCommodity() {
    //todo: validate

    onCommoditySave({
      productId: commodity?.productId ?? 0,
      name: productName!,
      parentProductId: commodity?.parentProductId,
      symbolId: commodity?.symbolId,
      description: description!,
      productUrl: productUrl!,
      commodityType: commodityType!,
      priceType: priceType!,
      optionStyle: commodity?.optionStyle,
      unit: unit!,
      currency: commodity!.currency,
      symbolCode: symbolCode!,
      exchange: exchange!,
      divider: divider!,
      dataProviderId: dataProviderId!,
    });

    onClose();
  }

  return (
    <StoneXModal header={actionLabel + ' Commodity'} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="Name" value={productName} onChange={(event: any) => setProductName(event.target.value)} />
        <StoneXTextField label="Description" value={description} onChange={(event: any) => setDescription(event.target.value)} />
        <StoneXTextField label="Url" value={productUrl} onChange={(event: any) => setProductUrl(event.target.value)} />
      </StoneXRow>
      <StoneXRow>
        <StoneXAutocomplete
          options={dataProviders.map((q) => q)}
          getOptionLabel={(dataProviderId) => EnumHelper.enumToString(dataProviderId)}
          value={commodity?.dataProviderId}
          onChange={onDataProviderChange}
          label="Data Provider"
        />
        <StoneXTextField label="Exchange" value={exchange} onChange={(event: any) => setExchange(event.target.value)} />
      </StoneXRow>
      <StoneXRow>
        <StoneXTextField label="Symbol" value={symbolCode} onChange={(event: any) => setSymbolCode(event.target.value)} />
        <StoneXTextField label="Divider" value={divider} onChange={(event: any) => setDivider(event.target.value)} />
      </StoneXRow>
      <StoneXRow>
        <StoneXAutocomplete
          options={commodityTypes.items
            .map((q) => q.commodityType)
            .sort((a, b) => {
              return a < b ? -1 : a > b ? 1 : 0;
            })}
          getOptionLabel={(commodityType) => EnumHelper.enumToString(commodityType)}
          value={commodity?.commodityType}
          onChange={onCommodityTypeChange}
          loading={commodityTypes.isLoading}
          label="Commodity Type"
        />
      </StoneXRow>
      <StoneXRow>
        <StoneXAutocomplete
          options={priceTypes.map((q) => q)}
          getOptionLabel={(priceType) => EnumHelper.enumToString(priceType)}
          value={commodity?.priceType}
          onChange={onPriceTypeChange}
          label="Price Type"
        />
      </StoneXRow>
      <StoneXRow>
        <StoneXAutocomplete
          options={units.map((q) => q)}
          getOptionLabel={(unit) => EnumHelper.enumToString(unit)}
          value={commodity?.unit}
          onChange={onUnitChange}
          label="Unit"
        />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={updateCommodity}>{actionLabel + ' Commodity'}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}
