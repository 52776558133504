import { object, number, date, mixed, array, string } from 'yup';
import { PriceQueryType, PriceQueryTypeOptions } from '../Types';


export const markItViewQuerySchema = object({
	startQuoteDate: date()
		.when('priceQueryType', {
			is: PriceQueryType.ForwardCurve,
			then: (schema: any) => schema.notRequired(),
			otherwise: (schema: any) => schema.required("Start Date is required"),
		}),
	endQuoteDate: date()
		.when('priceQueryType', {
			is: PriceQueryType.ForwardCurve,
			then: (schema: any) => schema.notRequired(),
			otherwise: (schema: any) => schema.required("End Date is required"),
		}),
	mtmDate: date()
		.when('priceQueryType', {
			is: PriceQueryType.ForwardCurve,
			then: (schema: any) => schema.required("Mtm Date is required"),
			otherwise: (schema: any) => schema.notRequired(),
		}),
	priceQueryType: mixed()
		.required("Price Query Type is required")
		.oneOf(PriceQueryTypeOptions),
	commodityType: string()
		.required("Commdity Type is required"),
	productId: number()
		.typeError("Product is required")
		.required("Product is required"),
	years: array()
		.of(number())
		.when('priceQueryType', {
			is: (priceQueryType: string): boolean => priceQueryType == PriceQueryType.Price || priceQueryType == PriceQueryType.CalendarStripAverage,
			then: (schema: any) => schema.min(1, "Year is required").required("Year is required"),
			otherwise: (schema) => schema.notRequired()
		}),
	months: array()
		.of(number().min(1).max(12))		
		.when('priceQueryType', { 
			is: (priceQueryType: string): boolean => priceQueryType == PriceQueryType.Price,
			then: (schema: any) => schema.required("Month is required").min(1, "Month is required"),
			otherwise: (schema) => schema.notRequired()
		}),
	startContractDate: mixed()
		.when('priceQueryType', {
			is: (priceQueryType: string): boolean => priceQueryType == PriceQueryType.StripAverage,
			then: (schema: any) => schema.required("Start Contract Date is required"),
			otherwise: (schema) => schema.notRequired()
		}),
	endContractDate: mixed()
		.when('priceQueryType', {
			is: (priceQueryType: string): boolean => priceQueryType == PriceQueryType.StripAverage,
			then: (schema: any) => schema.required("End Contract Date is required"),
			otherwise: (schema) => schema.notRequired()
		}),
});
