import { object, number, date, mixed, array, string, SchemaOf } from 'yup';
import {  Unit, Currency, OptionStyle, OptionType, PositionType, Side } from '../Generated/Commodity-Management-Api';

const PositionSchema = object({
  locationId: number().when('$customerSettings.positionsByLocation', {
    is: true,
    then: number().required("Location is required"),
    otherwise: number().notRequired()
  }),
  budgetDate: object({
    year: number().required("Budget year is required"),
    month: number().required("Budget month is required")
  }).required(),
  contractDate: object({
    year: number().required("Contract year is required"),
    month: number().required("Contract month is required")
  }).required(),
  price: number().required("Price is required"),
  volume: number().required("Volume is required"),
  positionType: mixed().oneOf(Object.values(PositionType)).required("Position type is required"),
  optionStrike: number().when('positionType', {
    is: PositionType.Option,
    then: number().required("Option strike is required"),
    otherwise: number().notRequired()
  }),
  optionType: mixed().oneOf(Object.values(OptionType)).when('positionType', {
    is: PositionType.Option,
    then: mixed().oneOf(Object.values(OptionType)).required("Option type is required"),
    otherwise: mixed().notRequired()
  }),
  optionStyle: mixed().oneOf(Object.values(OptionStyle)).when('positionType', {
    is: PositionType.Option,
    then: mixed().oneOf(Object.values(OptionStyle)).required("Option style is required"),
    otherwise: mixed().notRequired()
  }),
  side: mixed().oneOf(Object.values(Side)).required("Side is required"),
  unit: mixed().oneOf(Object.values(Unit)).required("Unit is required"),
  currency: mixed().oneOf(Object.values(Currency)).required("Currency is required")
});

export const UpdateDealTicketFormSchema = object({
  tradeDate: date().required("Trade date is required"),
  commodityId: number().required("Commodity is required"),
  counterpartyId: number()
    .when('$customerSettings.positionsByCounterparty', {
      is: true,
      then: number().required("Counterparty is required"),
      otherwise: number().optional()
    }),
  portfolioId: number()
    .when('$customerSettings.positionsByPortfolio', {
      is: true,
      then: number().required("Portfolio is required"),
      otherwise: number().optional()
    }),
  dealTicketNumber: string(),
  positions: array().of(PositionSchema).required().min(1, 'At least one position is required')
});