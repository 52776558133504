import { Box,Tab,Tabs } from '@mui/material';
import React,{ useEffect,useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { GetCustomerDataResponse } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXLoading } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import CounterpartySettings from './CounterpartySettings';
import OrganizationSettings from './OrganizationSettings';
import PortfolioSettings from './PortfolioSettings';
import UserManagementAdminSettings from './UserManagementAdminSettings';

export default function SettingsPage() {
  
  const customerDataLoadingState = useLoadingState();
  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});
  const settingOptions = ['counterparty', 'portfolio', 'organization', 'userManagement'] as const;
  const [activeSettings, setActiveSettings] = useState<typeof settingOptions[number]>('counterparty');

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
  }, []);

  function handleChange(event: React.SyntheticEvent, newValue: any) {
    setActiveSettings(newValue);
  }

  return (
    <StoneXMainPage>
      <StoneXLoading show={customerDataLoadingState.isLoading()} />
      {customerDataLoadingState.isLoaded() && (
        <>
          <div style={{ display: 'flex', height: '100%' }}>
            <Box sx={{ width: '400px', flexGrow: 1 }}>
              <Tabs orientation="vertical" variant="scrollable" value={activeSettings} onChange={handleChange} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Tab label={'Counterparties'} value="counterparty" />
                <Tab label={'Portfolios'} value="portfolio" />
                <Tab label={'Organization'} value="organization" />
                <Tab label={'User Management (Admin)'} value="userManagement" />
              </Tabs>
            </Box>
            <div style={{ width: '100%', height: '100%', flexGrow: 1, paddingLeft: '20px' }}>
              <h1>Settings</h1>
              {activeSettings == 'counterparty' && <CounterpartySettings counterparties={customerData?.counterparties!} />}
              {activeSettings == 'portfolio' && <PortfolioSettings portfolios={customerData?.portfolios!} />}
              {activeSettings == 'organization' && <OrganizationSettings tiers={customerData?.tiers!} locations={customerData?.locations!} />}
              {activeSettings == 'userManagement' && <UserManagementAdminSettings />}
            </div>
          </div>
        </>
      )}
    </StoneXMainPage>
  );
}
