import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { sum } from 'lodash';
import React,{ useEffect,useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { GetCustomerDataResponse,GetCustomerSettingsResponse,PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../../Hooks';
import useActiveApplication from '../../../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXDivider,StoneXLoading,StoneXRow } from '../../../../../StoneX';
import { StoneXDatePicker,StoneXNumber } from '../../../../../StoneXMui';
import MarkToMarketFilterAndGrouping from './MarkToMarketFilterAndGrouping';
import MarkToMarketTable from './MarkToMarketTable';
import { FilterAndGroupingResult } from './Types';

export interface MarkToMarketReportProps {
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  quoteDate: Date;
}

export function MarkToMarketReport(props: MarkToMarketReportProps) {

  const { customerData, customerSettings, quoteDate: mtmDate } = props;

  const [quoteDate, setQuoteDate] = useState<Date | null>(mtmDate);
  const [quoteDateString, setQuoteDateString] = useState<string | null>();
  
  const reportLoadingState = useLoadingState();
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [filteredPositions, setFilteredPositions] = useState<PositionModel[]>([]);

  const [grouping, setGrouping] = useState<string[]>([]);
  const [totalMtm, setTotalMtm] = useState<number>();

  const getReport = () => {
    CommodityManagementApi.getMarkToMarketReport(reportLoadingState.setLoadingState, quoteDateString!).then((res) =>
      setPositions(res.data.rows ?? []),
    );
  };

  const onFilterAndGrouping = (result: FilterAndGroupingResult) => {
    setFilteredPositions(result.positions);
    setGrouping(result.grouping);
  };

  useEffect(() => {
    setQuoteDateString(quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null);
  }, [quoteDate]);

  useEffect(() => {
    setTotalMtm(sum(positions.map((q) => q.markToMarketValue)));
  }, [positions]);

  return (
    <div>
      <StoneXRow>
        <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} />
        <Button variant="outlined" onClick={getReport}>
          Go
        </Button>
        {reportLoadingState.isLoaded() && (
          <h2 className="pull-right">
            MTM | {quoteDateString} | {<StoneXNumber number={totalMtm} currency='USD' colored/>}
          </h2>
        )}
      </StoneXRow>

      <StoneXDivider />
      <StoneXLoading show={reportLoadingState.isLoading()} />

      <div style={{ visibility: reportLoadingState.isLoaded() ? 'visible' : 'hidden' }}>
        <MarkToMarketFilterAndGrouping customerData={customerData} customerSettings={customerSettings} positions={positions} onApply={onFilterAndGrouping} />

        <StoneXDivider />

        <MarkToMarketTable customerData={customerData} positions={filteredPositions} grouping={grouping} />
      </div>
    </div>
  );
}
