import React from 'react'

export default function VarianceToBudget() {
  return (
    <div>VarianceToBudget</div>
  )
}


// import { ColDef } from 'ag-grid-community';
// import { AgGridReact } from 'ag-grid-react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import { find,flatMap,map,uniq } from 'lodash';
// import React,{ useEffect,useState } from 'react';
// import { CommodityManagementApi,MarkItViewApi } from '../../../../Apis/Apis';
// import { Variance,VarianceByEntity,VarianceComponent,VarianceEntity } from '../../../../Generated/Commodity-Management-Api';
// import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
// import { StoneXAutocomplete } from '../../../StoneXMui';
// let numeral = require('numeraljs');

// interface RowData {
//   breakdownPath?: string[],
//   month?: string,
//   budgetVolume?: number,
//   components?: VarianceComponent[]
// }

// function VarianceTable(props: { variance: VarianceEntity }) {

//   const [rowData, setRowData] = useState<RowData[]>();
//   const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

//   const isDefined = (value: any): boolean => !(value == null || value == undefined); 
//   const formatAsValue = (params: any) => (isDefined(params.value) ? numeral(params.value).format('(0,0)') : '-');
//   const formatAsPrice = (params: any) => (isDefined(params.value) ? numeral(params.value).format('(0,0.000)') : '-');

//   useEffect(() => {

//     console.log(props);

//     const rows = flatMap(props.variance.subGrouping.entities, (entity) => {
//       const months = map(entity.months, (month) => {
//         return {
//           breakdownPath: [entity.entityName, month.month?.label],
//           month: month.month?.label,
//           ...month
//         } as RowData;
//       });

//       if (entity.total){
//         console.log(entity.total, varianceToRowData(entity.total));
//         months.push({
//           breakdownPath: [entity.entityName!],
//           ...entity.total as RowData,
//         });
//       }

//       return months;
//     });


//     function varianceToRowData(variance: Variance): RowData{
      
//       return {...variance} as RowData;
//       return {
//         budgetVolume: variance.budgetVolume,
//         blendedPrice: variance.blendedPrice,
//         budgetPrice: variance.budgetPrice,
//         vtmValue: variance.vtmValue,
//         vtmPrice: variance.vtmPrice,
//         vtbValue: variance.vtbValue,
//         components: variance.components,
//       } as RowData;
//     }

//     const uniqueComponents = uniq(flatMap(rows, (row) => map(row.components, (component) => component.componentType)));

//     const componentColumnDefs = uniqueComponents.flatMap((component, index) => {

//       return {
//         headerName: component,
//         children: [
//           {
//             headerName: 'Budget Price',
//             valueGetter: (params: any) => find(params.data?.components ?? [], (r) => r.componentType == component)?.budgetPrice,
//             valueFormatter: formatAsPrice,
//           },
//           {
//             headerName: 'Hedge Volume',
//             valueGetter: (params: any) => find(params.data?.components ?? [], (r) => r.componentType == component)?.hedgeVolume,
//             valueFormatter: formatAsValue,
//           },
//           {
//             headerName: 'Hedge Price',
//             valueGetter: (params: any) => find(params.data?.components ?? [], (r) => r.componentType == component)?.hedgePrice,
//             valueFormatter: formatAsPrice,
//           },
//           {
//             headerName: 'Open Volume',
//             valueGetter: (params: any) => find(params.data?.components ?? [], (r) => r.componentType == component)?.netOpenVolume,
//             valueFormatter: formatAsValue,
//           },
//           {
//             headerName: 'Market Price',
//             valueGetter: (params: any) => find(params.data?.components ?? [], (r) => r.componentType == component)?.marketPrice,
//             valueFormatter: formatAsPrice,
//           },
//         ],
//       };
//     })

//     const columns: ColDef[] = [
//       { headerName: 'Budget Volume', field: 'budgetVolume', valueFormatter: formatAsValue },
//       ...componentColumnDefs,
//       { headerName: 'Blended Price', field: 'blendedPrice', valueFormatter: formatAsPrice },
//       { headerName: 'Budget Price', field: 'budgetPrice', valueFormatter: formatAsPrice },
//       { headerName: 'VTM Value', field: 'vtmValue', valueFormatter: formatAsValue },
//       { headerName: 'VTB Value', field: 'vtbValue', valueFormatter: formatAsValue },
//     ];

//     setColumnDefs(columns);
//     setRowData(rows);
//   }, []);

//   return (
//     <>
//       <div className="ag-theme-alpine" style={{ width: '100%', height: 'auto' }}>
//         <AgGridReact
//           rowData={rowData}
//           columnDefs={columnDefs}
//           defaultColDef={{ flex: 1 }}
//           autoGroupColumnDef={{
//             headerName: props.variance.breakdown.entityType,
//             cellRendererParams: {
//               suppressCount: true,
//             },
//           }}
//           treeData={true}
//           // animateRows={true}
//           // groupDefaultExpanded={-1}
//           domLayout="autoHeight"
//           getDataPath={(data: any) => data.breakdownPath}
//         ></AgGridReact>
//       </div>
//     </>
//   );
// }

// function VarianceChart(props: { variance: VarianceEntity }) {
//   const data = props.variance.months;

//   const chartOptions = {
//     chart: {
//       type: 'column',
//     },
//     title: {
//       text: 'Monthly Values',
//     },
//     xAxis: {
//       categories: data.map((item) => item.month?.label),
//       title: {
//         text: 'Months',
//       },
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: 'Values',
//         align: 'high',
//       },
//       labels: {
//         overflow: 'justify',
//       },
//     },
//     tooltip: {
//       valueSuffix: ' units',
//     },
//     plotOptions: {
//       bar: {
//         dataLabels: {
//           enabled: true,
//         },
//       },
//     },
//     series: [
//       {
//         name: 'Budget Value',
//         data: data.map((month) => month.budgetValue),
//       },
//       {
//         name: 'Market Value',
//         data: data.map((month) => month.marketValue),
//       },
//       {
//         name: 'Blended Value',
//         data: data.map((month) => month.blendedValue),
//       },
//     ],
//   };

//   return (
//     <div>
//       <HighchartsReact highcharts={Highcharts} options={chartOptions} />
//     </div>
//   );
// }

// export default function VarianceToBudget() {
//   const activeApplicationGuid = useActiveApplication();

//   const [varianceReport, setVarianceReport] = useState<VarianceByEntity>();

//   const [selectedBreakdown, setSelectedBreakdown] = useState<VarianceEntity | null | undefined>(null);

//   useEffect(() => {
//     (async () => {
//       const mtmDateString = (await MarkItViewApi.getMarketViewLastQuoteDate(null, '2', undefined)).data.quoteDate;
//       const report = (await CommodityManagementApi.getVarianceToBudgetReport(null, activeApplicationGuid, mtmDateString!)).data.report;
//       setVarianceReport(report);
//       setSelectedBreakdown(report?.entities[0]);
//     })();
//   }, []);


//   return (
//     <>
//       <StoneXAutocomplete
//         options={varianceReport?.entities ?? []}
//         getOptionLabel={(q) => q.entityName ?? ''}
//         value={selectedBreakdown}
//         onChange={(e: any, variance: VarianceEntity | null) => setSelectedBreakdown(variance)}
//       />

//       {selectedBreakdown?.breakdown.entities.map((q) => {
//         return (
//           <div key={q.entityName}>
//             {q.entityName}
//             <VarianceTable variance={q} />
//             <VarianceChart variance={q} />
//             <br />
//           </div>
//         );
//       })}
//     </>
//   );
// }
