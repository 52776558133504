// import { Cookies } from 'react-cookie';
import { CommodityManagementApi,MarketViewApi as CommodityManagementMarketViewApi } from '../Generated/Commodity-Management-Api';
import { MarketViewApi } from '../Generated/Mark-It-View-Api';
import store from '../Redux/ReduxStore';
import applicationInitializationService from '../Services/ApplicationInitializationService';

applicationInitializationService.registerAll(false, store);

const markItViewApi = new MarketViewApi();
const commodityManagementApi = new CommodityManagementApi();
const commodityManagementMarketViewApi = new CommodityManagementMarketViewApi();

export {
  markItViewApi as MarkItViewApi,
  commodityManagementApi as CommodityManagementApi,
  commodityManagementMarketViewApi as CommodityManagementMarketViewApi,
};