import { AccountCircle } from '@mui/icons-material';
import { Box,Button,Popover } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../Assets/KnowRisk_Inline_Dark_RGB.svg';
import './Header.css';

interface HeaderProps {
  contextualHeader?: ReactElement;
}

export default function Header(props: HeaderProps) {
  return (
    <header>
      <NavLink to="/">
        <LogoContainer />
      </NavLink>
      <div style={{  flexGrow: 1 }}>{props.contextualHeader}</div>
      <UserAccountIcon />
    </header>
  );
}

function LogoContainer() {
  return <img className='logo' src={Logo}></img>;
}

function UserAccountIcon() {
  const { authState, oktaAuth } = useOktaAuth();

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  // ToDo: Get this info from redux
  // oktaAuth.getUser().then((q) => {
  //   setName(q!.name!);
  //   setEmailAddress(q!.email!);
  // });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const logOut = async () => {
    oktaAuth.signOut();
  };

  return (
  <>
      <div onClick={handlePopoverOpen}>
        <AccountCircle />
      </div>
      <Popover
        id="user-account-popoever"
        // sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        // transformOrigin={{
        //   vertical: 'center',
        //   horizontal: 'right',
        // }}
        onClose={handlePopoverClose}
        // disableRestoreFocus
      >
        <Box sx={{ width: '250px' }}>
          {name}
          <br />
          {emailAddress}
          <Button onClick={logOut}>Logout</Button>
        </Box>
      </Popover>
    </>
  );
}
