import { object, number, date, mixed, array, string, SchemaOf } from 'yup';
import { ContractDate, Unit } from '../Generated/Mark-It-View-Api';
import { Currency } from '../Generated/Commodity-Management-Api';

export const BudgetFormSchema = object({
  budgetId: number().optional(),
  commodityId: number().required(),
  locationId: number().optional(),
  counterpartyId: number().optional(),
  pricingStrategyId: number().required(),
  volume: number().required().min(0),
  volumeUnit: mixed<Unit>().required(),
  currency: mixed<Currency>().required(),
  budgetMonth: object().shape({
    year: number().required().min(0),
    month: number().required().min(1).max(12)
  }),
  contractMonth: object().shape({
    year: number().required().min(0),
    month: number().required().min(1).max(12)
  }),
  contractDate: object().test('is-valid-contract-month', 'Invalid Contract Month', function (value: ContractDate | null | undefined) {

    if (!value || !value.year || !value.month) {
      return this.createError({
        message: "Invalid Contract Month",
        path: 'contratMonth'
      })
    }

    return true;
  }),
  budgetPrices: array().of(
    object().shape({
      budgetPriceId: number().optional(),
      variable: string().required(),
      price: number().required(),
      unit: mixed<Unit>().required(),
    })
  ).min(1)
});