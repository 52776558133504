import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ICellRendererParams,ProcessCellForExportParams,SuppressKeyboardEventParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React,{ useEffect,useMemo,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { CommodityModel,ContractDate,CounterpartyModel,Currency,GetCustomerDataResponse,LocationModel,OptionStyle,OptionType,PortfolioModel,PositionModel,PositionType,Side,Unit,UpdatePositionModel,UpdateDealTicketRequest } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXDivider,StoneXRow } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXAutocomplete,StoneXContractMonthDropdown,StoneXDatePicker,StoneXSelect,StoneXTextField } from '../../../StoneXMui';

// interface PositionRow {
//   positionId: number | null;
//   locationId?: number | null;
//   budgetMonth?: ContractDate | null;
//   contractMonth?: ContractDate | null;
//   price?: number | null;
//   volume?: number | null;
//   positionType?: PositionType | null;
//   optionStrike?: number | null;
//   optionType?: OptionType | null;
//   optionStyle?: OptionStyle | null;
//   side?: Side | null;
//   unit?: Unit | null;
//   currency?: Currency | null;
// }

interface PositionRow extends Partial<PositionModel> { };

export default function UpdatePositions() {
  const activeApplicationGuid = useActiveApplication();
  const gridRef = useRef<AgGridReact<PositionRow>>(null);

  const customerDataLoadingState = useLoadingState();
  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});

  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>();
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>();
  const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioModel | null>();
  const [dealTicket, setDealTicket] = useState<string>('');

  const [positions, setPositions] = useState<PositionRow[]>([]);

  const updatePosition = <K extends keyof PositionRow, V extends PositionRow[K]>(index: number, key: K, value: V): void => {
    setPositions((previous) =>
      previous.map((row, i) => {
        if (i == index) {
          return { ...row, [key]: value };
        }

        return row;
      }),
    );
  };

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
  }, []);

  const [tradeDate, setTradeDate] = useState<Date | null>(dayjs().toDate());
  const onTradeDateChange = (value: Date | null): void => setTradeDate(value);

  const addNewPosition = (): void => {
    const newPosition = {} as PositionRow;
    setPositions((previous) => [...previous, newPosition]);
  };

  const removePosition = (index: number): void => {
    setPositions((previous) => previous.filter((q, i) => i !== index));
  };

  function getPositionsFromAgGrid() {
    const gridApi = gridRef!.current!.api;
    const positions: PositionRow[] = [];

    gridApi.forEachNode((node: any) => {
      positions.push(node.data);
    });

    return positions;
  }

  function updatePositions() {
    const positions = getPositionsFromAgGrid();
    console.log(positions);

    const trade = {
      tradeDate: dayjs(tradeDate).format(),
      commodityId: selectedCommodity?.commodityId,
      counterpartyId: selectedCounterparty?.counterpartyId,
      portfolioId: selectedPortfolio?.portfolioId,
      positions: positions.map((q) => {
        return {
          positionId: q.positionId,
          locationId: q.location?.locationId,
          budgetMonth: q.budgetDate,
          contractMonth: q.contractDate,
          price: q.price,
          volume: q.volume,
          positionType: q.positionType,
          optionType: q.optionType,
          optionStrike: q.optionStrike,
          optionStyle: q.optionStyle,
          side: q.side,
          unit: q.unit,
          currency: q.currency,
        } as UpdatePositionModel;
      }),
    } as UpdateDealTicketRequest;

    CommodityManagementApi.addTrade(null, trade);
  }

  function processCellForClipboard(params: ProcessCellForExportParams) {
    const colId = params.column.getColId();

    console.log('colId', colId);

    if (colId === 'location') {
      return params.value?.locationName;
    }

    if (colId === 'budgetMonth' || colId === 'contractMonth') {
      const value = params.value;
      if (!value.year && !value.month) return '';
      return dayjs(new Date(value.year, value.month, 1)).format('L');
    }

    return params.value;
  }

  function processCellFromClipboard(params: ProcessCellForExportParams) {
    const colId = params.column.getColId();

    if (colId === 'location') {
      const locationName = params.value;
      const location = customerData.locations?.find((q) => q.locationName === locationName);
      return location;
    }

    if (colId === 'budgetMonth' || colId === 'contractMonth') {
      const day = dayjs(params.value);
      console.log(params.value, day, { year: day.year(), month: day.month() });
      return { year: day.year(), month: day.month() };
    }

    return params.value;
  }

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Side',
        field: 'side',
        editable: true,
        maxWidth: 90,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={['Buy', 'Sell']}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(side: Side | null) => params.setValue!(side)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Position Type',
        field: 'positionType',
        editable: true,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.positionTypes ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(positionType: PositionType | null) => params.setValue!(positionType)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Location',
        field: 'location',
        editable: true,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.locations ?? []}
            getId={(q) => q.locationId}
            getOptionLabel={(q) => q.locationName}
            value={params.value}
            onChange={(location: LocationModel | null) => params.setValue!(location)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Budget Month',
        field: 'budgetMonth',
        editable: true,
        maxWidth: 200,
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXContractMonthDropdown value={props.value} onChange={(date: ContractDate | null) => props.setValue!(date)} fullWidth />
        ),
      },
      {
        headerName: 'Contract Month',
        editable: true,
        field: 'contractMonth',
        maxWidth: 200,
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXContractMonthDropdown value={props.value ?? null} onChange={(date: ContractDate | null) => props.setValue!(date)} fullWidth />
        ),
      },

      {
        headerName: 'Option Type',
        editable: true,
        field: 'optionType',
        maxWidth: 90,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.optionTypes ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(optionType: OptionType | null) => params.setValue!(optionType)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Option Style',
        editable: true,
        field: 'optionStyle',
        maxWidth: 120,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.optionStyles ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(optionStyle: OptionStyle | null) => params.setValue!(optionStyle)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Option Strike',
        editable: true,
        field: 'contractMonth',
        maxWidth: 120,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXTextField type="number" fullWidth value={params.value} onChange={(e: any) => params.setValue!(e?.target.value)} min={0} />
        ),
      },
      {
        headerName: 'Quantity',
        editable: true,
        field: 'quantity',
        maxWidth: 120,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXTextField type="number" fullWidth value={params.value} onChange={(e: any) => params.setValue!(e?.target.value)} />
        ),
      },
      {
        headerName: 'Price',
        editable: true,
        field: 'price',
        maxWidth: 120,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXTextField type="number" fullWidth value={params.value} onChange={(e: any) => params.setValue!(e?.target.value)} />
        ),
      },
      {
        headerName: 'Unit',
        editable: true,
        field: 'unit',
        maxWidth: 110,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.units ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(unit: Unit | null) => params.setValue!(unit)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Currency',
        editable: true,
        field: 'currency',
        maxWidth: 110,
        cellRendererFramework: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.currencies ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.value}
            onChange={(currency: Currency | null) => params.setValue!(currency)}
            fullWidth
          />
        ),
      },
    ];
  }, [customerData]);

  const isAllowedKey = (event: KeyboardEvent): boolean => {
    const allowedKeys = ['Tab', 'Enter', 'Shift', 'Control', 'Alt', 'Escape', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete', 'c', 'v'];

    return allowedKeys.includes(event.key);
  };

  return (
    <StoneXMainPage>
      <StoneXRow>
        <StoneXDatePicker value={tradeDate} onChange={onTradeDateChange} label="Trade Date" />

        <StoneXAutocomplete
          options={customerData.commodities ?? []}
          getOptionLabel={(q) => q.commodityName}
          value={selectedCommodity}
          onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
          label="Commodity"
        />

        <StoneXAutocomplete
          options={customerData.counterparties ?? []}
          getOptionLabel={(q) => q.counterpartyName!}
          value={selectedCounterparty}
          onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
          label="Counterparty "
        />

        <StoneXAutocomplete
          options={customerData.portfolios ?? []}
          getOptionLabel={(q) => q.portfolioName!}
          value={selectedPortfolio}
          onChange={(e: any, portfolio: PortfolioModel | null) => setSelectedPortfolio(portfolio)}
          label="Portfolio "
        />

        <StoneXTextField type="text" label="Deal Ticket" value={dealTicket} min={0} onChange={(event) => setDealTicket(event.target.value)} />
      </StoneXRow>

      <StoneXDivider />

      <StoneXRow>
        <div className="pull-right nested-row-item">
          <Button variant="outlined">Cancel</Button>
          <Button onClick={updatePositions}>Save</Button>
        </div>
      </StoneXRow>

      <div className="ag-theme-alpine ag-with-inputs" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          processCellForClipboard={processCellForClipboard}
          processCellFromClipboard={processCellFromClipboard}
          suppressRowHoverHighlight
          suppressClickEdit
          // suppressLastEmptyLineOnPaste
          enableRangeSelection
          enterMovesDown
          enterMovesDownAfterEdit
          rowHeight={56}
          rowData={positions}
          domLayout="autoHeight"
          onCellKeyDown={(cellEvent: any) => {
            const input = cellEvent.event?.target.querySelector('input');
            if (input) {
              input.focus();
              // const event = new KeyboardEvent('keydown', cellEvent.event);
              // input.dispatchEvent(event);
            }
          }}
          defaultColDef={{
            flex: 1,
            suppressMenu: true,
            suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => !isAllowedKey(params.event),
          }}
          columnDefs={columnDefs}
        />
      </div>

      {JSON.stringify(positions)}

      <br></br>
      <Button variant="text" onClick={addNewPosition} startIcon={<AddCircle />}>
        Add position
      </Button>
    </StoneXMainPage>
  );
}
