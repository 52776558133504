import { Box,Card,CardHeader } from '@mui/material';
import { useEffect,useState } from 'react';
import { MarkItViewQuery } from '../../../../Types/MarkItViewQueryResult';
import { MarkItViewSavedQuerySet } from '../../../../Types/MarkItViewSavedQuerySet';
import QueryDisplay from './QueryDisplay/QueryDisplay';
import QueryFormList from './QueryFormList/QueryFormList';
import { StoneXDivider } from '../../../StoneX';

interface QueryPageProps {
  id: number;
  onTitleChange?: (id: number, title: string) => void;
  querySet?: MarkItViewSavedQuerySet;
  save: (querySetToSave: MarkItViewSavedQuerySet) => Promise<void>;
}

export function QueryPage(props: QueryPageProps) {
  const [queries, setQueries] = useState<MarkItViewQuery[]>([]);
  const [querySetsParams, setQuerySetsParams] = useState<MarkItViewSavedQuerySet>();

  useEffect(() => {
    if (props.onTitleChange && queries.length > 0) {
      props.onTitleChange(props.id, queries[0].product.name);
    }
  }, [queries]);

  return (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <QueryFormList onQueriesUpdate={setQueries} onQueryParamsUpdate={setQuerySetsParams} querySet={props.querySet} saveQuerySet={props.save} />
        </Box>

      <StoneXDivider />

      {queries.length > 0 && <QueryDisplay queries={queries} />}
    </>
  );
}
