import { Checkbox,FormControl,FormControlLabel,FormGroup,FormHelperText } from '@mui/material';
import { useCallback,useEffect,useState } from 'react';
import { MonthDictionary } from '../../../Types';
import { MonthDictionaryHelper } from '../../../Types/MonthDictionary';

interface PropsType {
  allowedMonths: number[] | null;
  resultSetter: (value: number[]) => void;
  error?: string | undefined;
  initial?: number[]
}

export function StoneXMonthMultiselect(props: PropsType) {
  const [allowedMonths, setAllowedMonths] = useState<MonthDictionary>(MonthDictionaryHelper.empty());
  const [selectedMonths, setSelectedMonths] = useState<MonthDictionary>(MonthDictionaryHelper.empty());
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (props.allowedMonths) {
      var allowedMonthDict = MonthDictionaryHelper.fromArray(props.allowedMonths);
      setAllowedMonths(allowedMonthDict);
      setIsLoaded(true);
    } else {
      setAllowedMonths(MonthDictionaryHelper.empty());
      setIsLoaded(false);
    }
  }, [props.allowedMonths]);

  useEffect(() => {
    // if (props.value) return;
    var monthArray = MonthDictionaryHelper.toArray(selectedMonths);
    props.resultSetter(monthArray);
  }, [selectedMonths]);

  useEffect(() => {
    if (props.initial) {
      setSelectedMonths(MonthDictionaryHelper.fromArray(props.initial));
    }
  }, [])

  // useEffect(() => {
  //   if (props.value) {
  //     setSelectedMonths(MonthDictionaryHelper.fromArray(props.value))
  //   }
  // }, [props.value])

  const onMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const month = parseInt(event.target.name);
    const isChecked = event.target.checked;

    var newSelectedMonths = { ...selectedMonths };
    newSelectedMonths[month as keyof MonthDictionary] = isChecked;
    setSelectedMonths(newSelectedMonths);
  };

  const onMonthCheckAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        setSelectedMonths(allowedMonths);
      } else {
        setSelectedMonths(MonthDictionaryHelper.empty());
      }
    },
    [allowedMonths],
  );

  return (
    <FormControl error={!!props.error}>
      <FormGroup sx={{ display: 'grid', alignContent: 'center', gridTemplateColumns: '100px 100px 100px 100px', marginLeft: '8px' }}>
        <FormControlLabel
          sx={{ gridColumnStart: 'span 4' }}
          disabled={!isLoaded}
          control={<Checkbox name="all" onChange={onMonthCheckAllChange} />}
          label="Check All"
        />
        <FormControlLabel control={<Checkbox name="1" onChange={onMonthChange} disabled={!allowedMonths[1]} checked={selectedMonths[1]} />} label="Jan" />
        <FormControlLabel control={<Checkbox name="2" onChange={onMonthChange} disabled={!allowedMonths[2]} checked={selectedMonths[2]} />} label="Feb" />
        <FormControlLabel control={<Checkbox name="3" onChange={onMonthChange} disabled={!allowedMonths[3]} checked={selectedMonths[3]} />} label="Mar" />
        <FormControlLabel control={<Checkbox name="4" onChange={onMonthChange} disabled={!allowedMonths[4]} checked={selectedMonths[4]} />} label="Apr" />
        <FormControlLabel control={<Checkbox name="5" onChange={onMonthChange} disabled={!allowedMonths[5]} checked={selectedMonths[5]} />} label="May" />
        <FormControlLabel control={<Checkbox name="6" onChange={onMonthChange} disabled={!allowedMonths[6]} checked={selectedMonths[6]} />} label="Jun" />
        <FormControlLabel control={<Checkbox name="7" onChange={onMonthChange} disabled={!allowedMonths[7]} checked={selectedMonths[7]} />} label="Jul" />
        <FormControlLabel control={<Checkbox name="8" onChange={onMonthChange} disabled={!allowedMonths[8]} checked={selectedMonths[8]} />} label="Aug" />
        <FormControlLabel control={<Checkbox name="9" onChange={onMonthChange} disabled={!allowedMonths[9]} checked={selectedMonths[9]} />} label="Sep" />
        <FormControlLabel control={<Checkbox name="10" onChange={onMonthChange} disabled={!allowedMonths[10]} checked={selectedMonths[10]} />} label="Oct" />
        <FormControlLabel control={<Checkbox name="11" onChange={onMonthChange} disabled={!allowedMonths[11]} checked={selectedMonths[11]} />} label="Nov" />
        <FormControlLabel control={<Checkbox name="12" onChange={onMonthChange} disabled={!allowedMonths[12]} checked={selectedMonths[12]} />} label="Dec" />

      </FormGroup>
        {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
}
