import { useEffect,useState } from "react";
import { number } from "yup";

export default function useField(ref: React.RefObject<HTMLDivElement>) {

	const [dimensions, setDimensions] = useState<{x: number | undefined, y: number | undefined}>({x: undefined, y: undefined});
	const resizeObserver = new ResizeObserver(updateDimensions);

	function updateDimensions() {
		const box = ref.current?.getBoundingClientRect();
		if (box) {
			const box = ref.current?.getBoundingClientRect();
			setDimensions({x: box!.width, y: box!.height});
		} else {
			setDimensions({x: undefined, y: undefined});
		}
	};

  useEffect(() => {
	updateDimensions();

	if (ref.current) {
		resizeObserver.observe(ref.current);
	}

    // ref.current?.addEventListener('click', updateDimensions);

    // clean up function
    return () => {
		if (ref.current) {  
			resizeObserver.unobserve(ref.current);
		}
      // remove resize listener
    //   ref.current?.removeEventListener('click', updateDimensions);
    };
  }, []);

  return dimensions;
}