/* eslint-disable class-methods-use-this */
import { Store } from '@reduxjs/toolkit';
// import { LicenseManager } from 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import axios, { AxiosRequestConfig } from 'axios';
import LoggingService from '../Core/Logging/LoggingService';
import store from '../Redux/ReduxStore';

// Service that will initialize the application with base settings to be able to bootstrap and start it.
class ApplicationInitializationService {
    private registerApiInterceptorsCalled = false;

    private stores: { [key: string]: Store } = { default: store };

    getStore = (storeId?: string): Store => this.stores[storeId ?? 'default'];

    registerAll = (isMono: boolean, newStore: Store, storeId?: string): void => {
        // applicationSettings.isMono = isMono;

        this.registerReduxStore(newStore, storeId);
        this.registerAgGridLicense();
        this.registerApiInterceptors();
    };

    registerReduxStore = (newStore: Store, storeId?: string): void => {
        this.stores[storeId ?? 'default'] = newStore;

        // Always set the default store to the first one that gets registered.
        if (!this.stores.default || this.stores.default === store) {
            this.stores.default = newStore;
        }
    };

    registerAgGridLicense = (): void => {
        LicenseManager.setLicenseKey(
            // eslint-disable-next-line max-len
            'CompanyName=StoneX Inc,LicensedGroup=technology-solutions-dev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-013567,ExpiryDate=17_March_2022_[v2]_MTY0NzQ3NTIwMDAwMA==f8d4d5abd17498af0a02ed580cac8549',
        );
    };

    registerApiInterceptors = (): void => {
        if (this.registerApiInterceptorsCalled) {
            return;
        }

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        // axios.defaults.headers.common['Raven-Environment'] = applicationSettings.ravenEnvironment;
        // axios.defaults.headers.common['Rift-Environment'] = applicationSettings.ravenEnvironment;

        axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
            // const accessToken = this.stores.default?.getState().user?.accessToken;
            const oktaString = localStorage.getItem('okta-token-storage');
            const accessToken = oktaString ? JSON.parse(oktaString)?.accessToken?.accessToken : null;

            if (accessToken) {
                config!.headers!.Authorization = `Bearer ${accessToken}`;
            }

            const siteGuid = this.getStore().getState().activeSite.site?.siteGuid;

            if (siteGuid) {
                config!.headers!['X-Site-Guid'] = siteGuid;
            }

            return config;
        });

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                LoggingService.trackException(error, `HTTP Error Response (${error.response?.status})`);

                return Promise.reject(error);
            },
        );

        this.registerApiInterceptorsCalled = true;
    };
}

// Intercept all ajax calls because we need to re-write the lightstreamer endpoints.
// We cannot modify the lightstreamer code to have separate HTTP and Websockets prefixes.
// Therefore we have to use interception to make this work. This allows it to work with
// the storefront.
// DO NOT MAKE THIS AN ARROW FUNCTION!
// if (applicationSettings.environment === 'local' || applicationSettings.environment === 'production') {
//     const originalOpen = XMLHttpRequest.prototype.open;
//     XMLHttpRequest.prototype.open = function open(method: string, url: any, isAsync?: boolean, username?: string, password?: string) {
//         let newUrl = url;
//         if (url.indexOf('/lightstreamer/') > 0) {
//             newUrl = url.replace('/lightstreamer/', '/lightstreamer-session/');
//         }

//         return originalOpen.call(this, method, newUrl, isAsync ?? false, username, password);
//     };
// }

const applicationInitializationService = new ApplicationInitializationService();

export default applicationInitializationService;
