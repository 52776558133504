import { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { CommodityManagementSiteAccessModel } from '../../../Generated/Commodity-Management-Api';
import { RootState, useApplicationSelector } from '../../../Redux/ReduxStore';
import UpdateBudgetsPage from './Budgets/UpdateBudgetsPage/UpdateBudgetsPage';
import ViewBudgetsPage from './Budgets/ViewBudgetsPage/ViewBudgetsPage';
import CommodityManagementSiteSelector from './CommodityManagementSiteSelector';
import CommodityManagementNavigation from './Navigation/CommodityManagementNavigation';
import UpdatePositions from './Positions/UpdatePositions';
import ViewPositionsPage from './Positions/ViewPositionsPage/ViewPositionsPage';
import MarkToMarket from './Reports/Reports/MarkToMarket/MarkToMarket';
import ReportsPage from './Reports/ReportsPage';
import VarianceToBudget from './Reports/VarianceToBudget';
import WhatIf from './Reports/WhatIf';
import SettingsPage from './Settings/SettingsPage';

interface CommodityManagementSolutionProps {
  setContextualHeader: (header: ReactElement) => void;
}

function CommodityManagementContextualHeader() {
  return (
    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'space-between' }}>
      <CommodityManagementNavigation />
      <CommodityManagementSiteSelector />
    </div>
  );
}

export default function CommodityManagementSolution(props: CommodityManagementSolutionProps) {
  const activeSite = useApplicationSelector((state: RootState): CommodityManagementSiteAccessModel => state.activeSite.site);

  useEffect(() => {
    props.setContextualHeader(<CommodityManagementContextualHeader />);

    // Clear the header if this solution is dismounted
    return () => {
      props.setContextualHeader(<></>);
    };
  }, []);

  if (activeSite) {
    return (
      // Setting the site's guid as the key will force the component to reload when the site changes
      <div style={{ width: '100%', height: '100%' }} key={activeSite.siteGuid}>
        <Routes>
          <Route path="settings" element={<SettingsPage />} />
          <Route path="budgets" element={<ViewBudgetsPage />} />
          <Route path="budgets/update" element={<UpdateBudgetsPage />} />
          <Route path="positions" element={<ViewPositionsPage />} />
          <Route path="positions/update" element={<UpdatePositions />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="reports/mark-to-market" element={<MarkToMarket />} />
          <Route path="reports/variance-to-budget" element={<VarianceToBudget />} />
          <Route path="reports/what-if" element={<WhatIf />} />
        </Routes>
      </div>
    );
  }
  else {
    return <h3>Please select a site to begin</h3>;
  }
}
