import 'ag-grid-enterprise';
import { ICellRendererParams,ProcessCellForExportParams,SuppressKeyboardEventParams,ValueGetterParams,ValueSetterParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React,{ useEffect,useMemo,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
ContractDate,GetCustomerDataResponse,
GetCustomerSettingsResponse,
PricingStrategyModel,
UpdateBudgetModel
} from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import { BudgetFormSchema } from '../../../../../Validations';
import { StoneXRow } from '../../../../StoneX';
import { StoneXDivider } from '../../../../StoneX/StoneXDivider';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXContractMonthDropdown,StoneXTextField } from '../../../../StoneXMui';
import BudgetSelectionForm from './BudgetSelectionForm';
import { BudgetRow } from './BudgetTypes';

export default function UpdateBudgetsPage() {
  const gridRef = useRef<AgGridReact<BudgetRow>>(null);

  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>();
  const [pricingStrategies, setPricingStrategies] = useState<PricingStrategyModel[] | null>();
  const [budgets, setBudgets] = useState<BudgetRow[]>([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [formErrorsHashmap, setFormErrorsHashmap] = useState<{ [key: string]: string }[]>([]);

  //init data on load
  const customerDataLoadingState = useLoadingState();
  const customerSettingsLoadingState = useLoadingState();
  const pricingStrategyLoadingState = useLoadingState();
  const budgetSaveLoadingState = useLoadingState();

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
    CommodityManagementApi.getCustomerSettings(customerSettingsLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
    CommodityManagementApi.listPricingStrategies(pricingStrategyLoadingState.setLoadingState).then((q) => setPricingStrategies(q.data.rows));
  }, []);

  function getBudgetsFromAgGrid() {
    const gridApi = gridRef!.current!.api;
    const budgets: BudgetRow[] = [];

    gridApi.forEachNode((node: any) => {
      budgets.push(node.data);
    });

    return budgets;
  }

  function validateBudgets(budgets: BudgetRow[]): boolean {
    const errors: { [key: string]: string }[] = [];

    budgets.forEach((row, index) => {
      try {
        BudgetFormSchema.validateSync(row, { abortEarly: false });
      } catch (err: any) {
        err.inner.forEach((e: any) => {
          if (!errors[index]) {
            errors[index] = {};
          }

          errors[index][e.path] = e.message;
        });
      }
    });

    setFormErrorsHashmap(errors);

    return !!errors;
  }

  const saveBudgets = (): void => {
    const budgets = getBudgetsFromAgGrid();
    const budgetsAreValid = validateBudgets(budgets);
    console.log('budgetsAreValid', budgetsAreValid);

    var updateBudgetsModel = {
      budgets: budgets as UpdateBudgetModel[]
    } ;

    CommodityManagementApi.updateBudgets(budgetSaveLoadingState.setLoadingState, updateBudgetsModel);
  };

  const isAllowedKey = (event: KeyboardEvent): boolean => {
    const allowedKeys = ['Tab', 'Enter', 'Shift', 'Control', 'Alt', 'Escape', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete', "c", "v"];

    return allowedKeys.includes(event.key);
  };

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Budget Month',
        field: 'budgetDate',
        editable: true,
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXContractMonthDropdown fullWidth value={props.value} onChange={(date: ContractDate | null) => props.setValue!(date)} />
        ),
      },
      {
        headerName: 'Contract Month',
        editable: true,
        field: 'contractDate',
        hide: !budgets[0]?.hasContractDate,
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXContractMonthDropdown fullWidth value={props.value ?? null} onChange={(date: ContractDate | null) => props.setValue!(date)} />
        ),
      },
      {
        headerName: 'Budget Volume',
        editable: true,
        field: 'volume',
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXTextField fullWidth type="number" value={props.value} min={0} onChange={(event: any) => props.setValue!(event.target.value)} />
        ),
      },
      ...(budgets[0]?.budgetPrices?.map((component, index) => ({
        headerName: `${component.componentType} Price`,
        editable: true,
        valueGetter: (params: ValueGetterParams) => params.data.budgetPrices[index].price,
        valueSetter: (params: ValueSetterParams) => {
          if (params.newValue !== params.oldValue) {
            params.data.budgetPrices[index].price = params.newValue;
            return true; // return true means the grid should refresh this cell
          }
          return false; // return false means no changes, no refresh
        },
        cellRendererFramework: (props: ICellRendererParams) => (
          <StoneXTextField fullWidth type="number" value={props.value} onChange={(event: any) => props.setValue!(event.target.value)} />
        ),
      })) ?? []),
    ];
  }, [budgets]);

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Add Budgets</h1>
      </StoneXRow>

      {customerSettings && customerData && pricingStrategies && (
        <BudgetSelectionForm
          customerSettings={customerSettings!}
          customerData={customerData}
          pricingStrategies={pricingStrategies!}
          onBudgetsUpdated={(budgets: BudgetRow[]) => setBudgets(budgets)}
          onEditStart={() => setIsInEditMode(true)}
          onEditCancel={() => setIsInEditMode(false)}
          onSave={saveBudgets}
        />
      )}

      <StoneXDivider />

      {isInEditMode && (
        <div className="ag-theme-alpine ag-with-inputs" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            processCellForClipboard={(params: ProcessCellForExportParams) => {
              const colId = params.column.getColId();
              if (colId === 'budgetDate' || colId === 'contractDate') {
                const value = params.value;
                return dayjs(new Date(value.year, value.month - 1, 1)).format('L');
              }


              return params.value;
            }}
            processCellFromClipboard={(params: ProcessCellForExportParams) => {
              const colId = params.column.getColId();
              if (colId === 'budgetDate' || colId === 'contractDate') {
                const day = dayjs(params.value);
                return { year: day.year(), month: day.month() + 1};
              }

              return params.value;
            }}
            suppressRowHoverHighlight
            suppressClickEdit
            enableRangeSelection
            enterMovesDown
            enterMovesDownAfterEdit
            rowHeight={56}
            rowData={budgets}
            domLayout="autoHeight"
            onCellKeyDown={(cellEvent: any) => {
              const input = cellEvent.event?.target.querySelector('input');
              if (input) {
                input.focus();
                // const event = new KeyboardEvent('keydown', cellEvent.event);
                // input.dispatchEvent(event);
              }
            }}
            defaultColDef={{
              flex: 1,
              suppressMenu: true,
              suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => !isAllowedKey(params.event),
            }}
            columnDefs={columnDefs}
          />
        </div>
      )}
    </StoneXMainPage>
  );
  
}