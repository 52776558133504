import {
  MarketViewQueryCalendarStripAverageResponse,
  MarketViewQueryForwardCurveResponse,
  MarketViewQueryPriceResponse,
  MarketViewQueryRollingMonthAheadResponse,
  MarketViewQueryStripAverageResponse,
} from '../Generated/Mark-It-View-Api';

enum PriceQueryType {
  Price = 'Price',
  StripAverage = 'Strip Average',
  CalendarStripAverage = 'Calendar Strip Average',
  RollingMonthAhead = 'Rolling Month Ahead',
  Rolling12MonthStrip = 'Rolling 12 Month Strip',
  ForwardCurve = 'Forward Curve',
}

type PriceQueryResult =
  | MarketViewQueryPriceResponse
  | MarketViewQueryStripAverageResponse
  | MarketViewQueryCalendarStripAverageResponse
  | MarketViewQueryRollingMonthAheadResponse
  | MarketViewQueryForwardCurveResponse;

const PriceQueryTypeOptions = Object.keys(PriceQueryType).map((key) => {
  return PriceQueryType[key as keyof typeof PriceQueryType];

  // return {
  //   type: key,
  //   title: PriceQueryType[key as keyof typeof PriceQueryType],
  // };
});

export type { PriceQueryResult };
export { PriceQueryType, PriceQueryTypeOptions };
