let numeral = require('numeraljs');

const Volume = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  console.log(`${x} => ${numeral(x).format('(0,0)')}`);

  return numeral(x).format('(0,0)');
};

const Value = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  return numeral(x).format('$(0,0)');
};

const Price = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  return numeral(x).format('$(0,0.000)');
};

export default {
  Value: Value,
  Volume: Volume,
  Price: Price
}