import { Close } from '@mui/icons-material';
import { IconButton, Tab, Tabs } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { GetCustomerDataResponse, GetCustomerSettingsResponse } from '../../../../Generated/Commodity-Management-Api';
import { useMtmDate } from '../../../../Hooks';
import { StoneXRow } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXSelect } from '../../../StoneXMui';
import { MarkToMarketReport } from './Reports/MarkToMarket/MarkToMarketReport';

interface Report {
  reportType: string;
  name: string;
  report: ReactElement;
}

const reportPageStyle = {
  margin: '20px',
};

export default function ReportsPage() {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const reportOptions = ['Mark To Market', 'Variance To Budget', 'Decile Report'];
  const [reports, setReports] = useState<Report[]>([]);

  const quoteDate = useMtmDate();
  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>({});

  function handleTabChange(event: any, newIndex: number) {
    setActiveTabIndex(newIndex);
  }

  function handleTabClose(event: any, index: number) {
    event.stopPropagation(); // Prevent the tab change event from firing
    setReports(reports.filter((report, i) => i !== index));
  }

  function addReport(reportType: string | null) {
    console.log('adding report', reportType);

    if (reportType) {
      setReports((previous) => [...previous, getNewReport(reportType)]);
    }
  }

  function getTabLabel(report: Report, index: number) {
    return (
      <span>
        {report.name}
        {/* <IconButton size="small" onClick={(event) => handleTabClose(event, index)}>
          <Close fontSize="inherit" />
        </IconButton> */}
      </span>
    );
  }

  function createNewReportElement(reportType: string): ReactElement {
    switch (reportType) {
      case 'Mark To Market':
        return markToMarketReport();
      case 'Variance To Budget':
        return varianceToBudgetReport();
      default:
        return <>Error</>;
    }
  }

  function getNewReport(reportType: string): Report {
    return {
      reportType: reportType,
      name: reportType,
      report: createNewReportElement(reportType),
    };
  }

  function markToMarketReport() {
    return <MarkToMarketReport customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />;
  }

  function varianceToBudgetReport() {
    return <>VTB</>;
  }

  useEffect(() => {
    setReports(reportOptions.map((q) => getNewReport(q)));

    CommodityManagementApi.getCustomerData(null).then((res) => setCustomerData(res.data));
    CommodityManagementApi.getCustomerSettings(null).then((res) => setCustomerSettings(res.data));
  }, []);

  return (
    <StoneXMainPage>
      <StoneXRow align="start">
        <h1>Reports</h1>

        <div className="pull-right" style={{ width: 260 }}>
          <StoneXSelect
            fullWidth
            options={['Select Report', ...reportOptions]}
            placeholder="Select Report"
            getOptionLabel={(q) => q}
            value={'Select Report'}
            getId={(q) => q}
            onChange={addReport}
            isControlled={true}
          />
        </div>
      </StoneXRow>

      <Tabs
        TabIndicatorProps={{
          style: { transition: 'none' },
        }}
        sx={{ borderBottom: '1px solid gray' }}
        value={activeTabIndex}
        onChange={handleTabChange}
      >
        {reports.map((q, i) => {
          return <Tab label={getTabLabel(q, i)} />;
        })}
      </Tabs>

      <>
        {reports.map((report, i) => {
          return <div style={{ display: i == activeTabIndex ? 'inherit' : 'none', ...reportPageStyle }}>{report.report}</div>;
        })}
      </>
    </StoneXMainPage>
  );
}
