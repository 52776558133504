import { CircularProgress } from '@mui/material';
import './StoneXLoading.scss';

interface PropsType {
  show?: boolean;
}

function StoneXLoading(props: PropsType) {

  return props.show ? <CircularProgress /> : <></>;

  return props.show ? (
    <div className="snx-container">
      <div className="snx-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
}

export default StoneXLoading;