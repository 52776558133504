/* eslint-disable no-console */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import applicationSettings from '../Settings/ApplicationSettings';

class LoggingService {
    private globalApplicationInsights?: ApplicationInsights;

    private get applicationInsights(): ApplicationInsights {
        if (this.globalApplicationInsights) {
            return this.globalApplicationInsights;
        }

        this.globalApplicationInsights = new ApplicationInsights({
            config: {
                instrumentationKey: applicationSettings.applicationInsightsInstrumentKey,
                disableFetchTracking: false,
            },
        });

        this.globalApplicationInsights.loadAppInsights();
        this.globalApplicationInsights.trackPageView();

        return this.globalApplicationInsights;
    }

    trackButton = (name: string): void => {
        console.log(`${applicationSettings.applicationName} | BUTTON clicked: ${name}`);
        this.applicationInsights.trackTrace({ message: `Button clicked: ${name}` });
    };

    trackEvent = (eventName: string, eventProperties?: {}): void => {
        const logProperties = { ...eventProperties };

        console.log(`${applicationSettings.applicationName} | EVENT: ${eventName}`, logProperties);
        this.applicationInsights.trackEvent({ name: eventName, properties: logProperties });
    };

    trackException = (exception: Error, message?: string): void => {
        console.log(`${applicationSettings.applicationName} | ERROR: ${message}`, exception);
        this.applicationInsights.trackException({ exception }, { message });
    };

    trackPage = (pageName: string): void => {
        console.log(`${applicationSettings.applicationName} | PAGE: ${pageName}`);
        this.applicationInsights.trackPageView({ name: `Page visited: ${pageName}` });
    };

    trackTrace = (message: string): void => {
        console.log(`${applicationSettings.applicationName} | TRACE: ${message}`);
        this.applicationInsights.trackTrace({ message });
    };
}

const loggingService = new LoggingService();

export default loggingService;
