import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommodityManagementSiteAccessModel} from '../../Generated/Commodity-Management-Api';
import { MarketViewApplication } from '../../Generated/Mark-It-View-Api';

export interface UserAccessState {
  commodityManagementUserSiteAccesses: CommodityManagementSiteAccessModel[];
  marketViewApplications: MarketViewApplication[];
  hasAccess: boolean;
  isAdmin: boolean;
}

interface SetUserAccessPayload {
  commodityManagementUserSiteAccesses: CommodityManagementSiteAccessModel[] | undefined | null;
  marketViewApplications: MarketViewApplication[] | undefined | null;
  hasAccess: boolean | undefined | null;
  isAdmin: boolean | undefined | null;
}


const initialState: UserAccessState = {
  commodityManagementUserSiteAccesses: [],
  marketViewApplications: [],
  hasAccess : false,
  isAdmin: false
};

export const userAccessSlice = createSlice({
  name: 'userAccess',
  initialState,
  reducers: {
    setUserAccess: (state: UserAccessState, { payload }: PayloadAction<SetUserAccessPayload>): void => {
      state.commodityManagementUserSiteAccesses = payload.commodityManagementUserSiteAccesses ?? [];
      state.marketViewApplications = payload.marketViewApplications ?? [];
      state.hasAccess = payload.hasAccess ?? false;
      state.isAdmin = payload.isAdmin ?? false;
    },
    resetUserAccess: (state: UserAccessState) => {
      state.commodityManagementUserSiteAccesses = [];
      state.marketViewApplications = [];
      state.hasAccess = false;
      state.isAdmin = false;
    }
  }
});

export const { setUserAccess, resetUserAccess } = userAccessSlice.actions;
export default userAccessSlice.reducer;