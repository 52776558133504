import {
  DecileCalculatorResponse, DecileStripType, ContractSettlement
} from '../Generated/Mark-It-View-Api';


type DecileQueryResult = DecileCalculatorResponse;

const DecileStripTypeOptions = Object.keys(DecileStripType).map((key) => {
  return DecileStripType[key as keyof typeof DecileStripType];

  // return {
  //   type: key,
  //   title: PriceQueryType[key as keyof typeof PriceQueryType],
  // };
});



const DecileContractSettlementOptions = Object.keys(ContractSettlement).map((key) => {
    return ContractSettlement[key as keyof typeof ContractSettlement];

  // return {
  //   type: key,
  //   title: PriceQueryType[key as keyof typeof PriceQueryType],
  // };
});

enum TermClassifications {
  annual = 'Annual',
  summer = 'Summer',
  winter = 'Winter',
  firstquarter = 'Quarter 1',
  secondquarter = 'Quarter 2',
  thirdquarter = 'Quarter 3',
  fourthquarter = 'Quarter 4',
  janfeb = 'Jan-Feb',
  marapr = 'Mar-Apr',
  julaug = 'Jul-Aug',
  octdec = 'Oct-Dec',
  custom = 'Custom'
}

const TermClassificationsOptions = Object.keys(TermClassifications).map((key) => {
  return TermClassifications[key as keyof typeof TermClassifications];

});

export type { DecileQueryResult };
export { DecileStripType, DecileStripTypeOptions };
export { ContractSettlement, DecileContractSettlementOptions };
export { TermClassifications, TermClassificationsOptions };
