import { Button,ButtonGroup,Chip } from '@mui/material';
import { FirstDataRenderedEvent } from 'ag-grid-community';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React,{ useCallback,useEffect,useMemo,useRef,useState } from 'react';
import { PositionModel } from '../../../../../Generated/Commodity-Management-Api';
import { StoneXRow } from '../../../../StoneX';
import { StoneXChevron } from '../../../../StoneXMui';
import { useNavigate } from 'react-router';
import { ChevronRight } from '@mui/icons-material';

interface PositionTableProps {
  label: string;
  positions: PositionModel[];
  onSetPositionValidation: (dealTicketId: number) => void;
}

export default function PositionTable(props: PositionTableProps) {

  const { label, positions, onSetPositionValidation } = props;

  const gridRef = useRef<AgGridReact<PositionModel>>(null);
  const [gridContainerHeight, setGridContainerHeight] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    gridRef?.current?.api?.refreshCells();
  }, [props.positions])

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    resizeParentDivToFit();
  }, []);

  function editDealTicket(dealTicketId: number) {
    const params = new URLSearchParams({
      dealTicketId: dealTicketId.toString(),
    });

    navigate(`update?${params.toString()}`);
  }
  
  function resizeParentDivToFit() {
    var api = gridRef?.current?.api;

    if (!api) return;

    const rowHeight = 40;
    const headerHeight = 40;
    const maxAllowedRows = 20;

    var rowCount = Math.min(maxAllowedRows, positions.length);

    var gridHeight = rowCount * rowHeight + headerHeight + 2; //2 extra pixels to account for border
    setGridContainerHeight(gridHeight);
  }

  const columns = useMemo<ColDef[]>(
    () => [
      { colId: 'tradeDate', headerName: 'Trade Date', field: 'tradeDate', sortable: true, width: 130, valueFormatter: (params: any) => dayjs(params.value).format('L') },
      { colId: 'budgetDate', headerName: 'Budget Month', field: 'budgetDate.label', sortable: true, width: 150, rowGroup: true },
      { colId: 'contractDate', headerName: 'Contract Month', field: 'contractDate.label', sortable: true, width: 160 },
      { colId: 'commodity', headerName: 'Commodity', field: 'commodity.commodityName', sortable: true, minWidth: 100, flex: 1 },
      { colId: 'location', headerName: 'Location', field: 'location.locationName', sortable: true, minWidth: 100, flex: 1 },
      { colId: 'counterparty', headerName: 'Counterparty', field: 'counterparty.counterpartyName', sortable: true, minWidth: 100, flex: 1 },
      { colId: 'side', headerName: 'Side', field: 'side', sortable: true, width: 85 },
      { colId: 'volume', headerName: 'Volume', field: 'volume', sortable: true, width: 110 },
      { colId: 'price', headerName: 'Price', field: 'price', sortable: true, width: 110 },
      { colId: 'unit', headerName: 'Unit', field: 'unit', sortable: true, width: 85 },
      { colId: 'currency', headerName: 'Ccy', field: 'currency', sortable: true, width: 85 },
      { colId: 'positionTypeOrOption', headerName: 'Position Type', field: 'positionTypeOrOption', sortable: true, width: 150 },
      { colId: 'dealTicket', headerName: 'Deal Ticket', field: 'dealTicket', sortable: true, minWidth: 100, flex: 3 },
      {
        colId: 'validated',
        headerName: 'Status',
        field: 'isValidated',
        sortable: true,
        width: 80,
        pinned: 'right',
        cellRendererFramework: (props: ICellRendererParams) => <Chip variant={props.data?.isValidated ? 'success' : 'neutral'} label={props.data?.isValidated ? 'VAL' : 'INV'} />,
      },
      {
        colId: 'actions',
        headerName: 'Actions',
        width: 330,
        pinned: 'right',
        cellStyle: { padding: '0' },
        cellRendererFramework: (props: ICellRendererParams) => (
          <ButtonGroup variant="text">
            <Button onClick={() => onSetPositionValidation(props.data.dealTicketId)}>Set Validadtion</Button>
            <Button onClick={() => editDealTicket(props.data.dealTicketId)}>Edit</Button>
            <Button>Delete</Button>
            <Button>Audit</Button>
          </ButtonGroup>
        ),
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      suppressMenu: true
    };
  }, []);

  function CustomGroupRowRenderer(props: any) {
    const { node, api, data } = props;

    const toggleGroup = () => {
      if (node.expanded) {
        node.setExpanded(false);
      } else {
        node.setExpanded(true);
      }
      api.onGroupExpandedOrCollapsed();
    };

    return (
      <div onClick={toggleGroup} style={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', padding: 0, margin: 0, alignItems: 'center' }}>
        <ChevronRight sx={{ transform: node.expanded ? 'rotate(90deg)' : 'none', transition: 'transform .05s ease-out' }} />
        <h2 style={{ margin: 0 }}>{node.key}</h2>
      </div>
    );
  }

  return (
    <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        enableRangeSelection
        groupDisplayType="groupRows"
        groupRowRenderer={CustomGroupRowRenderer}
        groupRowRendererParams={{ month: 'ABC' }}
        ref={gridRef}
        rowBuffer={30}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        rowData={props.positions}
        domLayout="autoHeight"        
        getRowHeight={(params) => {return params.node.group ? 60 : 40}}
        // onFirstDataRendered={onFirstDataRendered}
      />
    </div>
    // <StoneXChevron label={label}>
    //   <StoneXRow>

    //   </StoneXRow>
    // </StoneXChevron>
  );
}
