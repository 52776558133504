import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import React from 'react';
import applicationSettings from '../Settings/ApplicationSettings';

const oktaAuth = new OktaAuth({
    ...applicationSettings.okta,
    devMode: applicationSettings.isDevelopmentLike,
});

const OktaSecurity = ({ children }: React.PropsWithChildren<{}>) => {
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
};

export default OktaSecurity;
