import { configureStore, Store } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import SystemSlice from './Slices/SystemSlice';
import UserSlice from './Slices/UserSlice';
import UserAccessSlice from './Slices/UserAccessSlice';
import ActiveSiteSlice from './Slices/ActiveSiteSlice';

export const createReduxStore = (): Store => {
    const store = configureStore({
        reducer: {
            system: SystemSlice,
            user: UserSlice,
            userAccess: UserAccessSlice,
            activeSite: ActiveSiteSlice
        },
    });

    return store;
};

const store = createReduxStore();

export type RootState = ReturnType<typeof store.getState>;
export type ApplicationDispatch = typeof store.dispatch;
export const useApplicationDispatch = (): ApplicationDispatch => useDispatch<ApplicationDispatch>();
export const useApplicationSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
