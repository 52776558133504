import React, { ReactElement, useEffect } from 'react'
import { Route,  Routes } from 'react-router';
import Users from './Users/Users';
import Sites from './Sites/Sites';
import Commodities from './Commodities/Commodities';
import AdminNavigation from './Navigation/AdminNavigation';

interface AdminSolutionProps {
  setContextualHeader: (header: ReactElement) => void;
}

export default function AdminSolution(props: AdminSolutionProps) {
  useEffect(() => {
    props.setContextualHeader(<AdminNavigation />);

    // Clear the header if this solution is dismounted
    return () => {
      props.setContextualHeader(<></>);
    };
  }, []);

  return (
    // Setting the site's guid as the key will force the component to reload when the site changes
    <div style={{ width: '100%', height: '100%' }}>
      <Routes>
        <Route path="users" element={<Users />} />
        <Route path="sites" element={<Sites />} />
        <Route path="commodities" element={<Commodities />} />
      </Routes>
    </div>
  );
}
