import { AdminPanelSettings, Calculate, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Lightbulb, StackedLineChart } from '@mui/icons-material';
import React, { ReactElement, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RootState, useApplicationSelector } from '../../Redux/ReduxStore';
import { INavProps } from './InterfaceNav';
import './SidebarNavigation.scss';
import { UserAccessState } from '../../Redux/Slices/UserAccessSlice';
import { MarketViewApplication } from '../../Generated/Mark-It-View-Api';
import { includes } from 'lodash';

interface NavigationItem {
  url: string;
  icon: ReactElement;
  label: string;
}

export const SidebarNavigation: React.FC<INavProps> = () => {
  const access = useApplicationSelector((state: RootState): boolean => state.userAccess.hasAccess);
  const [open, setOpen] = useState<boolean | undefined>();
  const toggleOpen = () => setOpen(!open);

  const userAccess = useApplicationSelector((state: RootState): UserAccessState => state.userAccess);
  const userHasCommodityMangementSite = () => userAccess.commodityManagementUserSiteAccesses?.length > 0;
  const userHasApplication = (application: MarketViewApplication) => includes(userAccess.marketViewApplications, application);
  const userIsAdmin = () => userAccess.isAdmin;

  const navigate = useNavigate();
  const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([]);

  useEffect(() => {
    const items: NavigationItem[] = [];

    if (userHasCommodityMangementSite()) {
      items.push({ url: '/commodity-management', icon: <Lightbulb />, label: 'Know-Risk' });
    }

    if (userHasApplication(MarketViewApplication.MarketView)) {
      items.push({ url: '/market-view', icon: <StackedLineChart />, label: 'Mark-It-View' });
    }

    if (userHasApplication(MarketViewApplication.Deciles)) {
      items.push({ url: '/deciles', icon: <Calculate />, label: 'Deciles' });
    }

    if (userIsAdmin()) {
      items.push({ url: '/admin', icon: <AdminPanelSettings />, label: 'Admin' });
    }

    setNavigationItems(items);

    // If user has only one application, automatically go to it
    if (items.length == 1) {
      navigate(items[0].url)
    }

  }, [userAccess]);

  useEffect(() => {
    console.log('user access', userAccess);
  }, []);

  useEffect(() => {
    if (open != undefined) {
      localStorage.setItem('isSidebarOpen', JSON.stringify(open));
    }
  }, [open]);

  useEffect(() => {
    const isSidebarOpenString = localStorage.getItem('isSidebarOpen');
    const isSidebarOpen = isSidebarOpenString ? JSON.parse(isSidebarOpenString) : false;
    setOpen(isSidebarOpen);
  }, []);

  if (!access || navigationItems?.length <= 1) {
    return <></>;
  }

  return (
    <aside id="menu" className={open ? '' : 'collapsed'}>
      <ul>
        <li onClick={toggleOpen}>
          <div className="sidebar-toggle">{open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}</div>
        </li>
        {navigationItems?.map((x) => (
          <li key={x.url}>
            <NavLink className="nav-link" to={x.url}>
              {x.icon}
              <span className="aside-text">{x.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default SidebarNavigation;
