import { useState } from 'react';
import { LoadingState } from '../Types';
import { LoadingStateMonitor } from '../Types/LoadingState';

export default function useLoadingState(): LoadingStateMonitor {
  const [loadingState, setLoadingState] = useState(LoadingState.None);

  const setQuery = function (promise: Promise<any>) {
    setLoadingState(LoadingState.Loading);
    promise.then(() => setLoadingState(LoadingState.Loaded)).catch(() => setLoadingState(LoadingState.Failed));
  };

  const isLoading = function () {
    return loadingState == LoadingState.Loading;
  };

  const isLoaded = function () {
    return loadingState == LoadingState.Loaded;
  };

  const failed = function () {
    return loadingState == LoadingState.Failed;
  };

  const reset = function () {
    setLoadingState(LoadingState.None);
  };

  return {
    loadingState: loadingState,
    setLoadingState: setLoadingState,
    setQuery: setQuery,
    isLoading: isLoading,
    isLoaded: isLoaded,
    failed: failed,
    reset: reset,
  };
}
