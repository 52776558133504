import { FormControl } from '@mui/material';
import Autocomplete,{ AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import ListBox from '../ListBox';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface PropsType<TItem> {
  options: TItem[];
  loading?: () => boolean;
  getOptionLabel: (q: TItem) => string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: TItem | null) => void;
  onUpdate?: (value: TItem | null) => void;
  label?: string;
  error?: string;
  value?: TItem | null;
  renderOption?: ((props: React.HTMLAttributes<HTMLLIElement>, option: TItem, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined;
  dropdownAutoWidth?: boolean;
  listKey?: React.Key | null | undefined;
  groupBy?: ((option: TItem) => string) | undefined;
  variant?: undefined | 'standard';
  width?: string | number;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  getOptionDisabled?: ((option: TItem) => boolean) | undefined;
  disableClearable?: boolean;
  isOptionEqualToValue?: ((option: TItem, value: TItem) => boolean) | undefined;
}

export function StoneXAutocomplete<TItem>(props: PropsType<TItem>) {
  const [open, setOpen] = useState(false);

  function onChange(event: React.SyntheticEvent<Element, Event>, value: TItem | null) {
    props.onChange && props.onChange(event, value);
    props.onUpdate && props.onUpdate(value);
  }

  return (
      <StoneXLabeledItem label={props.label}>
        <Autocomplete
          disablePortal
          options={props.options}
          getOptionDisabled={props.getOptionDisabled}
          disabled={props.disabled}
          // PopperComponent={(popperProps: any) => <Popper {...popperProps} placement="bottom-start" />}
          getOptionLabel={props.getOptionLabel}
          onChange={onChange}
          sx={{ width: props.width }}
          value={props.value}
          disableClearable={props.disableClearable}
          isOptionEqualToValue={props.isOptionEqualToValue}
          open={open}
          key={props.listKey}
          groupBy={props.groupBy}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          loading={props.loading && props.loading()}
          ListboxComponent={ListBox}
          renderOption={props.renderOption}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={props.name}
                onClick={(e) => setOpen(true)}
                error={!!props.error}
                helperText={props.error}
                variant={props.variant}
                placeholder={props.placeholder}
              />
            );
          }}
        />
      </StoneXLabeledItem>
  );
}
