import { Button } from "@mui/material";
import { filter,includes,isEqual,uniq,uniqWith } from "lodash";
import { useEffect,useState } from "react";
import { CounterpartyModel,GetCustomerDataResponse,GetCustomerSettingsResponse,LocationModel,PositionModel,PositionType } from "../../../../../../Generated/Commodity-Management-Api";
import { ContractDate } from "../../../../../../Generated/Mark-It-View-Api";
import { ContractDateHelper } from "../../../../../../Helpers";
import { StoneXRow } from "../../../../../StoneX";
import { StoneXContractMonthPicker,StoneXMultiselect,StoneXSelect } from "../../../../../StoneXMui";
import StoneXLabeledItem from "../../../../../StoneXMui/StoneXLabeledItem/StoneXLabeledItem";
import { FilterAndGroupingResult } from "./Types";

interface MarkToMarketPositionFilterAndGroupingProps {
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  positions?: PositionModel[];
  onApply: (result: FilterAndGroupingResult) => void;
}

export default function MarkToMarketFilterAndGrouping(props: MarkToMarketPositionFilterAndGroupingProps) {
  const { customerData, customerSettings, positions, onApply } = props;

  const tierNames = customerData?.tiers?.map((q) => q.tierName!) ?? [];
  const [groupByOptions, setGroupByOptions] = useState<string[]>([]);
  const [firstGroupingIsTier, setFirstGroupingIsTier] = useState<boolean>(false);
  const [secondGroupingIsTier, setSecondGroupingIsTier] = useState<boolean>(false);
  const [firstGroupingSelected, setFirstGroupingSelected] = useState<string | null>();
  const [secondGroupingSelected, setSecondGroupingSelected] = useState<string | null>();

  const [years, setYears] = useState<number[]>([]);
  const [yearFiltered, setYearFiltered] = useState<number | null>();

  const [contractDateStartFilter, setContractDateStartFilter] = useState<ContractDate | null>();
  const [contractDateEndFilter, setContractDateEndFilter] = useState<ContractDate | null>();

  const [positionTypes, setPositionTypes] = useState<PositionType[]>([]);
  const [positionTypeFilter, setPositionTypeFilter] = useState<PositionType | null>();

  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [locationFilter, setLocationFilter] = useState<LocationModel | null>();

  const [counterparties, setCounterparties] = useState<CounterpartyModel[]>([]);
  const [counterpartiesFilter, setCounterpartiesFilter] = useState<CounterpartyModel[] | null>();

  function updateFilterOptions() {
    const tierNames = customerData?.tiers?.map((q) => q.tierName!) ?? [];

    let groupingOptions: string[] = [];

    if (customerSettings.positionsByLocation) {
      groupingOptions = [...groupingOptions, ...tierNames];
    }

    if (customerSettings.positionsByCounterparty) {
      groupingOptions = [...groupingOptions, 'Counterparty'];
    }

    groupingOptions = [...groupingOptions, 'Commodity'];

    setGroupByOptions(groupingOptions);

    setYears(uniq(positions?.map((q) => q.budgetDate.year!).sort()));
    setPositionTypes(uniq(positions?.map((q) => q.positionType!).sort()));

    const locations = positions?.filter((q) => !!q.location).map((q) => q.location!);
    const uniqueLocations = uniqWith(locations, isEqual);
    uniqueLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
    setLocations(uniqueLocations);

    setCounterparties(uniq(positions?.filter((q) => !!q.counterparty).map((q) => q.counterparty!)));
  }

  function apply() {
    const filteredPositions = applyFilters(positions ?? []);
    const groupingOrder = [firstGroupingSelected, secondGroupingSelected, 'Budget Month'].filter((q) => !!q).map((q) => q!);

    onApply({ grouping: groupingOrder, positions: filteredPositions });
  }

  function applyFilters(positions: PositionModel[]): PositionModel[] {
    let filteredPositions = positions;

    if (yearFiltered) {
      filteredPositions = filter(filteredPositions, (q) => q.budgetDate.year == yearFiltered);
    }

    if (contractDateStartFilter) {
      filteredPositions = filter(filteredPositions, (q) => ContractDateHelper.isGreaterThanOrEqualTo(q.contractDate, contractDateStartFilter) ?? false);
    }

    if (contractDateEndFilter) {
      filteredPositions = filter(filteredPositions, (q) => ContractDateHelper.isLessThanOrEqualTo(q.contractDate, contractDateEndFilter) ?? false);
    }

    if (positionTypeFilter) {
      filteredPositions = filter(filteredPositions, (q) => q.positionType == positionTypeFilter);
    }

    if (locationFilter) {
      filteredPositions = filter(filteredPositions, (q) => q.location?.locationId === locationFilter!.locationId);
    }

    if (counterparties.length > 0) {
      const counterpartyIds = counterpartiesFilter?.map((q) => q.counterpartyId!) ?? [];
      filteredPositions = filter(filteredPositions, (q) => includes(counterpartyIds, q.counterparty?.counterpartyId));
    }

    return filteredPositions;
  }

  function isTierName(name: string | null | undefined) {
    return includes(tierNames, name);
  }

  useEffect(() => {
    setFirstGroupingIsTier(isTierName(firstGroupingSelected));
    setSecondGroupingIsTier(isTierName(secondGroupingSelected));
  }, [firstGroupingSelected, secondGroupingSelected]);

  useEffect(() => {
    updateFilterOptions();
    apply();
  }, [positions]);

  return (
    <StoneXRow>
      <StoneXSelect
        width={200}
        onChange={setFirstGroupingSelected}
        options={groupByOptions}
        getId={(q) => q}
        getOptionLabel={(q) => q}
        label="View By"
        menuOptionDisabled={(q) => (secondGroupingIsTier && isTierName(q)) || q == secondGroupingSelected}
      />

      <StoneXSelect
        width={200}
        onChange={setSecondGroupingSelected}
        options={groupByOptions}
        getId={(q) => q}
        getOptionLabel={(q) => q}
        label="Then By"
        disabled={!firstGroupingSelected}
        menuOptionDisabled={(q) => (firstGroupingIsTier && isTierName(q)) || q == firstGroupingSelected}
      />

      <StoneXSelect width={200} onChange={setYearFiltered} options={years} getId={(q) => q} getOptionLabel={(q) => q} label="Year" />

      <StoneXContractMonthPicker onChange={setContractDateStartFilter} label="Contract Start" />

      <StoneXContractMonthPicker onChange={setContractDateEndFilter} label="Contract End" />

      <StoneXSelect width={200} options={positionTypes} getId={(q) => q} getOptionLabel={(q) => q} label="Position Type" onChange={setPositionTypeFilter} />

      <StoneXSelect
        width={200}
        options={locations}
        getId={(q) => q.locationId}
        getOptionLabel={(q) => q.locationName}
        label="Location"
        onChange={setLocationFilter}
      />

      <StoneXMultiselect
        list={counterparties}
        labelSelector={(q) => q.counterpartyName!}
        valueSelector={(q) => q.counterpartyId!}
        resultSetter={setCounterpartiesFilter}
        label="Counterparty"
      />

      <StoneXLabeledItem label="">
        <Button variant="outlined" onClick={apply}>
          Apply
        </Button>
      </StoneXLabeledItem>
    </StoneXRow>
  );
}
