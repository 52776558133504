import { object, number, date, mixed, array, string } from 'yup';
import { ContractSettlement, DecileContractSettlementOptions,DecileStripType,DecileStripTypeOptions,TermClassifications,TermClassificationsOptions } from '../Types';


export const decileQuerySchema = object({
	startQuoteDate: date()
		.required("Start Date is required"),
	endQuoteDate: date()
		.required("Start Date is required"),
	decileStripType: mixed()
		.required("Strip Type is required")
		.oneOf(DecileStripTypeOptions),
	/*decileContractSettlement: mixed()
		.required("Contract Settlement is required")
		.oneOf(DecileContractSettlementOptions),
	termClassification: mixed()
		.required("Term is required")
		.oneOf(TermClassificationsOptions),*/
	commodityType: string()
		.required("Commdity Type is required"),
	productId: number()
		.typeError("Product is required")
		.required("Product is required"),
	
});
