import { ColDef,GridOptions } from 'ag-grid-community';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React,{ useEffect,useState } from 'react';
import { CommodityManagementApi,MarkItViewApi } from '../../../../../../Apis/Apis';
import { PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import { useMtmDate } from '../../../../../../Hooks';
import useActiveApplication from '../../../../../../Hooks/CommodityManagement/useActiveApplication';


interface MarkToMarketProps {
  grouping: TradePositionGrouping;
}

// function MarkToMarketTable(props: MarkToMarketProps) {
//   const columns = [
//     { headerName: 'Commodity', field: 'commodity.commodityName', },
//     { headerName: 'Counterparty', field: 'counterparty.counterpartyName' },
//     { headerName: 'Location', field: 'location.locationName' },
//     { headerName: 'Budget Month', field: 'budgetDate.label' },
//     { headerName: 'Contract Month', field: 'contractDate.label' },
//     { headerName: 'Volume', field: 'volume' },
//     { headerName: 'Currency', field: 'currency' },
//     { headerName: 'Trade Price', field: 'price' },
//     { headerName: 'Market Price', field: 'marketPrice' },
//     { headerName: 'MTM Price', field: 'mtmPrice' },
//     { headerName: 'MTM Value', field: 'markToMarketValue' },
//   ];

//   if (props.grouping.subGrouping) {
//     return <>
//         <Typography>{props.grouping.groupingLabel}</Typography>
//         {props.grouping.subGrouping.map(q => <MarkToMarketTable grouping={q} />)}
//     </>
//   }

//     return (
//       <div className="ag-theme-alpine" style={{ width: '100%' }}>
//         <Typography>{props.grouping.groupingLabel}</Typography>
//         <AgGridReact gridOptions={{columnDefs: columns, rowData: props.grouping.positions, domLayout: 'autoHeight'}}/>
//       </div>
//     );
// }

interface TradePositionGrouping {
  groupingLabel: string;
  positions: PositionModel[];
  subGrouping?: TradePositionGrouping[];
}

interface MarkToMarketReportSettings {
}

interface MarktoMarketReportProps extends Partial<MarkToMarketReportSettings> {

}

export default function MarkToMarket(props: MarktoMarketReportProps) {
  const mtmDate = useMtmDate();

  const activeApplicationGuid = useActiveApplication();
  // const [mtmGroups, setMtmGroups] = useState<MarkToMarketGrouping[]>();
  const [positions, setPositions] = useState<PositionModel[]>([]);
  // const [grouping1, setGrouping1] = useState<string | null>();
  // const [grouping2, setGroupin2] = useState<string | null>();

  // const [groupedPositions, setGroupedPositions] = useState<TradePositionGrouping[]>();

  // const groupingOptions: string[] = ['Commodity', 'Location', 'Portfolio', 'Counterparty', 'Position Type'];

  useEffect(() => {
    (async () => {
      const quoteDate = (await MarkItViewApi.getMarketViewLastQuoteDate(null, '2', undefined)).data.quoteDate;
      const positions = (await CommodityManagementApi.getMarkToMarketReport(null, quoteDate!)).data.rows ?? [];
      setPositions(positions);
    })();

    // const mtmDateString = dayjs(mtmDate).format('L');
    // MarkItViewApi.getMarketViewLastQuoteDate(null, null);
    // CommodityManagementApi.getMarkToMarketReport(null, mtmDateString).then((q) => setPositions(q.data.rows ?? []));
  }, []);

  // useEffect(() => {

  //   if (grouping1) {
  //       const groupedPositions = groupPositions(grouping1, positions);
  //       const groupedPositionsByMonth = groupGroupedPositions('Budget Month', groupedPositions);
  //       console.log(groupedPositionsByMonth)
  //       setGroupedPositions(groupedPositionsByMonth);
  //   }

  //   if (grouping1 && grouping2) {

  //   }

  // }, [grouping1, grouping2]);

  // const groupPositions = (toGroupBy: string, positions: PositionModel[]): TradePositionGrouping[] => {
  //   const grouped = getGroupedPositions(toGroupBy, positions);

  //   return Object.entries(grouped).map(([key, values]) => {
  //     return {
  //       groupingLabel: key,
  //       positions: values,
  //     } as TradePositionGrouping;
  //   });
  // };

  // const groupGroupedPositions = (toGroupBy: string, groupedPositions: TradePositionGrouping[]): TradePositionGrouping[] => {
  //   return groupedPositions.map(q => {
  //       return {
  //           ...q,
  //           subGrouping: groupPositions(toGroupBy, q.positions)
  //       } as TradePositionGrouping;
  //   });
  // };

  // const getGroupedPositions = (toGroupBy: string, positions: PositionModel[]): Dictionary<PositionModel[]> => {
  //   if (toGroupBy === 'Commodity') return groupBy(positions, (q) => q.commodity?.commodityName);
  //   if (toGroupBy === 'Counterparty') return groupBy(positions, (q) => q.counterparty?.counterpartyName ?? 'No Counterparty');
  //   if (toGroupBy === 'Location') return groupBy(positions, (q) => q.location?.locationName ?? 'No Location');
  //   if (toGroupBy === 'Position Type') return groupBy(positions, (q) => q.positionType);
  //   if (toGroupBy === 'Budget Month') return groupBy(positions, (q) => q.budgetDate.label);
  //   throw 'Unsupported grouping';
  // };

  const columns: ColDef[] = [
    { headerName: 'Commodity', field: 'commodity.commodityName', rowGroup: true, hide: true },
    { headerName: 'Counterparty', field: 'counterparty.counterpartyName' },
    { headerName: 'Location', field: 'location.locationName', rowGroup: true, hide: true },
    { headerName: 'Budget Month', field: 'budgetDate.label', rowGroup: true, hide: true },
    { headerName: 'Contract Month', field: 'contractDate.label' },
    { headerName: 'Volume', field: 'volume' },
    { headerName: 'Currency', field: 'currency' },
    { headerName: 'Trade Price', field: 'price' },
    { headerName: 'Market Price', field: 'marketPrice' },
    {
      headerName: 'MTM Price',
      field: 'mtmPrice',
      aggFunc: 'sum',
      valueFormatter: (params: any) => (params.value ? Math.round(params.value * 100) / 100 + '' : ''),
    },
    {
      headerName: 'MTM Value',
      field: 'markToMarketValue',
      aggFunc: 'sum',
      valueFormatter: (params: any) => (params.value ? Math.round(params.value) + '' : ''),
    },
  ];

  const options: GridOptions = {
    columnDefs: columns,
    // animateRows: true,
    rowHeight: 30,
    domLayout: 'autoHeight',
    groupDisplayType: 'singleColumn',
    rowGroupPanelShow: 'always',
    // adds subtotals
    groupIncludeFooter: true,
    // includes grand total
    groupIncludeTotalFooter: true,
    autoGroupColumnDef: {
      minWidth: 300,
      cellRendererParams: {
        footerValueGetter: (params: any) => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
    },
  };

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact gridOptions={{ ...options, domLayout: 'autoHeight' }} rowData={positions} />
      </div>
    </>
  );

  // return (
  //   <>
  //     <StoneXAutocomplete
  //       options={groupingOptions}
  //       getOptionLabel={(q) => q}
  //       onChange={(e: any, groupingOption: string | null) => setGrouping1(groupingOption)}
  //       label="Breakdown By..."
  //     />

  //     {groupedPositions?.map((q) => (
  //       <MarkToMarketTable grouping={q} />
  //     ))}
  //   </>
  // );
}
