import { Box,FormControl } from '@mui/material';
import React,{ ReactNode } from 'react';

interface StoneXLabeledItemProps {
  width?: number | string;
  label?: string;
  children?: ReactNode;
  fullWidth?: boolean;
}

export default function StoneXLabeledItem(props: StoneXLabeledItemProps) {

  const width = props.fullWidth 
    ? '100%' 
    : props.width ?? 'auto';

  return (
    <FormControl sx={{ width: width }}>
      {props.label && <Box sx={{ marginBottom: '5px' }}>
        <label style={{ fontSize: '14px', lineHeight: '18px', fontWeight: 700 }}>{props.label}</label>
      </Box>}
      {props.children ?? '\u00A0'}
    </FormControl>
  );
}
