import { Close } from '@mui/icons-material';
import { IconButton,MenuItem,Select,SelectChangeEvent } from '@mui/material';
import { useEffect,useState } from 'react';
import StoneXLabeledItem from '../StoneXLabeledItem/StoneXLabeledItem';

interface PropsType<TItem> {
  options: TItem[];
  getId: (q: TItem) => string | number;
  getOptionLabel: (q: TItem) => string | number;
  onChange?: (item: TItem | null) => void;
  label?: string;
  value?: TItem | null;
  width?: string | number;
  fullWidth?: boolean;
  placeholder?: string;
  isControlled?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  menuOptionDisabled?: (condition: TItem) => boolean;
}

export function StoneXSelect<TItem>(props: PropsType<TItem>) {
  
  const [selectedValue, setSelectedValue] = useState<TItem | null>(props.value ?? null);

  function onItemSelected(event: SelectChangeEvent) {
    const value = props.options.find(q => props.getId(q) == event?.target?.value);
    props.onChange && props.onChange(value ?? null);
    
    if (!props.isControlled) {
      setSelectedValue(value ?? null);
    }
  }

  function clearItemSelected() {
    props.onChange && props.onChange(null);

    if (!props.isControlled) {
      setSelectedValue(null);
    }
  }

  useEffect(() => {
    if (props.value) {
      const value = props.options.find(q => props.getId(q) === props.getId(props.value!));
      setSelectedValue(value ?? null);
    }

  }, [props.value])

  return (
    <StoneXLabeledItem label={props.label} fullWidth={props.fullWidth}>
      <Select
        sx={{ width: props.width }}
        fullWidth={props.fullWidth}
        value={(selectedValue && props.getId(selectedValue))?.toString() ?? ''}
        onChange={onItemSelected}
        disabled={props.disabled}
        placeholder={props.placeholder}
        endAdornment={
          selectedValue && props.clearable ? (
            <IconButton onClick={clearItemSelected}>
              <Close />
            </IconButton>
          ) : (
            false
          )
        }
      >
        {props.options.map((q) => (
          <MenuItem key={props.getId(q)} value={props.getId(q)} disabled={(props.menuOptionDisabled && props.menuOptionDisabled(q)) ?? undefined}>
            {props.getOptionLabel(q)}
          </MenuItem>
        ))}
      </Select>
    </StoneXLabeledItem>
  );
}