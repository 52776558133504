import { useEffect, useState } from 'react';
import useLoadingState from './useLoadingState';

export default function useListResource<T, V>(defaultItems: T[], defaultItem: T | null, valueSelector: (item: T) => V) {
  const loadingState = useLoadingState();
  const [items, setItems] = useState<T[]>(defaultItems);
  const [selectedItem, setSelectedItem] = useState<T | null>(defaultItem);
  const [selectedValue, setSelectedValue] = useState<V | null>(null);

  const reset = function () {
    setItems(defaultItems);
    setSelectedItem(defaultItem);
    loadingState.reset();
  };

  const clearSelected = function () {
    setSelectedItem(defaultItem);
  };

  useEffect((): void => {
    if (selectedItem == null) {
      setSelectedValue(null);
    }

    if (selectedItem != null) {
      setSelectedValue(valueSelector(selectedItem!));
    }
  }, [selectedItem]);

  return {
    ...loadingState,
    items: items,
    setItems: setItems,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    selectedValue: selectedValue,
    reset: reset,
    clearSelected: clearSelected,
  };
}
