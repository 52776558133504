import { NavLink } from 'react-router-dom';
import '../../HeaderNavigation.css';

export default function AdminNavigation() {
  interface NavItem {
    label: string,
    url: string,
    children?: NavItem[]
  }

  const navItems: NavItem[] = [
    {
      label: 'Users',
      url: 'admin/users',
    },
    {
      label: 'Commodities',
      url: 'admin/commodities',
      
    },
    {
      label: 'Know Risk Sites',
      url: 'admin/sites',

    },
  ];

  return (
    <nav>
      <ul>
        {navItems.map((q) => {
          return (
            <li key={q.label} className={`${q.children ? 'dropdown' : ''}`}>
              <NavLink to={`/${q.url}`}>
                {q.label}
              </NavLink>
              {q.children && (
                <div className="dropdown-content">
                  {q.children.map((r) => {
                    return (
                      <NavLink key={r.label} to={`/${q.url}/${r.url}`}>
                        {r.label}
                      </NavLink>
                    );
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};