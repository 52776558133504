import { PlayArrow, Star, StarBorder } from '@mui/icons-material';
import { Button, CardActions, CardContent, Checkbox, FormControl, Grid, TextField, FormControlLabel, ToggleButton, ToggleButtonGroup, InputLabel, MenuItem } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { MarkItViewApi } from '../../../../../Apis/Apis';
import { EnumHelper } from '../../../../../Helpers';
import { useListResource, useLoadingState, useMtmDate, usePrevious } from '../../../../../Hooks';
import {
    CommodityType,
    ContractDate,
    MarketViewProductModel,
    MarketViewProductTradingMonthsResponse,
    MarketViewProductTypeModel,
    DecileCalculatorResponse,
    DecileTerm
} from '../../../../../Generated/Mark-It-View-Api';
import { MarkItViewQueryResult as MarkItViewQuery, PriceQueryResult, PriceQueryType, PriceQueryTypeOptions } from '../../../../../Types';
import { MarkItViewQueryParameters } from '../../../../../Types/MarkItViewQueryParameters';
import { DecileQueryParameters } from '../../../../../Types/DecileQueryParameters';
import { decileQuerySchema } from '../../../../../Validations/decileQueryFormValidation';
import { StoneXAutocomplete, StoneXDatePicker, StoneXMonthMultiselect, StoneXMultiselect, StoneXMultiDecileSelect } from '../../../../StoneXMui';
import { StoneXContractMonthPicker } from '../../../../StoneXMui/StoneXContractMonthPicker/StoneXContractMonthPicker';
import './DecileQueryForm.scss';
import { DecileQueryResult as DecileQuery, DecileStripType, ContractSettlement, TermClassifications, DecileContractSettlementOptions, DecileStripTypeOptions, TermClassificationsOptions } from '../../../../../Types';

interface QueryFormProps {
    id: number;
    onNewQuery: (query: DecileQuery) => void;
    onUpdateQuery: (query: DecileQuery) => void;
    onUpdateQueryParams: (query: DecileQueryParameters) => void;
    savedQueryToLoad?: DecileQueryParameters;
}

export default function DecileQueryForm(props: QueryFormProps) {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [hideGrid, setHideGrid] = useState(true);

    const [hideMonths, setHideMonths] = useState(true);

    const defaultParams = {
        id: props.id,
        startQuoteDate: props.savedQueryToLoad?.startQuoteDate ?? dayjs(useMtmDate()).subtract(1, 'year').toDate(),
        calculationDate: props.savedQueryToLoad?.calcDate ?? dayjs(useMtmDate()).subtract(1, 'year').toDate(),
        endQuoteDate: props.savedQueryToLoad?.endQuoteDate ?? useMtmDate(),
        commodityType: props.savedQueryToLoad?.commodityTypeId ?? null,
        productId: props.savedQueryToLoad?.productId ?? null,
        years: props.savedQueryToLoad?.years ?? [],
        months: props.savedQueryToLoad?.months ?? [],
        applyPpi: props.savedQueryToLoad?.applyPpi ?? true,
        decileStripType: props.savedQueryToLoad?.decileStripType ?? null,
        innerRange: props.savedQueryToLoad?.innerRange ?? 1,
        outerRange: props.savedQueryToLoad?.outerRange ?? 4,
        innerWeight: props.savedQueryToLoad?.innerWeight ?? 20,
        outerWeight: props.savedQueryToLoad?.outerWeight ?? 80,
        contractSettlement: props.savedQueryToLoad?.contractSettlement,
        weighted: props.savedQueryToLoad?.weighted ?? true,
        numbermonths: props.savedQueryToLoad?.numberMonths ?? null,
        calcType: props.savedQueryToLoad?.calcType ?? null,
        deciles: props.savedQueryToLoad?.deciles ?? [],
        termClassifications: props.savedQueryToLoad?.termClassifications ?? null
    } as DecileQueryParameters;

    const [queryParams, setQueryParams] = useState<DecileQueryParameters>(defaultParams);

    const commodityTypes = useListResource<MarketViewProductTypeModel, string>([], null, (item) => item.commodityType);
    const products = useListResource<MarketViewProductModel, number>([], null, (item) => item.productId);

    //Trading Months/Years
    const [tradingMonths, setTradingMonths] = useState<MarketViewProductTradingMonthsResponse>({ years: [], months: [] });
    const tradingMonthsLoadingState = useLoadingState();

    const [queryResult, setQueryResult] = useState<DecileQuery>();
    const previousQueryResult = usePrevious(queryResult);
    const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);
    const [isPerformingFirstLoad, setIsPerformingFirstLoad] = useState(!!props.savedQueryToLoad);
    const [productsUpdating, setProductsUpdating] = useState<{ [key: number]: boolean }>({});

    //report loading state
    const reportLoadingState = useLoadingState();

    const loadSavedQuery = (query: MarkItViewQueryParameters) => {
        // setIsPerformingFirstLoad(true);

        // loadProductTypes()
        //   .then(q => {
        //     commodityTypes.loadingState
        //     const commodityType = commodityTypes.items.find(q => q.commodityType == query.commodityType!);
        // })

        // loadProducts(query.commodityType!).then(() => {
        //   const selectedProduct = products.items.find((q) => q.productId == query.productId!);
        //   if (selectedProduct) {
        //     onProductChange(null, selectedProduct);
        //   }
        // });
    };

    const getClosestYearToCurrent = (years: number[]): number => {
        if (!years || years.length == 0) {
            return 0;
        }

        const currentYear = dayjs().year();
        const closest = years.reduce((a, b) => {
            return Math.abs(b - currentYear) < Math.abs(a - currentYear) ? b : a;
        });
        return closest;
    };

    const loadProductTypes = (): Promise<void> => {
        const promise = MarkItViewApi.listMarketViewProductTypes(commodityTypes.setLoadingState).then((response) => {
            if (response.data.rows) {
                commodityTypes.setItems(response.data.rows);

                if (queryParams.commodityType) {
                    loadProducts(queryParams.commodityType);
                }

                if (isPerformingFirstLoad) {
                    const selectedType = response.data.rows.find(q => q.commodityType == props.savedQueryToLoad?.commodityType);
                    if (selectedType) {
                        setQueryParams((previous) => {
                            return { ...previous, commodityType: selectedType.commodityType, product: null, productId: null };
                        });
                        // commodityTypes.setSelectedItem(selectedType);
                        loadProducts(selectedType.commodityType);
                    } else {
                    }
                }
            }
        });

        return promise;
    };

    const loadProducts = (commodityType: CommodityType): Promise<void> => {
        const promise = MarkItViewApi.listMarketViewProducts(products.setLoadingState, commodityType).then((response) => {
            if (response.data.rows) {
                products.setItems(response.data.rows);

                if (isPerformingFirstLoad) {
                    const selectedProduct = response.data.rows.find(q => q.productId == props.savedQueryToLoad?.productId);
                    if (selectedProduct) {
                        setQueryParams((previous) => {
                            return { ...previous, productId: selectedProduct.productId, product: selectedProduct };
                        });
                        products.setSelectedItem(selectedProduct);
                        loadProductTradingMonths(selectedProduct.productId);
                    }
                }
            }
        });

        return promise;
    };

    const loadProductTradingMonths = (productId: number): void => {
        MarkItViewApi.marketViewProductTradingMonths(tradingMonthsLoadingState.setLoadingState, productId.toString()).then((response) => {
            if (response.data) {
                setTradingMonths(response.data);

                if (isPerformingFirstLoad) {
                    setIsPerformingFirstLoad(false);
                }
            }
        });
    };

    const onInnerRangeChange = (event: any) => {
        const isNumber = Number.isFinite(parseInt(event.target.value));
        const innerRange = isNumber ? parseInt(event.target.value) : null;
        setQueryParams({ ...queryParams, innerRange: innerRange });
    };
    const onOuterRangeChange = (event: any) => {
        const isNumber = Number.isFinite(parseInt(event.target.value));
        const outerRange = isNumber ? parseInt(event.target.value) : null;
        setQueryParams({ ...queryParams, outerRange: outerRange });
    };
    const onInnerWeightChange = (event: any) => {
        const isNumber = Number.isFinite(parseInt(event.target.value));
        const innerWeight = isNumber ? parseInt(event.target.value) : null;
        setQueryParams({ ...queryParams, innerWeight: innerWeight });
    };
    const onOuterWeightChange = (event: any) => {
        const isNumber = Number.isFinite(parseInt(event.target.value));
        const outerWeight = isNumber ? parseInt(event.target.value) : null;
        setQueryParams({ ...queryParams, outerWeight: outerWeight });
    };
    const onApplyPPIChange = (event: any) => {
        const isChecked = event.target.checked;
        setQueryParams({ ...queryParams, applyPpi: isChecked });
    };
    const onWeightedChange = (event: any) => {
        const isChecked = event.target.checked;
        setQueryParams({ ...queryParams, weighted: isChecked });
    };

    const onQueryParamsUpdate = () => {
        validateQuery(queryParams);
    }

    useEffect(() => {
        if (!isPerformingFirstLoad && props.savedQueryToLoad) {
            submit();
        }
    }, [isPerformingFirstLoad])

    useEffect(() => {
        if (hasSubmittedOnce) {
            validateQuery(queryParams);
        }
        props.onUpdateQueryParams(queryParams);
    }, [queryParams]);

    const onCalculationDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, calcDate: value });
    const onStartQuoteDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, startQuoteDate: value });
    const onEndQuoteDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, endQuoteDate: value });
    const onYearsChange = (value: number[]): void => setQueryParams({ ...queryParams, years: value });
    const onMonthsChange = (value: number[]): void => setQueryParams({ ...queryParams, months: value });
    const onDecilesChange = (value: number[]): void => setQueryParams({ ...queryParams, deciles: value });

    const onCommodityTypeChange = (event: any, value: CommodityType | null) => {
        setQueryParams({ ...queryParams, commodityType: value });
        // commodityTypes.setSelectedItem(value);

        if (value === null) {
            setQueryParams({ ...queryParams, product: null, productId: null })
        } else {
            loadProducts(value);
        }
    };

    const onProductChange = (event: any, product: MarketViewProductModel | null) => {
        if (product === null) {
            // products.clearSelected();
            setTradingMonths({});
            tradingMonthsLoadingState.reset();
            setQueryParams({ ...queryParams, productId: null, product: null });
            products.setSelectedItem(null);
        } else {
            // products.setSelectedItem(product);
            setQueryParams({ ...queryParams, productId: product.productId, product: product });
            loadProductTradingMonths(product.productId);
            products.setSelectedItem(product);
        }
        // onQueryParamsUpdate();
    };
    const onStripTypeChange = (event: any, value: any): void => setQueryParams({ ...queryParams, decileStripType: value });
    const onTermChange = (value: any): void => {
        setQueryParams({ ...queryParams, termClassifications: value });
        if (value == "custom") {
            setHideMonths(false);
        }
        else {
            setHideMonths(true);
        }
    };
    const onSettlementChange = (event: any, value: any): void => setQueryParams({ ...queryParams, contractSettlement: value });

    /*const toggleFavorite = (product: MarketViewProductModel): void => {
      product.isFavorite = !product.isFavorite;

      const callback = () => {
        const newSet = [...products.items.filter((q) => q.productId != product.productId), product];
        products.setItems(newSet);
        setProductsUpdating((previous) => ({ ...previous, [product.productId]: false }));
      };

      const savedFailedCallback = () => {
        setProductsUpdating((previous) => ({ ...previous, [product.productId]: false }));
      };

      if (product.isFavorite) {
        setProductsUpdating((previous) => ({ ...previous, [product.productId]: true }));
        MarkItViewApi.addMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
      } else {
        setProductsUpdating((previous) => ({ ...previous, [product.productId]: true }));
        MarkItViewApi.deleteMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
        MarkItViewApi.deleteMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
      }
    };*/

    const validateQuery = (query: MarkItViewQueryParameters): boolean => {
        const validationErrors: { [key: string]: string } = {};

        try {
            decileQuerySchema.validateSync(query, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err: any) {
            err.inner.forEach((e: any) => {
                console.log(e.path, e.message);
                validationErrors[e.path] = e.message;
            });

            setErrors(validationErrors);

            return false;
        }

        // markItViewQuerySchema
        //   .validate(query, { abortEarly: false })
        //   .then((q) => {
        //     setErrors({});
        //   })
        //   .catch((err) => {
        //     err.inner.forEach((e: any) => {
        //       validationErrors[e.path] = e.message;
        //     });

        //     if (hasSubmittedOnce) {
        //       setErrors(validationErrors);
        //     }

        //     return false;
        //   });

        // return Object.keys(validationErrors).length === 0;
    };

    const submit = (): void => {
        setHasSubmittedOnce(true);

        if (!validateQuery(queryParams)) {
            return;
        }

        getDecileQuery();
    };

    const getDecileQuery = () => {
        const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
        const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');

        MarkItViewApi.matrixQuery(reportLoadingState.setLoadingState,
            queryParams.productId!,
            queryParams.decileStripType,
            dayjs(queryParams.startQuoteDate).format('L'),
            dayjs(queryParams.endQuoteDate).format('L'),
            dayjs(queryParams.calcDate).format('L'),
            //[1],
            queryParams.innerRange!,
            queryParams.outerRange!,
            queryParams.innerWeight!,
            queryParams.outerWeight!,
            queryParams.contractSettlement,
            queryParams.termClassifications,
            queryParams.applyPpi,
            false).then((response) => {
                onQueryUpdate(DecileStripType.FirstContractMonth, queryParams.product!, response.data);
            });

        onQueryUpdate(DecileStripType.FirstContractMonth, queryParams.product!);
    };

    const onQueryUpdate = (queryType: DecileStripType, product: MarketViewProductModel, prices?: DecileCalculatorResponse) => {
        const query = {
            id: props.id,
            product: product,
            query: prices,
            queryType: queryType,
            hidden: false,
            loadingState: reportLoadingState,
            deciles: queryParams.deciles
        } as DecileQuery;

        setQueryResult(query);
    };

    useEffect(() => {
        if (!queryResult) return;
        const updatedQuery = { ...queryResult, loadingState: reportLoadingState } as DecileQuery;
        setQueryResult(updatedQuery);
    }, [reportLoadingState.loadingState]);

    useEffect(() => {
        if (!previousQueryResult && !queryResult) return;

        props.onUpdateQuery(queryResult!);
    }, [queryResult]);

    const decileQueryInput = (): ReactNode => {
        return (
            <Grid container item direction="column" xs="auto" spacing={2}>
                <Grid item>
                    <StoneXMultiselect
                        list={tradingMonths!.years!}
                        labelSelector={(q) => q}
                        valueSelector={(q) => q}
                        loading={tradingMonthsLoadingState.isLoading}
                        resultSetter={onYearsChange}
                        initial={queryParams.years}
                        autoFocusValue={getClosestYearToCurrent(tradingMonths!.years!)}
                        error={errors['years']}
                        label="Years"
                    />
                </Grid>
                <Grid item>
                    <StoneXMonthMultiselect
                        allowedMonths={tradingMonths!.months!}
                        initial={queryParams.months}
                        resultSetter={onMonthsChange}
                        error={errors['months']}
                    ></StoneXMonthMultiselect>
                </Grid>
            </Grid>
        );
    };

    const calendarStripAverageInput = (): ReactNode => {
        return (
            <Grid container item direction="column" xs="auto" spacing={2}>
                <Grid item>
                    <StoneXMultiselect
                        list={tradingMonths!.years!}
                        labelSelector={(q) => q}
                        valueSelector={(q) => q}
                        loading={tradingMonthsLoadingState.isLoading}
                        resultSetter={onYearsChange}
                        initial={queryParams.years}
                        autoFocusValue={getClosestYearToCurrent(tradingMonths!.years!)}
                        error={errors['years']}
                        label="Years"
                    />
                </Grid>
            </Grid>
        );
    };

    const renderDatePickers = (): ReactNode => {
        return (
            <Grid container direction="column" xs="auto" item spacing={2}>
                <Grid item>
                    <StoneXDatePicker value={queryParams.calcDate!} onChange={onCalculationDateChange} label="Calculaton Date" />
                </Grid>
                {!hideGrid && (
                    <Grid item>
                        <StoneXDatePicker value={queryParams.startQuoteDate!} onChange={onStartQuoteDateChange} label="Start Date" />
                    </Grid>
                )}
                {!hideGrid && (
                    <Grid item>
                        <StoneXDatePicker value={queryParams.endQuoteDate!} onChange={onEndQuoteDateChange} label="End Date" />
                    </Grid>
                )}
            </Grid>
        );
    };

    const renderDecileContractSettlmentLabel = (d: ContractSettlement) => {
        switch (d) {
            case ContractSettlement.ExpirationDate:
                return 'Expiration Date';
            case ContractSettlement.LastDayOfTheMonth:
                return 'Last Day of Month';
            case ContractSettlement.NaturalGasExpiration:
                return 'Natural Gas Expiration';
            default:
                return '';
        }
    };

    const renderDecileStripTypeLabel = (d: DecileStripType) => {
        switch (d) {
            case DecileStripType.ContractPeriod:
                return 'Contract Period';
            case DecileStripType.FirstContractMonth:
                return 'First Contract Month';
            case DecileStripType.RollingMonth:
                return 'Rolling Month';
            case DecileStripType.SecondContractMonth:
                return 'Second Contract Month';
            case DecileStripType.Strip:
                return 'Strip';
            default:
                return '';
        }
    };

    const [alignment, setAlignment] = React.useState('web');
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        if (newAlignment == "chart") {
            setHideGrid(false);
        }
        else {
            setHideGrid(true);
        }
    };

    const months = [
        { value: 0, label: 'January' },
        { value: 1, label: 'February' },
        { value: 2, label: 'March' },
        { value: 3, label: 'April' },
        { value: 4, label: 'May' },
        { value: 5, label: 'June' },
        { value: 6, label: 'July' },
        { value: 7, label: 'August' },
        { value: 8, label: 'September' },
        { value: 9, label: 'October' },
        { value: 10, label: 'November' },
        { value: 11, label: 'December' },
    ];

    const [selectedMonths, setSelectedMonths] = useState<number[]>([]);

    const handleMonthChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
        setSelectedMonths(event.target.value as number[]);
        setQueryParams({ ...queryParams, months: selectedMonths });
    };

    useEffect(() => {
        if (props.savedQueryToLoad) {
            // setIsPerformingFirstLoad(true);
            // loadSavedQuery(props.savedQueryToLoad);
        } else {
            // loadProductTypes();
        }

        loadProductTypes();
    }, []);

    return (
        <>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            value={alignment}
                            aria-label="Platform"
                            onChange={handleChange}>
                            <ToggleButton value="table">Table</ToggleButton>
                            <ToggleButton value="chart">Chart</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {renderDatePickers()}

                    <Grid container direction="column" xs="auto" item spacing={2}>
                        <Grid item>
                            <StoneXAutocomplete
                                options={DecileContractSettlementOptions}
                                getOptionLabel={(decileContractSettlement) => renderDecileContractSettlmentLabel(decileContractSettlement) ?? ''}
                                value={queryParams.contractSettlement}
                                onChange={onSettlementChange}
                                label="Contract Settlement"
                                error={errors['decileContractSettlement']}
                            />
                        </Grid>
                        <Grid item>
                            <StoneXAutocomplete
                                options={DecileStripTypeOptions}
                                getOptionLabel={(decileStripType) => renderDecileStripTypeLabel(decileStripType) ?? ''}
                                value={queryParams.decileStripType}
                                onChange={onStripTypeChange}
                                label="Strip Type"
                                error={errors['decileStripType']}
                            />
                        </Grid>
                        <Grid item>
                            <StoneXMultiselect
                                list={TermClassificationsOptions}
                                labelSelector={(q) => q}
                                valueSelector={(q) => Object.keys(TermClassifications)[Object.values(TermClassifications).indexOf(q)]}
                                resultSetter={onTermChange}
                                label="Term Classification"
                                error={errors['termClassification']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="column" xs="auto" item spacing={2}>
                        <Grid item>
                            <StoneXAutocomplete
                                options={commodityTypes.items.map((q) => q.commodityType).sort((a, b) => {
                                    if (a == CommodityType.Favorites) return -1;
                                    if (b == CommodityType.Favorites) return -1;
                                    return a < b ? -1 : a > b ? 1 : 0;
                                })}
                                getOptionLabel={(commodityType) => EnumHelper.enumToString(commodityType)}
                                value={queryParams.commodityType}
                                onChange={onCommodityTypeChange}
                                loading={commodityTypes.isLoading}
                                label="Commodity Types"
                                error={errors['commodityType']}
                            />
                        </Grid>
                        <Grid item>
                            <StoneXAutocomplete
                                options={orderBy(products.items, [(product) => product.commodityType, (product) => product.name])}
                                getOptionLabel={(product) => product.name}
                                key={'productId'}
                                dropdownAutoWidth
                                value={products.selectedItem}
                                onChange={onProductChange}
                                groupBy={queryParams.commodityType == CommodityType.Favorites ? (product) => EnumHelper.enumToString(product.commodityType) : undefined}
                                loading={products.isLoading}
                                listKey={'productId'}
                                label="Products"
                                error={errors['productId']}
                                renderOption={(props, product) => (
                                    <li {...props}>
                                        {/* <Checkbox style={{marginRight: 8, padding: 0 }} checked={false} /> */}
                                        <IconButton
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                // product.isFavorite = !product.isFavorite;
                                                //toggleFavorite(product);
                                            }}
                                            sx={{ padding: 0, margin: 0, marginRight: '8px' }}
                                        >
                                            {productsUpdating[product.productId] ? <CircularProgress size="1em" /> : product.isFavorite ? <Star /> : <StarBorder />}
                                        </IconButton>
                                        {product.name}
                                    </li>
                                )}
                            />
                        </Grid>
                        {!hideMonths && (
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id="multi-select-month-label">Select Months</InputLabel>
                                    <Select
                                        labelId="multi-select-month-label"
                                        multiple
                                        value={selectedMonths}
                                        onChange={handleMonthChange}
                                        renderValue={(selected) =>
                                            (selected as number[]).map((monthIndex) => months[monthIndex].label).join(', ')}>
                                        {months.map((month, index) => (
                                            <MenuItem key={month.value} value={index}>
                                                {month.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item direction="column" xs="auto" spacing={2}>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <TextField value={defaultParams.innerRange} label="Inner Range" type="number" onChange={onInnerRangeChange} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <TextField value={defaultParams.outerRange} label="Outer Range" type="number" onChange={onOuterRangeChange} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" xs="auto" spacing={2}>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <TextField value={defaultParams.innerWeight} label="Inner Weight" type="number" onChange={onInnerWeightChange} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <TextField value={defaultParams.outerWeight} label="Outer Weight" type="number" onChange={onOuterWeightChange} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" xs="auto" spacing={2}>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <FormControlLabel control={<Checkbox name="applyPPI" onChange={onApplyPPIChange} checked={1 == 1} />} label="Apply PPI" />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ width: 100 }}>
                                <FormControlLabel control={<Checkbox name="weighted" onChange={onWeightedChange} checked={1 == 1} />} label="Weighted" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>{!hideGrid && (
                    <Grid item>
                        <StoneXMultiDecileSelect
                            initial={[]}
                            resultSetter={onDecilesChange}
                            error={errors['deciles']}
                        ></StoneXMultiDecileSelect>
                    </Grid>
                )}
                </Grid>
            </CardContent>

            <CardActions>
                <Button variant='outlined' onClick={submit} startIcon={<PlayArrow />}>Run Query</Button>
            </CardActions>
        </>
    );
}