import React,{ ReactNode } from 'react';
import './StoneXRow.css';

interface StoneXRowProps {
  children: ReactNode;
  justify?: 'start' | 'end';
  align?: 'start' | 'baseline' | 'end';
}

export default function StoneXRow(props: StoneXRowProps) {

  const alignmentStyle = props.align ? { alignItems: props.align } : { alignItems: 'end'};
  const justifyStyle = { justifyContent: props.justify };

  return (
    <div className="row" style={{...alignmentStyle, ...justifyStyle}}>
      {props.children}
    </div>
  );
}
