import { Close, Delete, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import '../../../../../Styles/Colors.scss';
import { MarkItViewQueryParameters } from '../../../../../Types/MarkItViewQueryParameters';
import { MarkItViewQuery } from '../../../../../Types/MarkItViewQueryResult';
import { MarkItViewSavedQuerySet } from '../../../../../Types/MarkItViewSavedQuerySet';
import { markItViewQuerySchema } from '../../../../../Validations/queryFormValidation';
import QueryForm from '../QueryForm/QueryForm';
import { usePrevious } from '../../../../../Hooks';

interface SaveQuerySetParameterDialogProps {
  open: boolean;
  onClose: () => void;
  querySet: MarkItViewSavedQuerySet;
  save: (querySetToSave: MarkItViewSavedQuerySet) => Promise<void>;
}

function SaveQuerySetDialog(props: SaveQuerySetParameterDialogProps) {
  const [isEmpty, setIsEmpty] = useState<boolean>();
  const [totalQueries, setTotalQueries] = useState<number>(0);
  const [numberOfInvalidQueries, setNumberOfInvalidQueries] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [queryName, setQueryName] = useState(props.querySet.name);
  const [isSaving, setIsSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);

  useEffect(() => {
    setTotalQueries(props.querySet.querySetParameters.length);
    setIsEmpty(props.querySet.querySetParameters.length === 0);
    const filtered = props.querySet.querySetParameters.filter((q) => {
      try {
        markItViewQuerySchema.validateSync(q);
        return true;
      } catch {
        return false;
      }
    });
    setNumberOfInvalidQueries(props.querySet.querySetParameters.length - filtered.length);
  }, [props.open]);

  const hasError = (): boolean => {
    return isEmpty || numberOfInvalidQueries! >= 1;
  };

  const save = () => {
    const itemToSave = { ...props.querySet, name: queryName, id: props.querySet.id };
    //TODO: Use loading state hook to make this simpler!
    setErrorSaving(false);
    setIsSaving(true);
    props
      .save(itemToSave)
      .then((q) => {
        setIsSaving(false);
        props.onClose();
      })
      .catch((err) => {
        setErrorSaving(true);
        setIsSaving(false);
      });
  };

  const onQueryNameChange = (e: any): void => {
    const name = e.target.value?.trimStart();
    setQueryName(name);
    if (!name || name.length === 0) {
      setErrorMessage('Please enter a name');
    } else if (name.legnth > 100) {
      setErrorMessage('Name cannot exceed 100 characters');
    } else {
      setErrorMessage(undefined);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (!errorMessage) {
        save();
      }
    }
  };

  const renderErrorDialog = () => {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Save Mark-It-View Query</DialogTitle>
        <DialogContent>
          {isEmpty && <DialogContentText>There are no queries to save.</DialogContentText>}
          {numberOfInvalidQueries == 1 && totalQueries == 1 && <DialogContentText>Your query is invalid. Please correct it and try again.</DialogContentText>}
          {numberOfInvalidQueries == 1 && totalQueries > 1 && <DialogContentText>One of your queries. Please correct it and try again.</DialogContentText>}
          {numberOfInvalidQueries! > 1 && totalQueries > 1 && (
            <DialogContentText>
              {numberOfInvalidQueries} of your {totalQueries} queries are invalid. Please correct them and try again.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSavePrompt = () => {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Save Mark-It-View Query</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter or update query name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            error={!!errorMessage}
            helperText={errorMessage}
            label="Saved Query Name"
            type="text"
            fullWidth
            variant="standard"
            value={queryName}
            onChange={onQueryNameChange}
            onKeyDown={handleKeyDown}
            required
          />
        </DialogContent>
        {errorSaving && <DialogContentText>An error occured while saving this query.</DialogContentText>}
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={save} disabled={!!errorMessage}>
            {isSaving && <CircularProgress size="1em" />} Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return hasError() ? renderErrorDialog() : renderSavePrompt();
}

interface QueryFormListProps {
  onQueriesUpdate: (queries: MarkItViewQuery[]) => void;
  onQueryParamsUpdate: (queryParams: MarkItViewSavedQuerySet) => void;
  querySet?: MarkItViewSavedQuerySet;
  saveQuerySet: (querySetToSave: MarkItViewSavedQuerySet) => Promise<void>;
}

export default function QueryFormList(props: QueryFormListProps) {
  const [forms, setForms] = useState<ReactElement[]>([]);
  const [queries, setQueries] = useState<MarkItViewQuery[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [queryNameDialogOpen, setQueryNameDialogOpen] = useState(false);
  const [querySet, setQuerySet] = useState<MarkItViewSavedQuerySet>(props.querySet ?? { id: 0, name: '', querySetParameters: [] });

  const onTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setActiveTabIndex(newTabIndex);
  };

  const deleteTab = (formId: number) => {
    setQueries((previous) => previous.filter((q, i) => q.id !== formId));
    setForms((previous) => previous.filter((q, i) => q.props.id !== formId));

    if (forms.length == 1 && activeTabIndex == 1) {
      setActiveTabIndex(0);
    }
  };

  const onNewQuery = (query: MarkItViewQuery) => {
    //ToDo: remove
  };

  const onQueryUpdate = (query: MarkItViewQuery) => {
    setQueries((previous) => {
        const updatedList = previous.map((y) => {
          if (y.id == query.id) {
            return query;
          } else {
            return y;
          }
        });

        const queryExists = previous.find((x) => x.id == query.id);
        if (!queryExists) {
          updatedList.push(query);
        }

      return updatedList;
    });
  };

  const onQueryParamsUpdate = (query: MarkItViewQueryParameters): void => {
    querySet.querySetParameters = [...querySet.querySetParameters.filter((q, i) => q.id !== query.id), query];
  };

  const createNewForm = (savedQuery?: MarkItViewQueryParameters) => {
    const id = savedQuery?.id ?? new Date().valueOf() + Math.random();
    const form = (
      <QueryForm
        key={id}
        id={id}
        onUpdateQuery={onQueryUpdate}
        onNewQuery={onNewQuery}
        savedQueryToLoad={savedQuery}
        onUpdateQueryParams={onQueryParamsUpdate}
      />
    );
    setForms((previous) => [...previous, form]);
  };

  const renderFormTabs = () => {
    return forms.map((form, index) => {
      const query = queries.find((q) => q.id == form.props.id);
      return (
        <Tab
          label={
            <span style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: query?.loadingState.failed() ? 'red' : 'inherit' }}>
                {query?.product?.name ?? 'New Query'}
              </span>
              {query?.loadingState.isLoading() ? (
                <CircularProgress size="1.25em" />
              ) : (
                <Close
                  fontSize="small"
                  sx={{ color: 'gray', '&:hover': { color: 'inherit' } }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTab(form.props.id);
                  }}
                />
              )}
            </span>
          }
          key={form.props.id}
        ></Tab>
      );
    });
  };

  const renderForms = () =>
    forms.map((queryForm, i) => (
      <Box sx={{ display: activeTabIndex == i ? 'flex' : 'none', flexDirection: 'column' }} key={queryForm.props.id}>
        {queryForm}
      </Box>
    ));

  useEffect(() => {
    if (forms.length == 0) {
      if (props.querySet) {
        props.querySet.querySetParameters.forEach((q) => createNewForm(q));
      } else {
        createNewForm();
      }
    }
  }, []);

  useEffect(() => {
    props.onQueriesUpdate(queries.filter((q) => q.query != undefined));
  }, [queries]);

  useEffect(() => {
    props.onQueryParamsUpdate(querySet);
  }, [querySet]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 250, flexGrow: 0, flexShrink: 0 }}>
        <Tabs orientation="vertical" variant="scrollable" value={activeTabIndex} onChange={onTabChange} sx={{ borderRight: 1, borderColor: 'divider' }}>
          {renderFormTabs()}
          <Tab label="Add New Query" key="new-query" onClick={() => createNewForm()}></Tab>
        </Tabs>
        <CardActions>
          <Button variant="outlined" onClick={() => setQueryNameDialogOpen(true)} startIcon={<Save />}>
            Save
          </Button>
          {/* {querySet.id != 0 && <Button variant="outlined" onClick={() => {}} startIcon={<Delete />}>Delete</Button>} */}
        </CardActions>
      </Box>

      <SaveQuerySetDialog querySet={querySet} open={queryNameDialogOpen} onClose={() => setQueryNameDialogOpen(false)} save={props.saveQuerySet} />

      {renderForms()}
    </>
  );
}
