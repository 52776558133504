import { Button } from '@mui/material';
import dayjs from 'dayjs';
import React,{ useEffect,useState } from 'react';
import { number,object } from 'yup';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
CommodityModel,ContractDate,CounterpartyModel,GetCustomerDataResponse,
GetCustomerSettingsResponse,LocationModel,PositionModel,
PositionType,
Side,YearDataModel
} from '../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import useActiveApplication from '../../../../../Hooks/CommodityManagement/useActiveApplication';
import { LoadingState } from '../../../../../Types';
import { StoneXRow } from '../../../../StoneX';
import { StoneXAutocomplete,StoneXContractMonthPicker,StoneXDatePicker,StoneXMultiAutocomplete,StoneXTextField } from '../../../../StoneXMui';
import StoneXLabeledItem from '../../../../StoneXMui/StoneXLabeledItem/StoneXLabeledItem';

interface AdvancedQueryFormProps {
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  years: YearDataModel[];
  loadingStateSetter: (loadingState: LoadingState) => void;
  onPositionsUpdated: (positions: PositionModel[]) => void;
}

export default function AdvancedQueryForm(props: AdvancedQueryFormProps) {
  const { customerData, customerSettings, years, loadingStateSetter, onPositionsUpdated } = props;
  const activeApplicationGuid = useActiveApplication();

  const [year, setYear] = useState<YearDataModel | null>();
  const [tradeStartDate, setTradeStartDate] = useState<Date | null>();
  const [tradeEndDate, setTradeEndDate] = useState<Date | null>();
  const [contractStartMonth, setContratStartMonth] = useState<ContractDate | null>();
  const [contractEndMonth, setContratEndMonth] = useState<ContractDate | null>();
  const [commodities, setCommodities] = useState<CommodityModel[]>();
  const [locations, setLocations] = useState<LocationModel[]>();
  const [counterparties, setCounterparties] = useState<CounterpartyModel[]>();
  const [dealTicket, setDealticket] = useState<string | null>();
  const [side, setSide] = useState<Side | null>();
  const [positionType, setPositionType] = useState<PositionType | null>();
  const [validationStatus, setValidationStatus] = useState<boolean | null>();

  const [formIsValid, setFormIsValid] = useState(false);

  const formValidationSchema = object({
    year: number().required().min(2000),
  });

  useEffect(() => {
    validateForm();
  }, [commodities, locations, counterparties, year]);

  function getPositions() {
    const budgetStartMonth = ContractDateHelper.getStartContractDate(year!.year, customerSettings?.fiscalMonthOffset);
    const budgetEndMonth = ContractDateHelper.addMonths(budgetStartMonth, 12);

    CommodityManagementApi.listPositions(
      loadingStateSetter,
      undefined, //mtmDate
      false, // include expiration dates
      false, //include market prices
      commodities?.map((q) => q.commodityId),
      locations?.map((q) => q.locationId),
      counterparties?.map((q) => q.counterpartyId!),
      tradeStartDate ? dayjs(tradeStartDate).format('L') : undefined,
      tradeEndDate ? dayjs(tradeEndDate).format('L') : undefined,
      budgetStartMonth?.year,
      budgetStartMonth?.month,
      undefined, //label (ignore)
      budgetEndMonth?.year,
      budgetEndMonth?.month,
      undefined, //label (ignore),
      contractStartMonth?.year,
      contractStartMonth?.month,
      undefined, //label (ignore)
      contractEndMonth?.year,
      contractEndMonth?.month,
      undefined, //label (ignore)
      positionType ? [positionType] : [],
      dealTicket ?? undefined,
      side ?? undefined,
      validationStatus ?? undefined,
    ).then((q) => {
      onPositionsUpdated(q.data.rows ?? []);
    });
  }

  function validateForm() {
    formValidationSchema
      .validate({
        year: year?.year,
      })
      .then((success) => setFormIsValid(true))
      .catch((error) => setFormIsValid(false));
  }

  return (
    <>
      <StoneXRow>
        <StoneXAutocomplete
          options={years}
          getOptionLabel={(q) => q.year.toString()}
          onChange={(e: any, year: YearDataModel | null) => setYear(year)}
          label="Year"
        />

        <StoneXDatePicker onChange={setTradeStartDate} label={'Trade Start Date'} />

        <StoneXDatePicker onChange={setTradeEndDate} label={'Trade End Date'} />

        <StoneXContractMonthPicker onChange={setContratStartMonth} label={'Contract Start Date'} />

        <StoneXContractMonthPicker onChange={setContratEndMonth} label={'Contract End Date'} />
      </StoneXRow>

      <StoneXRow>
        <StoneXTextField label="Deal Ticket ID" type="text" onChange={(event: any) => setDealticket(event.target.value)} />

        <StoneXAutocomplete options={['Buy', 'Sell']} getOptionLabel={(q) => q} onChange={(e: any, side: Side | null) => setSide(side)} label="Side" />

        <StoneXAutocomplete
          options={customerData.positionTypes ?? []}
          getOptionLabel={(q) => q}
          onChange={(e: any, positionType: PositionType | null) => setPositionType(positionType)}
          label="Position Type "
        />

        <StoneXAutocomplete
          options={[
            { label: 'Validated', value: true },
            { label: 'Unvalidated', value: false },
          ]}
          getOptionLabel={(q) => q.label}
          onChange={(e: any, validationStatus: { label: string; value: boolean } | null) => setValidationStatus(validationStatus?.value)}
          label="Validation Status "
        />
      </StoneXRow>

      <StoneXRow align="start">
        <StoneXMultiAutocomplete
          options={customerData.commodities ?? []}
          getOptionLabel={(q) => q.commodityName}
          placeholder="Select a commodity"
          onChange={(e: any, commodities: CommodityModel[]) => setCommodities(commodities)}
          label="Commodity"
        />

        {customerSettings.positionsByLocation && (
          <StoneXMultiAutocomplete
            options={customerData.locations ?? []}
            getOptionLabel={(q) => q.locationName}
            placeholder="Select a location"
            onChange={(e: any, locations: LocationModel[]) => setLocations(locations)}
            label="Location "
          />
        )}

        {customerSettings.positionsByCounterparty && (
          <StoneXMultiAutocomplete
            options={customerData.counterparties ?? []}
            getOptionLabel={(q) => q.counterpartyName!}
            placeholder="Select a counterparty"
            onChange={(e: any, counterparties: CounterpartyModel[]) => setCounterparties(counterparties)}
            label="Counterparty "
          />
        )}

        <StoneXLabeledItem label="&nbsp;">
          <Button variant="outlined" disabled={!formIsValid} onClick={getPositions}>
            Apply
          </Button>
        </StoneXLabeledItem>
      </StoneXRow>
    </>
  );
}
