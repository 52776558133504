import React,{ useEffect,useState } from 'react';
import { number,object } from 'yup';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { CommodityModel,CounterpartyModel,GetCustomerDataResponse,GetCustomerSettingsResponse,LocationModel,PositionModel,YearDataModel } from '../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import { LoadingState } from '../../../../../Types';
import { StoneXRow } from '../../../../StoneX';
import { StoneXAutocomplete,StoneXYearPills } from '../../../../StoneXMui';

interface BasicQueryFormProps {
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  years: YearDataModel[];
  loadingStateSetter: (loadingState: LoadingState) => void;
  onPositionsUpdated: (positions: PositionModel[]) => void;
}

export default function BasicQueryForm(props: BasicQueryFormProps) {
  const { customerData, customerSettings, years, loadingStateSetter, onPositionsUpdated } = props;

  const [allPositions, setAllPositions] = useState<PositionModel[]>([]);

  const [selectedYear, setSelectedYear] = useState<YearDataModel | null>();
  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>();
  const [selectedLocation, setSelectedLocation] = useState<LocationModel | null>();
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>();

  const [formIsValid, setFormIsValid] = useState(false);

  const formValidationSchema = object({
    year: number().required().min(2000),
  });

  useEffect(() => {
    if (selectedYear) {
      getYearPositions();
    } else {
      setAllPositions([]);
    }
  }, [selectedYear]);

  useEffect(() => {
    filterPositions();
  }, [allPositions, selectedCommodity, selectedLocation, selectedCounterparty]);

  useEffect(() => {
    validateForm();
  }, [selectedCommodity, selectedLocation, selectedCounterparty, selectedYear]);

  function getYearPositions() {
    const startMonth = ContractDateHelper.getStartContractDate(selectedYear!.year, customerSettings?.fiscalMonthOffset);
    const endMonth = ContractDateHelper.addMonths(startMonth, 12);

    CommodityManagementApi.listPositions(
      loadingStateSetter,
      undefined, //mtmDate
      false, //include expiration dates
      false, //include market prices
      undefined, //commodityFilter,
      undefined, //locationFilter,
      undefined, //counterpartyFilter,
      undefined, //trade start date
      undefined, //trade end date
      startMonth.year,
      startMonth.month,
      undefined, //label (ignore)
      endMonth.year,
      endMonth.month,
    ).then((q) => {
      setAllPositions(q.data.rows ?? []);
    });
  }

  function filterPositions() {
 
       const filteredPositions = allPositions.filter((x) => {
        if (selectedCommodity && x.commodity.commodityId != selectedCommodity.commodityId) {
          return false;
        }

        if (selectedLocation && x.location?.locationId != selectedLocation.locationId) {
          return false;
        }

        if (selectedCounterparty && x.counterparty?.counterpartyId != selectedCounterparty.counterpartyId) {
          return false;
        }

        return true;
      });

      onPositionsUpdated(filteredPositions);
  }

  function validateForm() {
    formValidationSchema.validate({
        year: selectedYear?.year,
        commodityId: selectedCommodity?.commodityId,
        locationId: selectedLocation?.locationId,
        counterpartyId: selectedCounterparty?.counterpartyId,
      })
      .then((success) => setFormIsValid(true))
      .catch((error) => setFormIsValid(false));
  }

  return (
    <>
      <StoneXRow>
        <StoneXYearPills label="Years" years={years} onYearSelected={(year: YearDataModel) => setSelectedYear(year)} />
      </StoneXRow>

      {selectedYear && (
        <StoneXRow>
          <StoneXAutocomplete
            options={customerData.commodities ?? []}
            getOptionLabel={(q) => q.commodityName}
            value={selectedCommodity ?? null}
            placeholder="Select a commodity"
            onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
            label="Commodity"
          />

          {customerSettings.positionsByLocation && (
            <StoneXAutocomplete
              options={customerData.locations ?? []}
              getOptionLabel={(q) => q.locationName}
              value={selectedLocation ?? null}
              placeholder="Select a location"
              onChange={(e: any, location: LocationModel | null) => setSelectedLocation(location)}
              label="Location "
            />
          )}

          {customerSettings.positionsByCounterparty && (
            <StoneXAutocomplete
              options={customerData.counterparties ?? []}
              getOptionLabel={(q) => q.counterpartyName!}
              value={selectedCounterparty ?? null}
              placeholder="Select a counterparty"
              onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
              label="Counterparty "
            />
          )}

          {/* <Button variant="outlined" disabled={!formIsValid} onClick={getPositions}>
          Apply
        </Button> */}
        </StoneXRow>
      )}
    </>
  );
}
