import { Button } from '@mui/material';
import { groupBy } from 'lodash';
import { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { BudgetModel,CommodityModel,CounterpartyModel,GetCustomerDataResponse,GetCustomerSettingsResponse,LocationModel,YearDataModel } from '../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import { useActiveAppliction,useLoadingState } from '../../../../../Hooks';
import { StoneXDivider,StoneXLoading,StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXAutocomplete,StoneXYearPills } from '../../../../StoneXMui';
import BudgetTable from './BudgetTable';

export default function ViewBudgetsPage() {

  const activeApplicationGuid = useActiveAppliction();
  
  const customerDataLoadingState = useLoadingState();
  const customeSettingsLoadingState = useLoadingState();
  const budgetYearsLoadingState = useLoadingState();
  const budgetsLoadingState = useLoadingState();

  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>({});
  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LocationModel | null>(null);
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>(null);
  const [selectedYear, setSelectedYear] = useState<YearDataModel | null>();
  const [years, setYears] = useState<YearDataModel[]>([]);
  const [allBudgets, setAllBudgets] = useState<BudgetModel[]>([]);
  const [filteredBudgets, setFilteredBudgets] = useState<BudgetModel[][]>([]);

  const showCounterparty = false;

  //update budgets when anything gets selected
  useEffect(() => {
    if (!selectedYear) {
      return;
    }

    var startDate = ContractDateHelper.getStartContractDate(selectedYear.year, customerSettings!.fiscalMonthOffset);
    var endDate = ContractDateHelper.addMonths(startDate, 11);

    console.log(startDate, endDate, customerSettings!.fiscalMonthOffset);

    CommodityManagementApi.listBudgets(
      budgetsLoadingState.setLoadingState,
      undefined,
      startDate.year,
      startDate.month,
      endDate.year,
      endDate.month,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      // selectedCommodity?.commodityId ? [selectedCommodity.commodityId] : [],
      // selectedLocation?.locationId ? [selectedLocation.locationId] : [],
      // selectedCounterparty?.counterpartyId ? [selectedCounterparty.counterpartyId] : [],
    ).then((q) => {
      setAllBudgets(q.data.rows ?? []);
    });
  }, [selectedYear]);

  useEffect(() => {
    
    const filteredBudgets = allBudgets.filter((x) => {

      if (selectedCommodity && x.commodity.commodityId != selectedCommodity.commodityId) {
        return false;
      }

      if (selectedLocation && x.location?.locationId != selectedLocation.locationId) {
        return false;
      }

      if (selectedCounterparty && x.counterparty?.counterpartyId != selectedCounterparty.counterpartyId) {
        return false;
      }

      return true;
      
    });

    const groupedBudgets = groupBy(filteredBudgets, (x) => `"${x.commodity.commodityId}+${x.location?.locationId}+${x.counterparty?.counterpartyId}"`);
    setFilteredBudgets(Object.values(groupedBudgets));

  }, [allBudgets, selectedCommodity, selectedLocation, selectedCounterparty]);

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
    CommodityManagementApi.getCustomerSettings(customeSettingsLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
    CommodityManagementApi.listBudgetYears(budgetYearsLoadingState.setLoadingState).then((q) => setYears(q.data.rows ?? []));
  }, []);
  
  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Budgets</h1>
        <NavLink to={'update'} className="pull-right">
          <Button>Add new budget</Button>
        </NavLink>
      </StoneXRow>

      <StoneXLoading show={customerDataLoadingState.isLoading()} />

      <StoneXRow>
        <StoneXYearPills label="Years" years={years} onYearSelected={setSelectedYear} />
      </StoneXRow>

      <StoneXRow>
        <StoneXAutocomplete
          options={customerData.commodities ?? []}
          getOptionLabel={(q) => q.commodityName}
          value={selectedCommodity}
          placeholder="Select a commodity"
          onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
          label="Commodity"
        />

        <StoneXAutocomplete
          options={customerData.budgetingLocations ?? []}
          getOptionLabel={(q) => q.locationName}
          value={selectedLocation}
          placeholder="Select a location"
          onChange={(e: any, location: LocationModel | null) => setSelectedLocation(location)}
          label="Location "
        />

        {showCounterparty && (
          <StoneXAutocomplete
            options={customerData.counterparties ?? []}
            getOptionLabel={(q) => q.counterpartyName!}
            value={selectedCounterparty}
            placeholder="Select a counterparty"
            onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
            label="Counterparty "
          />
        )}
      </StoneXRow>

      <StoneXDivider />

      <StoneXLoading show={budgetsLoadingState.isLoading()} />

      {budgetsLoadingState.isLoaded() &&
        filteredBudgets.map((q, i) => (
          <BudgetTable
            key={i}
            budgets={q}
            year={selectedYear!.year}
            commodityId={q[0].commodity.commodityId}
            locationId={q[0].location?.locationId}
            counterpartyId={q[0].counterparty?.counterpartyId}
            labelByCommodity={!selectedCommodity}
            labelByLocation={!selectedLocation}
            labelByCounterparty={showCounterparty && !selectedCounterparty}
          />
        ))}

      {budgetsLoadingState.isLoaded() && filteredBudgets.length == 0 && <p>No budgets found. Please revise the search criteria.</p>}
    </StoneXMainPage>
  );
}
