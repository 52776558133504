import { Button } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';

export default function LoginPage() {
  const { authState, oktaAuth } = useOktaAuth();


  const login = async () => oktaAuth.signInWithRedirect();
  const logOut = async () => {
    // oktaAuth.signOut({ postLogoutRedirectUri: applicationSettings.okta.postLogoutRedirectUri });
    oktaAuth.signOut();
  };

  const btnLogin = authState != null && authState.isAuthenticated ? logOut : login;

  return (
    <div style={{ width: '100%', height: '100%', display: 'grid' }}>
      <div style={{ width: 450, height: 150, alignSelf: 'center', justifySelf: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <h1 style={{ margin: 0 }}>KnowRisk</h1>
          <h5 style={{ margin: 0 }}>Commodity Management</h5>
        </div>
        <Button variant="contained" onClick={btnLogin}>
          login
        </Button>
      </div>
    </div>
  );
}
