import { Button } from '@mui/material';
import { groupBy } from 'lodash';
import { useEffect,useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
GetCustomerDataResponse,
GetCustomerSettingsResponse,
PositionModel,YearDataModel
} from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import useActiveApplication from '../../../../../Hooks/CommodityManagement/useActiveApplication';
import useManyLoadingStates from '../../../../../Hooks/useManyLoadingStates';
import { StoneXDivider,StoneXLoading,StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import AdvancedQueryForm from './AdvancedQueryForm';
import BasicQueryForm from './BasicQueryForm';
import PositionTable from './PositionTable';
import PositionValidationModal from './PositionValidationModal';
import { NavLink } from 'react-router-dom';

export default function ViewPositionsPage() {
  const activeApplicationGuid = useActiveApplication();
  const [isInAdvancedMode, setIsInAdvancedMode] = useState(false);

  const customerDataLoadingState = useLoadingState();
  const customerSettingsLoadingState = useLoadingState();
  const yearsLoadingState = useLoadingState();
  const dataLoadingState = useManyLoadingStates([customerDataLoadingState, customerSettingsLoadingState, yearsLoadingState]);

  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>();
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>();
  const [years, setYears] = useState<YearDataModel[]>([]);

  const postionsLoadingState = useLoadingState();
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [positionsByMonth, setPositionsByMonth] = useState<{ [key: string]: PositionModel[] }>();

  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [validationDealTicketId, setDealTicketValidationId] = useState<number | undefined>();

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
    CommodityManagementApi.getCustomerSettings(customerSettingsLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
    CommodityManagementApi.listPositionYears(yearsLoadingState.setLoadingState).then((q) => setYears(q.data.rows ?? []));
  }, []);

  useEffect(() => {
    const groupedPositions = groupBy(positions, (position) => position.budgetDate.label);
    setPositionsByMonth(groupedPositions);
  }, [positions]);

  function onPositionsUpdated(positions: PositionModel[]) {
    setPositions(positions);
  }

  function setPositionValidation(dealTicketId: number) {
    setValidationModalOpen(true);
    setDealTicketValidationId(dealTicketId);
  }

  function onValidationUpdated(dealTicketId: number, validatedPositionIds: number[]) {
    console.log('validation succeeded. updating positions...');
    setPositions(previous => previous.map(q => {
      if (q.dealTicketId === dealTicketId) {
        q.isValidated = !!validatedPositionIds.find((r) => r === q.positionId);
      }
      return q;
    }))
  }

  return (
    <StoneXMainPage>
      <StoneXRow align="baseline">
        <h1>Positions</h1>

        {isInAdvancedMode ? (
          <Button variant="text" onClick={() => setIsInAdvancedMode(false)}>
            Basic Mode
          </Button>
        ) : (
          <Button variant="text" onClick={() => setIsInAdvancedMode(true)}>
            Advanced Mode
          </Button>
        )}

        <NavLink to={'update'} className="pull-right">
          <Button>Add new position</Button>
        </NavLink>
      </StoneXRow>

      <StoneXLoading show={dataLoadingState.isLoading} />

      {dataLoadingState.isLoaded && !isInAdvancedMode && (
        <BasicQueryForm
          customerData={customerData!}
          customerSettings={customerSettings!}
          years={years}
          loadingStateSetter={postionsLoadingState.setLoadingState}
          onPositionsUpdated={onPositionsUpdated}
        />
      )}

      {dataLoadingState.isLoaded && isInAdvancedMode && (
        <AdvancedQueryForm
          customerData={customerData!}
          customerSettings={customerSettings!}
          years={years}
          loadingStateSetter={postionsLoadingState.setLoadingState}
          onPositionsUpdated={onPositionsUpdated}
        />
      )}

      <StoneXDivider />

      <StoneXLoading show={postionsLoadingState.isLoading()} />

      {postionsLoadingState.isLoaded() && positionsByMonth && (
        <PositionTable key={"Temp"} label={"Temp"} positions={positions} onSetPositionValidation={setPositionValidation} />
      )}

      {/* {postionsLoadingState.isLoaded() &&
        positionsByMonth &&
        Object.entries(positionsByMonth).map(([month, positions]: [string, PositionModel[]]) => (
          <PositionTable key={month} label={month} positions={positions} onSetPositionValidation={setPositionValidation} />
        ))} */}

      <PositionValidationModal
        open={validationModalOpen}
        dealTicketId={validationDealTicketId}
        onClose={() => {
          setValidationModalOpen(false);
        }}
        onValidationUpdated={onValidationUpdated}
      />
    </StoneXMainPage>
  );
}
