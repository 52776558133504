export enum LoadingState {
  None,
  Loading,
  Loaded,
  Failed,
}

export class LoadingStateUtil {
  public static IsLoading(state: LoadingState): boolean {
    return state == LoadingState.Loading;
  }
}
export interface LoadingStateMonitor {
  loadingState: LoadingState;
  setLoadingState: (loadingState: LoadingState) => void;
  setQuery: (promise: Promise<any>) => void;
  isLoading: () => boolean;
  isLoaded: () => boolean;
  failed: () => boolean;
  reset: () => void;
}

export interface ManyLoadingStatesMonitor {
  isLoading: boolean;
  isLoaded: boolean;
  failed: boolean;
}

