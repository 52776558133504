import { Box,Button,FormControl,Stack } from '@mui/material';
import React,{ useMemo,useState } from 'react';
import { YearDataModel } from '../../../Generated/Commodity-Management-Api';
import { StoneXAutocomplete } from '../StoneXAutocomplete/StoneXAutocomplete';
import './StoneXYearPills.css';

interface StoneXYearPillsProps {
  label?: string;
  years: YearDataModel[];
  displayCount?: number;
  onYearSelected?: (year: YearDataModel) => void;
  onChange?: (event: any) => void;
}

export function StoneXYearPills(props: StoneXYearPillsProps) {
  const [displayCount, setDisplayCount] = useState<number>(props.displayCount ?? 3);
  const [activeYear, setActiveYear] = useState<YearDataModel>();
  // const [yearsToShow, setYearsToShow] = useState<YearDataModel[]>();
  const [showAll, setShowAll] = useState<boolean>(false);

  // useEffect(() => {
  //   const years = props.years.sort(q => q.year).reverse().slice(0, displayCount).reverse();
  //   console.log(props.years.sort(q => q.year), years);
  //   setYearsToShow(years);
  // }, [props.years])

  const yearsToShow = useMemo(() => {
    const years = props.years
      .sort((q) => q.year)
      .reverse()
      .slice(0, displayCount)
      .reverse();

    return years;
  }, [props.years]);

  const onYearSelected = (year: number): void => {
    const yearModel = props.years.find((q) => q.year == year)!;
    setActiveYear(yearModel);
    props.onYearSelected && props.onYearSelected(yearModel);
  };

  function showPills() {
    return (
      <FormControl>
        <Box sx={{ marginBottom: '5px' }}>
          <label style={{ display: 'inline-block', fontSize: '14px', lineHeight: '18px', fontWeight: 700 }}>Year</label>
        </Box>
        <Stack direction="row" spacing="10px" className="pills">
          {yearsToShow?.map((q) => (
            <Button
              variant="outlined"
              className={`pill-button ${activeYear?.year == q.year ? 'active' : ''}`}
              value={q.year}
              key={q.year}
              onClick={(e: any) => onYearSelected(e.currentTarget.value)}
            >
              {q.year}
            </Button>
          ))}
          {props.years.length > displayCount && (
            <Button onClick={() => setShowAll(true)} variant="text">
              Show more
            </Button>
          )}
        </Stack>
      </FormControl>
    );
  }

  function showDropdown() {
    return (
      <>
        <StoneXAutocomplete
          options={props.years ?? []}
          getOptionLabel={(q) => q.year.toString()}
          placeholder="Select a year"
          disableClearable
          label="Year"
          value={activeYear ?? null}
          onChange={(e: any, year: YearDataModel | null) => onYearSelected(year!.year)}
        />
        <Button onClick={() => setShowAll(false)} variant="text">
          Show fewer
        </Button>
      </>
    );
  }

  return showAll ? showDropdown() : showPills();
}
