
import { RootState, useApplicationSelector } from '../../Redux/ReduxStore';

export default function Dashboard() {

  const options = [
    { id: 1, label: 'A' },
    { id: 2, label: 'B' },
    { id: 3, label: 'C' },
  ];

  const access = useApplicationSelector((state: RootState): boolean => state.userAccess.hasAccess);
  
  return (
    <>
      {access && Dashboard}
      {!access &&
        <h2>
          You do not have access to any Know-Risk Applications.  Please contact RMICustomerService@StoneX.com to get started.
        </h2>
      }
    </>

  );
}
