import { NavLink } from "react-router-dom";
import '../../HeaderNavigation.css';

export default function CommodityManagementNavigation() {
  interface NavItem {
    label: string,
    url: string,
    children?: NavItem[]
  }

  const navigationItems: NavItem[] = [
    {
      label: 'Reports',
      url: 'reports',
    },
    {
      label: 'Positions',
      url: 'positions',
      children: [
        {
          label: 'Add Positions',
          url: 'add',
        },
        {
          label: 'Allocate Positions',
          url: 'allocate',
        },
      ],
    },
    {
      label: 'Budgets',
      url: 'budgets',
      children: [
        {
          label: 'Add Budgets',
          url: 'update',
        },
      ],
    },
    {
      label: 'Settings',
      url: 'settings',
    },
  ];

  return (
    <nav>
      <ul>
        {navigationItems.map((q) => {
          return (
            <li key={q.label} className={`${q.children ? 'dropdown' : ''}`}>
              <NavLink to={`/commodity-management/${q.url}`}>
                {q.label}
              </NavLink>
              {q.children && (
                <div className="dropdown-content">
                  {q.children.map((r) => {
                    return (
                      <NavLink key={r.label} to={`/commodity-management/${q.url}/${r.url}`}>
                        {r.label}
                      </NavLink>
                    );
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>  
    </nav>
  );
};